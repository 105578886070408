
<div class="card card-blue-border">
  <div class="card-header  card-big-blue-text-header">
    NEW Sales Invoices from {{erpName}}:
  </div>
  <div class="card-body">

    <form [formGroup]="formGroup">


      <div class="form-row">

        <div class="col-md-3">
          <label>Customer:</label>
          <select class="form-control" formControlName="customerddl">
            <option *ngFor="let customer of customerPickList" [ngValue]="customer.id">{{customer.name}}</option>
            <option [ngValue]=null> All Customers </option>
          </select>
        </div>

        <div class="col-md-2">
            <label> Invoice Date From:</label>
            <app-date-picker formControlName="invoiceDateFrom" [minDate]="minDate"></app-date-picker>
        </div>

        <div class="col-md-2">
            <label> Invoice Date To:</label>
            <app-date-picker formControlName="invoiceDateTo" [minDate]="minDate"></app-date-picker>
          </div>

          <div class="col-md-3">
          </div>
  
          <div class="col-md-2">
              <button type="button" class="btn btn-primary group-button" (click)="onSearchSubmit()"> Refresh</button>
              <button type="button" class="btn btn-outline-info cancel group-button" [disabled]="searchState === 'loading'" (click)="onSearchReset()">Reset</button>
            </div>
    
      </div>


    </form>

  </div>
</div>



<div style="margin-left:5px;">

    <div class="row" style="margin:5px">
 
    </div>

    <div class="row">
      <div class="col-md-2">
          <button (click)="onSendSelectedToQueueClick()" class="btn btn-sm btn-outline-primary mr-2">Place in Send Queue</button>
      </div>
      <div class="col-md-10">
      </div>
    </div>

    <div class="row">
        <div class="col-md-12">
      
            <div class="table-responsive" *ngIf="!!searchResults && !!searchResults.length">
          
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th> <input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"> </th>
                    <th>Customer:</th>
                    <th>Invoice No:</th>
                    <th>Invoice Total:</th>
                    <th>Invoice Date:</th>
                    <th>Type:</th>
                    <th>Status:</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let r of searchResults; let i=index">
      
                    <td><input type="checkbox" value="{{r.invoiceNumber}}" [(ngModel)]="r.checked"></td>

                    <td>{{r.customer.customerName}}</td>
                    <td>{{r.invoiceNumber}}</td>
                    <td>{{r.totalAmount | currency}}</td>
                    <td>{{r.invoiceDate | stDate}}</td>
                    <td>{{r.invoiceLayoutType}}</td>
                    <td>{{r.erpStatus}}</td>

                    <td class="d-flex">
                        <button (click)="onExportInvoiceClick(r.invoiceGuid, r.invoiceLayoutType, r.invoiceNumber)" class="btn btn-sm btn-outline-primary mr-2">Send Now</button>
                    </td>
                  </tr>
                </tbody>
              </table>
      
            </div>
      
        </div>
    </div>
        
</div>

<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>

    
