import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackgroundJobResult } from '../models/bg-job-result';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduledJobService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  // public getNewPurchaseInvoicesFromEdiExchange() :Observable<BackgroundJobResult> {
  //   const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
  //   return this.httpClient.get<BackgroundJobResult>(`${environment.api}/purchaseInvoice/getNewPurchaseInvoicesFromEdiExchange/${userTenantId}`);
  // }
  
}
