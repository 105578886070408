import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  erpName: string = "ERP";

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    const userErpTypeShortName = this.authenticationService.getCurrentUser().userErpTypeShortName;
    this.erpName = userErpTypeShortName === null ? "ERP" : userErpTypeShortName;
  }

}
