import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../../../core/helpers/form-helpers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthUserService } from '../../../../core/services/auth-user.service';
import { AuthUserSetPw } from '../../../../core/models/auth-user';
import { AuthUserRole } from '../../../../core/models/auth-user';
import { ToasterService } from '../../../../core/services/toaster.service';

@Component({
  selector: 'app-auth-user-set-pw-modal',
  templateUrl: './auth-user-set-pw-modal.component.html',
  styleUrls: ['./auth-user-set-pw-modal.component.scss']
})

export class AuthUserSetPasswordComponent implements OnInit {
  selectedAuthUserId: number;
  authUserForm: FormGroup;
  savingState: string;
  
  @Output()
  indicatoreChange: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
      private authUserService: AuthUserService,
      private toaster: ToasterService
   ) {}  

  ngOnInit() {
   this.authUserForm = new FormGroup({
      authUserId: new FormControl(""),
      loginName: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
      confirmPassword: new FormControl("", Validators.required)
    });

  }

  setAuthUserPw(action: string, id: number) {
    this.selectedAuthUserId = id;
 
     this.authUserService.getById(this.selectedAuthUserId)
        .subscribe(this.onLoadAuthUser.bind(this),
        error => {
            throw error;    // will go to the global error handler
        }
    );

  }

    // method for closing the modal & pass some data back to the trigger method
    closeModal() {
      this.activeModal.close('Modal Closed');
    }

  onLoadAuthUser(obj: AuthUserSetPw) {
    this.authUserForm = this.formBuilder.group({
      authUserId: [obj.authUserId],
      loginName: [obj.loginName, Validators.required],
      password: [obj.password, Validators.required],
      confirmPassword: [obj.confirmPassword, Validators.required]
    });
  }

  
  onSubmitClick() {
    if (this.authUserForm.invalid) {
      FormHelpers.validateFormGroup(this.authUserForm);
    }
    else {

    const authuserSetPw: AuthUserSetPw = this.authUserForm.getRawValue();

    if (authuserSetPw.password !== authuserSetPw.confirmPassword) {
        this.toaster.showError('The password and confirmation password do not match.');
        return;
    }

    if (authuserSetPw.password.length < 6 || authuserSetPw.password.length > 50) {
        this.toaster.showError('The password must be between 6 and 50 characters in length.');
        return;
    }

    this.savingState = 'loading';

    this.authUserService.updatePassword(authuserSetPw)
        .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
    }
  }

  onSavingSuccess() {
    this.savingState = "finished";
    this.toaster.showSuccess('Successfully saved user.');
    this.activeModal.close(true);
  }

  onSavingError(error) {
    this.savingState = "";
    throw error;    // will go to the global error handler
  }

  lastFieldKeydown(event) {
    if (event.key === 'Enter') {
      this.onSubmitClick();
    }
  }

  get authUserIdControl() { return this.authUserForm.get("authUserId"); }
  get loginNameControl() { return this.authUserForm.get("loginName"); }
  get passwordControl() { return this.authUserForm.get("password"); }
  get confirmPasswordControl() { return this.authUserForm.get("confirmPassword"); }

}
