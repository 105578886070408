import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../../core/helpers/form-helpers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceLine } from '../../../core/models/pur-invoice';
import { PurchaseInvoiceService } from '../../../core/services/pur-invoice.service';
import { ToasterService } from '../../../core/services/toaster.service';

import { PickListService } from '../../../core/services/pickList.service';
import { PickListItem } from '../../../core/models/pickList';

@Component({
  selector: 'app-pur-invoice-line-modal',
  templateUrl: './pur-invoice-line-modal.component.html',
  styleUrls: ['./pur-invoice-line-modal.component.scss']
})

export class PurchaseInvoiceLineModalComponent implements OnInit {

  @Input() public invoiceLine: InvoiceLine;
  @Input() public invoiceLayoutType: string;

  invoiceLineForm: FormGroup;
  savingState: string;

  inventoryItemPickList: PickListItem[];
  accountPickList: PickListItem[];
  
  @Output()
  indicatoreChange: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private pickListService: PickListService,
    private purchaseInvoiceService: PurchaseInvoiceService,
    private toaster: ToasterService
   ) {   this.createForm();
   }  

  ngOnInit() {
    this.pickListService.getInventoryItemPickList().subscribe(res => {
      this.inventoryItemPickList = res;
    })

    this.pickListService.getAccountPickList().subscribe(res => {
      this.accountPickList = res;
    })

  }

  setModalForm(origInvoiceLine: InvoiceLine) {

    this.invoiceLineForm.patchValue({
      purchaseInvoiceLineId: this.invoiceLine.purchaseInvoiceLineId,
      itemIdentifier: this.invoiceLine.itemIdentifier.trim(),
      accountingCostCode:  this.invoiceLine.accountingCostCode.trim(),
    });

  }

    // method for closing the modal & pass some data back to the trigger method
    closeModal() {
      this.activeModal.close('Modal Closed');
    }

  
  onSubmitClick() {
    if (this.invoiceLineForm.invalid) {
      FormHelpers.validateFormGroup(this.invoiceLineForm);
    }
    else {

      // create a model with values returned from the reactive form
      const modifiedInvoiceLine: InvoiceLine = this.invoiceLineForm.getRawValue();
      
      // get the data that may have changed from the reactive form controls 
      // & update the original InvoiceLine model so what is not updated on Form is not clobbered in db

      this.invoiceLine.itemIdentifier = modifiedInvoiceLine.itemIdentifier;
      // get the item description from the curently selected picklistitem
      const selectedItemIdentifier: PickListItem = this.inventoryItemPickList.find( s => s.code === this.invoiceLine.itemIdentifier);

      this.invoiceLine.accountingCostCode = modifiedInvoiceLine.accountingCostCode;
      // get the account name from the curently selected picklistitem
      const selectedAccount: PickListItem = this.accountPickList.find( s => s.code === this.invoiceLine.accountingCostCode);

      if (selectedItemIdentifier)
        this.invoiceLine.itemDescription =  selectedItemIdentifier.name;

      // set the IsDirty flag if the FormGroup isdirty for history purposes
      this.invoiceLine.isDirty = this.invoiceLineForm.dirty;
  
      this.savingState = 'loading';
      this.activeModal.close(this.invoiceLine);
    }
  }

  private createForm() {

    this.invoiceLineForm = this.formBuilder.group({
      purchaseInvoiceLineId: '',
      itemIdentifier:  '',
      accountingCostCode: ''
    });
  }
  
  onItemIdentifierChange(newValue) {
    let itemIdentifier = this.invoiceLineForm.get("itemIdentifier").value;
    const selectedItemIdentifier: PickListItem = this.inventoryItemPickList.find( s => s.code === itemIdentifier);
    this.invoiceLine.itemDescription = selectedItemIdentifier.name;
  }


  get purchaseInvoiceLineIdControl() { return this.invoiceLineForm.get("purchaseInvoiceLineId"); }
  get itemIdentifierControl() { return this.invoiceLineForm.get("itemIdentifier"); }
  get accountingCostCodeControl() { return this.invoiceLineForm.get("accountingCostCode"); }

}
