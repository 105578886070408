
  <div class="card card-blue-border" style="margin-top: 10px">
    <div class="card-header card-blue-header">
      <span *ngIf="action=='view'">Supplier Details</span>
      <span *ngIf="action=='add'">Add New Supplier</span>
      <span *ngIf="action=='edit'">Edit Supplier</span>
    </div>
    <div class="card-body">


      <form [formGroup]="supplierForm">
        <div class="row">
          <div class="col-md-8">


            <div class="form-group row">
              <label for="supplierId" class="col-sm-3 col-form-label">Id:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="supplierId" [readonly]="true" />
              </div>
            </div>
        

            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Name:</label> </div>
              <div class="col-sm-8">
                <span class="form-control readonly-bg">{{supplier.supplierName}}</span>
              </div>
            </div>


          <div class="form-group row">
            <label for="ediSchemaId" class="col-sm-3 col-form-label">EDI Schema Identifier :</label>
            <div class="col-sm-3">
    
                <input type="text" maxlength="50" [readonly]="!isEditable"
                [ngClass]="{'is-invalid': ediSchemaIdControl.invalid && (ediSchemaIdControl.dirty || ediSchemaIdControl.touched)}"
                class="form-control prevent-uppercase"
                formControlName="ediSchemaId" />
            </div>
          </div>


          <div class="form-group row">
              <label for="ediParticipantIdentifier" class="col-sm-3 col-form-label">EDI Participant Identifier :</label>
              <div class="col-sm-8">
      
                  <input type="text" maxlength="50" [readonly]="!isEditable"
                  [ngClass]="{'is-invalid': ediParticipantIdentifierControl.invalid && (ediParticipantIdentifierControl.dirty || ediParticipantIdentifierControl.touched)}"
                  class="form-control prevent-uppercase"
                  formControlName="ediParticipantIdentifier" />
              </div>
          </div>


            <div class="form-group row">
              <label for="supplierGuid" class="col-sm-3 col-form-label">Supplier GUID:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg">{{supplier.supplierGuid}}</span>
              </div>
            </div>

            <!-- TODO: only display one of these depending if MYOB or not  -->

            <!-- <div class="form-group row">
                <label for="erpIdentifier" class="col-sm-3 col-form-label">ERP Identifier:</label>
                <div class="col-sm-8">
                    <span class="form-control readonly-bg">{{supplier.erpIdentifier}}</span>
                </div>
            </div> -->


            <div class="form-group row">
                <div class="col-sm-3"> <label class="col-form-label">Is Active:</label> </div>
                <div class="col-sm-8">
                    <span *ngIf="supplier.isActive" class="form-control readonly-bg"> Yes </span>
                    <span *ngIf="supplier.isActive === false" class="form-control readonly-bg"> No </span>
                </div>
              </div>

            
            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Contact Email:</label> <span class="required">*</span> </div>
                <div class="col-sm-8">
                  <input type="text" maxlength="50" [readonly]="!isEditable" (keydown)="lastFieldKeydown($event)"
                  [ngClass]="{'is-invalid': contactEmailControl.invalid && (contactEmailControl.dirty || contactEmailControl.touched)}"
                  class="form-control prevent-uppercase"
                  formControlName="contactEmail" />
                  <div *ngIf=" contactEmailControl.errors" class="invalid-feedback">
                    <div [hidden]="! contactEmailControl.errors.required">Contact email is required.</div>
                  </div>
                </div>
            </div>


            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Default Bill Type:</label> <span class="required">*</span> </div>
              <div class="col-sm-8">

                  <div *ngIf="!isEditable && supplier != undefined">
                      <span class="form-control readonly-bg">{{supplier.invoiceLayoutType}}</span>
                  </div>
                  
                  <div *ngIf="isEditable">

                      <select class="form-control"
                          [ngClass]="{'is-invalid': invoiceLayoutTypeControl.invalid && (invoiceLayoutTypeControl.dirty || invoiceLayoutTypeControl.touched)}" 
                          class="form-control prevent-uppercase"
                          formControlName="invoiceLayoutType">
              
                          <option *ngFor="let invoiceLayout of invoiceLayoutPickList" [ngValue]="invoiceLayout.code">{{invoiceLayout.name}}</option>
                      </select>
              
                      <div *ngIf=" invoiceLayoutTypeControl.errors" class="invalid-feedback">
                          <div [hidden]="! invoiceLayoutTypeControl.errors.required">Bill Type is required.</div>
                      </div>

                  </div>

              </div>
            </div>



            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Default Cost Account:</label> </div>
      
              <div class="col-sm-8">

                  <div *ngIf="!isEditable && supplier != undefined">
                      <span class="form-control readonly-bg">{{supplier.accountingCostCode}}</span>
                  </div>

                  <div *ngIf="isEditable">

                    <select class="form-control"
                      [ngClass]="{'is-invalid': accountingCostCodeControl.invalid && (accountingCostCodeControl.dirty || accountingCostCodeControl.touched)}"
                      class="form-control prevent-uppercase"
                      formControlName="accountingCostCode">
                      <option *ngFor="let account of accountPickList" [ngValue]="account.code">{{account.code}} - {{account.name}}</option>
                      <option [ngValue]=null>-- Select an Account --  (optional)</option>
                    </select>

                  </div>

      
              <div *ngIf=" accountingCostCodeControl.errors" class="invalid-feedback">
                  <div [hidden]="! accountingCostCodeControl.errors.required">Account Code is required.</div>
              </div>
      
            </div>
         </div>
      


          </div>


          <div class="col-md-4">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Created:</label>
              <div class="col-sm-6">
                <span class="form-control readonly-bg">{{supplier.createdOn | stDateTime}}</span>
              </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Created By:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{supplier.createdBy}}</span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Last Modified:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{supplier.modifiedOn | stDateTime}}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Modified By:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{supplier.modifiedBy}}</span>
                </div>
              </div>
          </div>


        </div>
        
      </form>

  </div>
</div>


<div class="form-group" style="margin-top:5px;">
  <button type="button" class="btn btn-outline-dark group-button" (click)="onCancel()">
    <span *ngIf="action=='view'">Close</span>
    <span *ngIf="action=='add'">Cancel</span>
    <span *ngIf="action=='edit'">Cancel</span>
  </button>
  <span *ngIf="action=='add' || action=='edit'"> 
    <button type="button" class="btn btn-outline-primary group-button" [disabled]="savingState === 'loading'" (click)="onSubmitClick()">
      <span *ngIf="savingState !== 'loading'">Save & Close</span>
      <span *ngIf="savingState === 'loading'">Saving...</span>
    </button>
  </span>

  <span *ngIf="action=='view'"> 
    <button type="button" class="btn btn-primary group-button" (click)="onModifyClick()"> Modify </button>
  </span>

</div>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>






