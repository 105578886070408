import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../../../core/helpers/form-helpers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryItem } from '../../../../core/models/inventory-item';
import { InventoryItemService } from '../../../../core/services/inventory-item.service';
import { ToasterService } from '../../../../core/services/toaster.service';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Guid } from "guid-typescript";
import { stringify } from 'querystring';


@Component({
  selector: 'app-inventory-item-maint',
  templateUrl: './inventory-item-maint.component.html',
  styleUrls: ['./inventory-item-maint.component.scss']
})

export class InventoryItemMaintComponent implements OnInit {
  inventoryItemId: number;
  inventoryItem: InventoryItem = new InventoryItem();

  inventoryItemForm: FormGroup;
  isEditable: boolean;
  action: string;
  savingState: string;

  states = {
    isShowAddInventoryItemRoleForm: false
  };

  @Output()
  indicatoreChange: EventEmitter<any> = new EventEmitter();

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private route:ActivatedRoute,
      private spinner: NgxSpinnerService,
      private inventoryItemService: InventoryItemService,
      private toaster: ToasterService
   ) {}  

  ngOnInit() {
    this.route.params.subscribe(params => this.action = params['action'] );
    this.route.params.subscribe(params => this.inventoryItemId = params['inventoryItemId']);

    this.isEditable = this.action != 'view';

    this.inventoryItemForm = new FormGroup({
      inventoryItemId: new FormControl(""),
      inventoryItemName: new FormControl("", Validators.required),
      inventoryItemGuid: new FormControl(""),
      erpIdentifier: new FormControl(""),
    });

    if (this.action === 'add') {
    }
    else
      this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.inventoryItemService.getById(this.inventoryItemId)
      .subscribe(this.onDataLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(["auth-user"]);
          throw error;       // will go to the global error handler
        }
    );
  }


  onDataLoaded(obj: InventoryItem) {
    this.inventoryItem = obj;
 
    this.inventoryItemForm = this.formBuilder.group({

      inventoryItemId: [obj.inventoryItemId],
      inventoryItemName: [obj.inventoryItemName, Validators.required],
      inventoryItemGuid: [obj.inventoryItemGuid],
      erpIdentifier: [obj.erpIdentifier],
    });

    this.spinner.hide();

  }

  
  onSubmitClick() {
    if (this.inventoryItemForm.invalid) {
      FormHelpers.validateFormGroup(this.inventoryItemForm);
    }
    else {

      // create a model with values returned from the reactive form
      const modifiedInventoryItem: InventoryItem = this.inventoryItemForm.getRawValue();

      // get the data that may have changed from the reactive form controls 
      // & update the orinal order model so what is not updated on Form is not clobbered in db

      this.inventoryItem.inventoryItemId = modifiedInventoryItem.inventoryItemId;
      this.inventoryItem.erpIdentifier = modifiedInventoryItem.erpIdentifier;

      this.savingState = 'loading';

      if (this.action === 'add') {
        this.inventoryItem.inventoryItemId = 0;

        var newInventoryItem = this.inventoryItemService.add(this.inventoryItem)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }
      else {
        this.inventoryItemService.update(this.inventoryItem)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }

    }
  }


  onSavingSuccess() {
    this.savingState = "finished";
    this.router.navigate(["inventoryItem"])
    this.toaster.showSuccess('Successfully saved inventoryItem.');
  }

  onSavingError(error) {
    this.savingState = "";
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["home"])
  }

  onModifyClick() {
    this.action = 'edit';
    this.isEditable = true;
  } 

  lastFieldKeydown(event) {
    if (event.key === 'Enter') {
      this.onSubmitClick();
    }
  }

  get inventoryItemIdControl() { return this.inventoryItemForm.get("inventoryItemId"); }
  get erpIdentifierControl() { return this.inventoryItemForm.get("erpIdentifier"); }

}
