  
<div class="card card-blue-border">
  <div class="card-header card-big-blue-text-header">
    Sales Invoice
  </div>


  <div class="form-group row" style="margin-top:5px;">
    <div class="col-md-8">
        <button type="button" class="btn btn-outline-dark group-button" (click)="onCancel()"> Exit </button>

        <span *ngIf="salesInvoice.salesInvoiceStatusId > 500">
          <button type="button" class="btn btn-primary group-button" (click)="onSalesInvoiceRetrySendingClick()" placement="bottom" ngbTooltip="Once you have resoloved the errors click to re-send.">
             Retry Sending
          </button>
        </span>

        <span>
          <button type="button" class="btn btn-primary group-button" (click)="onSalesInvoiceRefreshClick()"  placement="bottom" ngbTooltip="Get the latest status and details for this invoice.">
            Refresh Invoice
          </button>
        </span>
 
        <span *ngIf="salesInvoice.salesInvoiceStatusId === statusEnum.new || salesInvoice.salesInvoiceStatusId === statusEnum.readyToSend">
          <button type="button" class="btn btn-primary group-button" (click)="onCancelInvoiceConfirm()"> Cancel Invoice</button>
        </span>

        <span *ngIf="salesInvoice.salesInvoiceStatusId === statusEnum.cancelled || salesInvoice.salesInvoiceStatusId === statusEnum.onHold">
          <button type="button" class="btn btn-primary group-button" (click)="onReinstateInvoiceConfirm()"> Reinstate Invoice</button>
        </span>


        <span *ngIf="salesInvoice.salesInvoiceStatusId === statusEnum.new || salesInvoice.salesInvoiceStatusId === statusEnum.readyToSend">
          <button type="button" class="btn btn-primary group-button" (click)="onPutOnHoldConfirm()"> Put On Hold</button>
        </span>



    </div>
    <div class="col-md-4">
    </div>
  </div>



  <div class="card-body">

    <div class="row">
      <div class="col-md-6">

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Id:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{salesInvoice.salesInvoiceId}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Invoice No:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{salesInvoice.invoiceNumber}}</span>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Invoice Status:</label>
          <div class="col-sm-8">
              <span *ngIf="salesInvoice.salesInvoiceStatusId < 200" class="form-control readonly-bg" style="width:300px;">{{salesInvoice.salesInvoiceStatusName}}</span>
              <span *ngIf="salesInvoice.salesInvoiceStatusId === 200" class="form-control readonly-bg" style="width:300px;color:green;">{{salesInvoice.salesInvoiceStatusName}}</span>
              <span *ngIf="salesInvoice.salesInvoiceStatusId >= 500" class="form-control readonly-bg" style="width:300px;color:red;font-weight:bold;">{{salesInvoice.salesInvoiceStatusName}}</span>
          </div>
        </div>
 
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Customer:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:300px;">{{salesInvoice.customerName}}</span>
          </div>
        </div>

      </div>


      <div class="col-md-6">

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Layout Type:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:300px;">{{salesInvoice.invoiceLayoutType}}</span>
          </div>
        </div>
    
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Sent Date:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{salesInvoice.sentDate | stDate}}</span>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Invoice Date:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{salesInvoice.invoiceDate | stDate}}</span>
          </div>
        </div>
  

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Amount Payable:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{salesInvoice.invoiceCurrencyCode}} {{salesInvoice.payableAmount | currency}}</span>
          </div>
        </div>

    </div>

  </div>
</div>


<ngb-tabset [destroyOnHide]="false">

  <!-- <ngb-tab title="Invoice Lines">
    <ng-template ngbTabContent>
      
      <div class="card card-blue-border" style="margin-top: 10px">
        <div class="card-header card-blue-header">
          Invoice Lines
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-md-12">
      
                <div class="table-responsive"  *ngIf="salesInvoice && salesInvoice.invoiceLines && salesInvoice.invoiceLines.length">

                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th>Item Id:</th>
                        <th>Description:</th>
                        <th>Account:</th>
                        <th>Quantity:</th>
                        <th>Unit Price:</th>
                        <th>Tax:</th>
                        <th>Line Total:</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let line of salesInvoice.invoiceLines; let i = index">
                        <td>{{line.itemIdentifier}}</td>
                        <td>{{line.itemDescription}}</td>
                        <td>{{line.accountingCostCode}}</td>
                        <td>{{line.invoicedQuantity}}</td>
                        <td>{{line.unitPrice | currency}}</td>
                        <td>{{line.taxCode}}</td>
                        <td>{{line.lineExtensionAmount | currency}}</td>

                      </tr>
      
                    </tbody>
                  </table>

                </div>

            </div>
          </div>

        </div>
      </div>
      
    </ng-template>
  </ngb-tab> -->


  <ngb-tab title="Workflow History">
    <ng-template ngbTabContent>

      <div class="card card-blue-bsalesInvoice" style="margin-top: 10px">
        <div class="card-header card-blue-header">
           Workflow History
        </div>
        <div class="card-body">
    
          <div class="row">
            <div class="col-md-12">
      
                <div class="table-responsive"  *ngIf="salesInvoice.historyList && salesInvoice.historyList.length">
    
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                          <th>When:</th>
                          <th>By Who:</th>
                          <th>Action:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of salesInvoice.historyList; let i = index">
                            <td>{{item.actionedOn | stDateSlash}}</td>
                        <td>{{item.actionedBy}}</td>
                        <td>{{item.actionDescription}}</td>
                      </tr>
      
                    </tbody>
                  </table>
    
                </div>
    
            </div>
          </div>
    
        </div>
      </div>
      
    </ng-template>
  </ngb-tab>

  
  <ngb-tab>

      <ng-template ngbTabTitle>
          <div>Send Errors</div>
      </ng-template>
  
    <ng-template ngbTabContent>

        <div  style="margin-left:20px; margin-top:20px;" *ngIf="salesInvoice.errorList && salesInvoice.errorList.length === 0">
            <span>NO Errors...</span>
          </div>

        <div style="margin:10px;" *ngFor="let item of salesInvoice.errorList; let i = index">
          <span class="error-text">{{item.errorMessage}}</span>
        </div>

    </ng-template>
  </ngb-tab>


  <!-- <ngb-tab>

      <ng-template ngbTabTitle>
          <div>Other Invoice Details</div>
      </ng-template>
  
    <ng-template ngbTabContent>

    </ng-template>
  </ngb-tab> -->


</ngb-tabset>


<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>
