<div style="margin:15px;">

    <div class="row">

        <div class="col-md-6">
            <p><span style="color:blue;font-weight: bold;">edipak</span> is an online service that faciltates the automated transmission and processing of financial data between trading parties. </p>
            <p>One of the key features is e-Invoicing which is the electronic exchange of invoices between buyers and sellers. It does not matter which accounting software each party uses. This is achieved by converting the invoice to a standard message format for transmission. </p>
 
            <p>The Australian and New Zealand governments have agreed on a transmission standard based on the Peppol framework, which is now widely accepted throught the world. <span style="color:blue;font-weight: bold;">edipak</span> utilizes this standard allowing the automated transfer of invoices between different ERPs and Accounting systems. </p>
            <p> <span style="color:blue;font-weight: bold;"> edipak</span> integrates with your ERP / Accounting system. You don't need to make expensive modifications to your ERP / accounting system. It is all handled by <span style="color:blue;font-weight: bold;"> edipak</span> </p>

            <p><span style="font-weight:bold;">For Suppliers an automated task does the following:</span> </p>
            <ul>
                <li style="list-style:circle;">Extracts the data for unsent invoices from the buyer's Accounting system. </li>
                <li style="list-style:circle;">Converts this data to a Peppol format message and validates it.</li>
                <li style="list-style:circle;"> Sends this message to a Peppol access point, addressed to the buyer.</li>
            </ul>

            <p><span style="font-weight:bold;">For Buyers an automated task does the following:</span> </p>
            <ul>
                <li style="list-style:circle;">Collects all new invoices that are addressed to the Buyer from a Peppol Access Point. </li>
                <li style="list-style:circle;">Invoices are then validated.</li>
                <li style="list-style:circle;">Facility is provided to amend invalid codes.</li>
                <li style="list-style:circle;">Facility is provided to approve the invoices.</li>
                <li style="list-style:circle;">All pending invoices that have been approved are then uploaded to the buyer's Accounting system.</li>
            </ul>

        </div>

        <div class="col-md-6">
            <span><img src="/assets/images/ComputerNetwork.jpg"></span>
            <br/>
            <p>Every business must process bills from their suppliers. The majority of supplier bills are printed and sent by the post or emailed. They are then either OCR scanned or manually entered into the buyer's ERP / Accounting system.</p>
            <p>Because <span style="color:blue;font-weight: bold;">edipak</span> is a SASS application (Software As A Service), you can get started without any expensive custom modifications to your existing ERP / Accounting system.  </p>
            <p><span style="color:blue;font-weight: bold;">edipak</span> has been designed to be flexible and scalable. <span style="color:blue;font-weight: bold;">edipak</span> can be hosted in the cloud or on-site at your own premises. You also have the option of having your own separate database. </p>
        </div>
    </div>
        

    <div class="row">

        <div class="col-md-6">
            <div id="box-blue-rounded-200" style="width:95%; height:280px">
                <h2>Benefits for Suppliers:</h2>
                <ul>
                    <li style="list-style:circle;">Eliminates the need to print, post or email invoices. Can be configured to send electronic invoices out automatically. </li>
                    <li style="list-style:circle;">Assists in the prompt payment by customers.</li>
                    <li style="list-style:circle;">Reduced cost of invoicing.</li>
                    <li style="list-style:circle;">Reliable method delivering invoices to customers.</li>
                    <li style="list-style:circle;">Eliminates processing errors.</li>
                </ul>
            </div>
                     
        </div>
        <div class="col-md-6">
            <div id="box-blue-rounded-200" style="width:95%; height:280px">
                <h2>Benefits for Buyers:</h2>

                <ul>
                    <li style="list-style:circle;">Eliminates the need for expensive and unreliable methods to capture supplier invoice such as OCR, scanning and manual entry. </li>
                    <li style="list-style:circle;">Invoice data is entered once by the supplier and purchase invoices are automatically created in the purchaser's ERP / Accounting system. </li>
                    <li style="list-style:circle;">Improved accuracy of inbound data. </li>
                    <li style="list-style:circle;">Approval workflow process. </li>
                    <li style="list-style:circle;">Time taken to process Supplier bills is dramatically decreased. </li>
                </ul>



                <!-- <p>  Eliminates the need for expensive and unreliable methods to capture supplier invoice such as OCR, scanning and manual entry. </p>
                <p>  Invoice data is entered once by the supplier and purchase invoices are automatically created in the purchaser's ERP / Accounting system. </p>
                <p>  Improved accuracy of inbound data. </p>
                <p>  Approval workflow process. </p>
                <p>  Time taken to process Supplier bills is dramatically decreased.  </p> -->

            </div>
          
        </div>
    </div>

    <br/>

    <p style="font-weight:bold;">Features: </p>
    <p>  Environmentally friendly by eliminating the need for paper. </p>
    <p>  Full audit trail of automated processes. </p>
    <p>  Lower processing costs. </p>
    <p>  Faster invoice cycle times. </p>
    <p>  Improved relationshis between trading partners. </p>
    <p>  More efficient cash flow management. </p>

</div>
