<div class="modal-header">
  <h4 class="modal-title">
     <span>Modify Invoice Line:</span>
 </h4>
 <button (click)="activeModal.dismiss('Cancelled')" class="fa fa-close fa-lg"></button>
</div>

  
<form [formGroup]="invoiceLineForm">
  <div class="modal-body">
    <div class="container">

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Description:</label>
        <div class="col-sm-8">
          <span class="form-control readonly-bg" style="width:200px;">{{invoiceLine.itemDescription}}</span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Layout Type:</label>
        <div class="col-sm-8">
          <span class="form-control readonly-bg" style="width:200px;">{{invoiceLayoutType}}</span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Line Total:</label>
        <div class="col-sm-8">
          <span class="form-control readonly-bg" style="width:200px;">{{invoiceLine.lineExtensionAmount | currency}}</span>
        </div>
      </div>


      <div class="form-group row" *ngIf="invoiceLayoutType === 'Item'">
        <div class="col-sm-3"> <label class="col-form-label">Item Identifier:</label> <span class="required">*</span> </div>

        <div class="col-sm-8">
          <select class="form-control" (change)="onItemIdentifierChange($event)"
          
            [ngClass]="{'is-invalid': itemIdentifierControl.invalid && (itemIdentifierControl.dirty || itemIdentifierControl.touched)}"
            class="form-control prevent-uppercase"
            formControlName="itemIdentifier">

            <option *ngFor="let item of inventoryItemPickList" [ngValue]="item.code">{{item.code}} - {{item.name}}</option>
          </select>

          <div *ngIf=" itemIdentifierControl.errors" class="invalid-feedback">
              <div [hidden]="! itemIdentifierControl.errors.required">Inventory Item is required.</div>
          </div>

        </div>
      </div>


      <div class="form-group row">
        <div class="col-sm-3"> <label class="col-form-label">Cost Account:</label> <span class="required" *ngIf="invoiceLayoutType !== 'Item'">*</span> </div>

        <div class="col-sm-8">
          <select class="form-control"
          
            [ngClass]="{'is-invalid': accountingCostCodeControl.invalid && (accountingCostCodeControl.dirty || accountingCostCodeControl.touched)}"
            class="form-control prevent-uppercase"
            formControlName="accountingCostCode">

            <option *ngFor="let account of accountPickList" [ngValue]="account.code">{{account.code}} - {{account.name}}</option>
          </select>

          <div *ngIf=" accountingCostCodeControl.errors" class="invalid-feedback">
              <div [hidden]="! accountingCostCodeControl.errors.required">Account Code is required.</div>
          </div>

        </div>
      </div>


      <!-- <div class="form-group row">
        <label for="accountingCostCode" class="col-sm-3 col-form-label">Account Code:</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="accountingCostCode"  />
        </div>
      </div> -->

    </div>
  </div>


  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">
        <span>Cancel</span>
      </button>
      <span> 
        <button type="button" class="btn btn-outline-primary" [disabled]="invoiceLineForm.invalid" (click)="onSubmitClick()">
          <span *ngIf="savingState !== 'loading'">Save & Close</span>
          <span *ngIf="savingState === 'loading'">Saving...</span>
        </button>
      </span>
    </div>

</form>


