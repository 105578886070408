<!-- display app icon -->
<link rel="shortcut icon" href="/assets/images/app.ico" type="image/ico" />

<app-main-nav></app-main-nav>

  <!--<ul>
  <li><a routerLink="/home">Home</a></li>
  <li><a routerLink="/contact">Contact</a></li>
</ul>-->


<!-- <router-outlet></router-outlet> -->
