
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data?.title }}</h5>

        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button> -->

        <button type="button" class="fa fa-close fa-lg" data-dismiss="modal" (click)="close()">
        </button>
  
      </div>
      <div class="modal-body" *ngIf="data && data.content">
        <p>{{ data?.content }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="confirm()" class="btn btn-primary">Confirm</button>
        <button type="button" (click)="close()"class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
