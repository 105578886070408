import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../core/helpers/form-helpers';
import { AuthRequest } from '../../core/models/auth';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService
  ) {
      this.loginForm = this.fb.group({
        'loginName': [null, Validators.required],
        'password': [null, Validators.required],
      })
   }

  ngOnInit() {
  }


  onSubmitClick() {
  
    if (this.loginForm.invalid) {

      FormHelpers.validateFormGroup(this.loginForm);
    }
    else {

      this.spinner.show();

      // create a model with values returned from the reactive form
      const authRequest: AuthRequest = this.loginForm.getRawValue();

      var result = this.authenticationService.login(authRequest)
        .subscribe(this.onLoginSuccess.bind(this), this.onLoginError.bind(this));
         
     }
  }


  onLoginSuccess(token: object) {
    this.spinner.hide();
    this.router.navigate(["home"])
  }

  onLoginError(error) {
    this.spinner.hide();
    // throw new Error("Put whatever text you want in here");
    throw error;    // will go to the global error handler
  }

  lastFieldKeydown(event) {
    if (event.key === 'Enter') {
      this.onSubmitClick();
    }
  }


  get loginNameControl() { return this.loginForm.get("loginName"); }
  get passwordControl() { return this.loginForm.get("password"); }


}
