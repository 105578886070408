import { Component, OnInit } from '@angular/core';
import { AuthUserService } from '../../../core/services/auth-user.service';
import { AuthUser } from '../../../core/models/auth-user';
import { Pagination } from '../../../core/models/pagination';
import { PaginatedResult } from '../../../core/models/paginated-result';
import { AuthUserSetPasswordComponent } from './auth-user-set-pw-modal/auth-user-set-pw-modal.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalOptions, SimpleModalConfirmComponent } from "../../../shared/simple-modal-confirm/simple-modal-confirm.component";
import { Params, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authuser',
  templateUrl: './auth-user.html',
  styleUrls: ['./auth-user.scss']
})
export class AuthUserComponent implements OnInit {

  authUserForm: FormGroup;
  
  selectedPage: number = 1;
  pagination: Pagination;
 
  searchState: string = 'loading';
  searchFilters = new AuthUser();
  searchResults: AuthUser[] = new Array<AuthUser>();

  isDataDirty: boolean = false;
 
  constructor(
    private authUserService: AuthUserService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private router: Router
  )
  {
    this.createForm();
  }
  

  ngOnInit() {
    this.fireSearchRequests();
    this.searchState = 'finished'; 
  }

  resetSearch() {
    this.authUserForm.reset();
    this.fireSearchRequests();
  }

  fireSearchRequests(page=1) {

    this.spinner.show();

    this.populateSearchFilter();
    this.searchState = 'loading';

    this.authUserService.search(this.searchFilters, page)
        .subscribe(this.onSearchLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(['/home']);
          throw error;    // will go to the global error handler
      }
    );
  }

  onSearchLoaded(result: PaginatedResult<AuthUser>){
    this.pagination = Pagination.fromJson(result);
    this.searchResults = result.results;
    this.searchState = 'finished';

    this.spinner.hide();
  
    // the json coming back from the server is adding one to the page (I think angular assumes it's zero based)
    if (this.pagination.page > 1)
      this.pagination.page = this.pagination.page - 1;
  }

  populateSearchFilter()
  {
    this.searchFilters.authUserId = this.authUserIdKey;
    this.searchFilters.loginName = this.loginNameKey;
  }

  onSearchSubmit() {
      if (this.authUserForm.valid) {
          this.fireSearchRequests();
      }
  }

  onSearchReset()
  {
      this.selectedPage = 1;
      this.authUserForm.reset();
      this.fireSearchRequests();
  }

  
  createForm() {
    this.authUserForm = this.fb.group({
      authUserIdKey: [''],
      loginNameKey: ['']
    });
    this.authUserForm.get('authUserIdKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
    this.authUserForm.get('loginNameKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
  }


  //This function is called automatically by the ngBootstrap pagination component on page load passing page=1.
  onPageClicked(page: number) {
    if (page && page !== this.pagination.page) {
      this.fireSearchRequests(page);
    }
  }

  onAuthUserDetailClick(authUserId: number) {
    this.router.navigate(["auth-user-maint", 'view', authUserId]);
  } 

  onAuthUserCreateClick() {
    this.router.navigate(["auth-user-maint", 'add', 0]);
  } 

  onAuthUserModifyClick(authUserId: number) {
    this.router.navigate(["auth-user-maint", 'edit', authUserId]);
  } 


  editPw(obj: any) {

    this.isDataDirty = true;  // TODO: work out how to set this

    const modalRef = this.modalService.open(AuthUserSetPasswordComponent, { size: 'lg' });
    modalRef.componentInstance.setAuthUserPw('edit', obj.authUserId);

    modalRef.result.then(function () {
      // if modal closed by buttons calling close() method then will end up here
    }, function () {
      // modal dismissed ie click on background or esc key or calling the dismiss() method will end up here

    }).finally(() => {
       this.handleModalClosed(this.isDataDirty);
    }).catch((error) => {
      throw error;    // will go to the global error handler
    });
  }



  deleteConfirm(authUserId: number) {

    const data = <SimpleModalOptions>{
      title: "Are you sure you want to delete this AuthUser?"
    };

    const modalRef = this.modalService.open(SimpleModalConfirmComponent);
    const modalInstance: SimpleModalConfirmComponent =
      modalRef.componentInstance;
    modalInstance.data = data;

    modalInstance.confirmed.subscribe(res => {
      if (res) {
        this.authUserService.delete(authUserId).subscribe((response) => {
          this.onSearchSubmit();
        });
      }
    });

  }


  handleModalClosed(isSuccess: boolean) {
   if (isSuccess) {
     this.onSearchSubmit();
   }
  }

  
  get authUserIdKey() { return this.authUserForm.get('authUserIdKey').value; }
  get loginNameKey() { return this.authUserForm.get('loginNameKey').value; }

}
