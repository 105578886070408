
import { Component, OnInit } from '@angular/core';
import { PurchaseInvoice } from '../../../core/models/pur-invoice';
import { PurchaseInvoiceSearchFilters } from '../../../core/models/pur-invoice-search-filters';
import { PurchaseInvoiceService } from '../../../core/services/pur-invoice.service';
import { PickListService } from '../../../core/services/pickList.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pagination } from '../../../core/models/pagination';
import { PaginatedResult } from '../../../core/models/paginated-result';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { PickListItem } from '../../../core/models/pickList';
import { Router } from '@angular/router';

import { DateHelpers } from '../../../core/helpers/date-helpers';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as moment from "moment";

@Component({
  selector: 'app-pur-invoice-search',
  templateUrl: './pur-invoice-search.component.html',
  styleUrls: ['./pur-invoice-search.component.scss']
})
export class PurchaseInvoiceSearchComponent implements OnInit {

  formGroup: FormGroup;
  
  selectedPage: number = 1;
  pagination: Pagination;
 
  searchState: string = 'loading';
  searchFilters = new PurchaseInvoiceSearchFilters();
  searchResults: PurchaseInvoice[] = new Array<PurchaseInvoice>();

  supplierPickList: PickListItem[];
  purchaseInvoiceStatusPickList: PickListItem[];

  minDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 2000
}

  constructor(
    private purchaseInvoiceService: PurchaseInvoiceService,
    private pickListService: PickListService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder)
 
  {
    this.createForm();
  }

  ngOnInit() {

    this.initDates();

    this.pickListService.getSupplierPickList().subscribe(res => {
      this.supplierPickList = res;
    })

    this.pickListService.getPurchaseInvoiceStatusPickList().subscribe(res => {
      this.purchaseInvoiceStatusPickList = res;
    })


    this.fireSearchRequests();
    this.searchState = 'finished'; 
  }

  resetSearch() {
    this.formGroup.reset();
    this.fireSearchRequests();
  }


  // perform the search
  fireSearchRequests(page=1) {

    this.spinner.show();

    // get the search params from the form controls
    this.searchFilters.supplierId = this.formGroup.get("supplierddl").value;
    this.searchFilters.purchaseInvoiceStatusId = this.formGroup.get("purchaseInvoiceStatusId").value;

    this.searchFilters.receivedDateFromAsISOString = null;
    var receivedDateFrom = this.formGroup.get("receivedDateFrom").value;
    if (receivedDateFrom) {
      receivedDateFrom = DateHelpers.removeTimeFromDate(receivedDateFrom);
      this.searchFilters.receivedDateFromAsISOString = receivedDateFrom.toISOString();
    }

    this.searchFilters.receivedDateToAsISOString = null;
    var receivedDateTo = this.formGroup.get("receivedDateTo").value;
    if (receivedDateTo) {
      receivedDateTo = DateHelpers.removeTimeFromDate(receivedDateTo);
      this.searchFilters.receivedDateToAsISOString = receivedDateTo.toISOString();
    }

    this.searchFilters.page = page;

    this.searchState = 'loading';
    this.purchaseInvoiceService.search(this.searchFilters)
      .subscribe(this.onSearchLoaded.bind(this),
      error => {
        this.spinner.hide();
        this.router.navigate(['/home']);
        throw error;    // will go to the global error handler
      }
    );
  }


  onSearchLoaded(result: PaginatedResult<PurchaseInvoice>){
    this.pagination = Pagination.fromJson(result);
    this.searchResults = result.results;
    this.searchState = 'finished';

    this.spinner.hide();
  
    // the json coming back from the server is adding one to the page (I think angular assumes it's zero based ???)
    if (this.pagination.page > 1)
      this.pagination.page = this.pagination.page - 1;
  }


  onSearchSubmit() {
      if (this.formGroup.valid) {
          this.fireSearchRequests();
      }
  }

  onSearchReset()
  {
      this.selectedPage = 1;
      this.formGroup.reset();
      this.fireSearchRequests();
  }

  onPurchaseInvoiceDetailClick(purchaseInvoiceId: number) {
    this.router.navigate(["pur-invoice-detail", purchaseInvoiceId])
  } 

  onPurchaseInvoiceModifyClick(purchaseInvoiceId: number) {
    this.router.navigate(["pur-invoice-maint", false, purchaseInvoiceId])
  } 

  createForm() {

    this.formGroup = this.fb.group({
      supplierddl: [null],
      purchaseInvoiceStatusId: [null],
      receivedDateFrom: [''],
      receivedDateTo: [''],
    });

  }
  
  
  //This function is called automatically by the ngBootstrap pagination component on page load passing page=1.
  onPageClicked(page: number) {
    if (page && page !== this.pagination.page) {
      this.fireSearchRequests(page);
    }
  }


  

  // pagination will also only return one page of data
  initDates() {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 2);
    this.receivedDateFromControl.setValue(lastMonth);
    this.receivedDateToControl.setValue(today);
  }


  get receivedDateFromControl() { return this.formGroup.get('receivedDateFrom'); }
  get receivedDateToControl() { return this.formGroup.get('receivedDateTo'); }



}
