import { Guid } from "guid-typescript";

export class  SalesInvoice {

    salesInvoiceId: number;
    invoiceGuid: Guid;
    messageUuid: string;
    tenantId: number;
    invoiceLayoutType: string;
    invoiceNumber: string;
    invoiceDate: Date;
    invoiceCurrencyCode: string;
    payableAmount: number;
    sentDate: Date;
    salesInvoiceStatusId: number;
    salesInvoiceStatusName : string;
    customerId: number;
    customerGuid: Guid;
    customerName: string;
    customerParticipantIdentifier: string;
    cmlFileLocation: string;
    xmlFileName: string;
    selectToSend: boolean;
    errorList: SalesInvoiceError[];
    historyList: SalesInvoiceHistory[];
}


export class SalesInvoiceError {
    salesInvoiceHistoryId: number;
    salesInvoiceId: number;
    tenantId: number;
    errorMessage: string;
}


export class  SalesInvoiceErp {
    invoiceGuid: Guid;
    tenantId: number;
    customer: SalesInvoiceCustomer;
    erpStatus: string;
    invoiceDeliveryStatus: string;
    invoiceLayoutName: string;
    invoiceNumber: string;
    invoiceDate: Date;
    totalAmount: number;

    checked?; boolean;
    
}

export class  SalesInvoiceCustomer {
    customerGuid: Guid;
    customerName: string;
}

export class SalesInvoiceHistory {
    salesInvoiceHistoryId: number;
    salesInvoiceId: number;
    tenantId: number;
    workFlowActionTypeId: number;
    actionedOn: Date;
    actionedBy: string;
    actionDescription: string;
}

export class SalesInvoicePatch {
    salesInvoiceId: number;
    salesInvoiceStatusId: number;
}





