import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../../../core/helpers/form-helpers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from '../../../../core/models/customer';
import { PickListService } from '../../../../core/services/pickList.service';
import { PickListItem } from '../../../../core/models/pickList';
import { CustomerService } from '../../../../core/services/customer.service';
import { ToasterService } from '../../../../core/services/toaster.service';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Guid } from "guid-typescript";
import { stringify } from 'querystring';


@Component({
  selector: 'app-customer-maint',
  templateUrl: './customer-maint.component.html',
  styleUrls: ['./customer-maint.component.scss']
})

export class CustomerMaintComponent implements OnInit {
  customerId: number;
  customer: Customer = new Customer();

  countryPickList: PickListItem[];

  customerForm: FormGroup;
  isEditable: boolean;
  action: string;
  savingState: string;

  states = {
    isShowAddCustomerRoleForm: false
  };

  @Output()
  indicatoreChange: EventEmitter<any> = new EventEmitter();

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private route:ActivatedRoute,
      private spinner: NgxSpinnerService,
      private customerService: CustomerService,
      private pickListService: PickListService,
      private toaster: ToasterService
   ) {}  

  ngOnInit() {
    this.route.params.subscribe(params => this.action = params['action'] );
    this.route.params.subscribe(params => this.customerId = params['customerId']);

    this.isEditable = this.action != 'view';

    this.pickListService.getCountryPickList().subscribe(res => {
      this.countryPickList = res;
    })

    this.customerForm = new FormGroup({
      customerId: new FormControl(""),
      customerName: new FormControl("", Validators.required),
      //customerGuid: new FormControl(""),
      isEInvoicingEnabled: new FormControl(""),
      erpIdentifier: new FormControl(""),
      ediSchemaId: new FormControl(""),
      ediParticipantIdentifier: new FormControl(""),
      contactName: new FormControl(""),
      contactPhone: new FormControl(""),
      contactEmail: new FormControl("", Validators.required),
      billing_Street: new FormControl(""),
      billing_City: new FormControl(""),
      billing_State: new FormControl(""),
      billing_PostCode: new FormControl(""),
      billing_CountryCode: new FormControl(""),
      shipTo_Street: new FormControl(""),
      shipTo_City: new FormControl(""),
      shipTo_State: new FormControl(""),
      shipTo_PostCode: new FormControl(""),
      shipTo_CountryCode: new FormControl(""),
    });

    if (this.action === 'add') {
    }
    else
      this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.customerService.getById(this.customerId)
      .subscribe(this.onDataLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(["customer"]);
          throw error;       // will go to the global error handler
        }
    );
  }


  onDataLoaded(obj: Customer) {
    this.customer = obj;
 
    this.customerForm = this.formBuilder.group({
      customerId: [obj.customerId],
      customerName: [obj.customerName, Validators.required],
      //customerGuid: [obj.customerGuid],
      isEInvoicingEnabled: [obj.isEInvoicingEnabled],
      erpIdentifier: [obj.erpIdentifier],
      ediSchemaId:  [obj.ediSchemaId],
      ediParticipantIdentifier: [obj.ediParticipantIdentifier],
      contactName: [obj.contactName],
      contactPhone: [obj.contactPhone],
      contactEmail: [obj.contactEmail, Validators.required],
      billing_Street: [obj.billing_Street],
      billing_City: [obj.billing_City],
      billing_State: [obj.billing_State],
      billing_PostCode: [obj.billing_PostCode],
      billing_CountryCode: [obj.billing_CountryCode],
      shipTo_Street: [obj.shipTo_Street],
      shipTo_City: [obj.shipTo_City],
      shipTo_State: [obj.shipTo_State],
      shipTo_PostCode: [obj.shipTo_PostCode],
      shipTo_CountryCode: [obj.shipTo_CountryCode],
    });

    this.spinner.hide();

  }

  
  onSubmitClick() {
    if (this.customerForm.invalid) {
      FormHelpers.validateFormGroup(this.customerForm);
    }
    else {

      // create a model with values returned from the reactive form
      const modifiedCustomer: Customer = this.customerForm.getRawValue();

      // get the data that may have changed from the reactive form controls 
      // & update the orinal order model so what is not updated on Form is not clobbered in db

      this.customer.customerId = modifiedCustomer.customerId;
      this.customer.isEInvoicingEnabled = modifiedCustomer.isEInvoicingEnabled;
      this.customer.erpIdentifier = modifiedCustomer.erpIdentifier;
      this.customer.ediSchemaId = modifiedCustomer.ediSchemaId;
      this.customer.ediParticipantIdentifier = modifiedCustomer.ediParticipantIdentifier;
      this.customer.contactName = modifiedCustomer.contactName;
      this.customer.contactPhone = modifiedCustomer.contactPhone;
      this.customer.contactEmail = modifiedCustomer.contactEmail;
      this.customer.billing_Street = modifiedCustomer.billing_Street;
      this.customer.billing_City = modifiedCustomer.billing_City;
      this.customer.billing_State = modifiedCustomer.billing_State;
      this.customer.billing_PostCode = modifiedCustomer.billing_PostCode;
      this.customer.billing_CountryCode = modifiedCustomer.billing_CountryCode;
      this.customer.shipTo_Street = modifiedCustomer.shipTo_Street;
      this.customer.shipTo_City = modifiedCustomer.shipTo_City;
      this.customer.shipTo_State = modifiedCustomer.shipTo_State;
      this.customer.shipTo_PostCode = modifiedCustomer.shipTo_PostCode;
      this.customer.shipTo_CountryCode = modifiedCustomer.shipTo_CountryCode;

      this.savingState = 'loading';

      if (this.action === 'add') {
        this.customer.customerId = 0;

        var newCustomer = this.customerService.add(this.customer)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }
      else {
        this.customerService.update(this.customer)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }

    }
  }


  onSavingSuccess() {
    this.savingState = "finished";
    this.router.navigate(["customer"])
    this.toaster.showSuccess('Successfully saved customer.');
  }

  onSavingError(error) {
    this.savingState = "";
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["home"])
  }

  onModifyClick() {
    this.action = 'edit';
    this.isEditable = true;
  } 

  lastFieldKeydown(event) {
    if (event.key === 'Enter') {
      this.onSubmitClick();
    }
  }

  get customerIdControl() { return this.customerForm.get("customerId"); }
  get isEInvoicingEnabledControl() { return this.customerForm.get("isEInvoicingEnabled"); }
  get erpIdentifierControl() { return this.customerForm.get("erpIdentifier"); }
  get ediSchemaIdControl() { return this.customerForm.get("ediSchemaId"); }
  get ediParticipantIdentifierControl() { return this.customerForm.get("ediParticipantIdentifier"); }
  get contactNameControl() { return this.customerForm.get("contactName"); }
  get contactPhoneControl() { return this.customerForm.get("contactEmail"); }
  get contactEmailControl() { return this.customerForm.get("contactEmail"); }
  get billing_StreetControl() { return this.customerForm.get("billing_Street"); }
  get billing_CityControl() { return this.customerForm.get("billing_City"); }
  get billing_StateControl() { return this.customerForm.get("billing_State"); }
  get billing_PostCodeControl() { return this.customerForm.get("billing_PostCode"); }
  get billing_CountryCodeControl() { return this.customerForm.get("billing_CountryCode"); }
  get shipTo_StreetControl() { return this.customerForm.get("shipTo_Street"); }
  get shipTo_CityControl() { return this.customerForm.get("shipTo_City"); }
  get shipTo_StateControl() { return this.customerForm.get("shipTo_State"); }
  get shipTo_PostCodeControl() { return this.customerForm.get("shipTo_PostCode"); }
  get shipTo_CountryCodeControl() { return this.customerForm.get("shipTo_CountryCode"); }
}
