
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ComponentRef, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'simple-modal-confirm',
  templateUrl: './simple-modal-confirm.component.html',
  styleUrls: ['./simple-modal-confirm.component.scss']
})
export class SimpleModalConfirmComponent implements OnInit {
  data: SimpleModalOptions = {}
  
  @Output()
  confirmed  = new EventEmitter 

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    
  }

  confirm(){
    this.confirmed.emit(true)
    this.activeModal.close()
  }
  
  close(){
    this.activeModal.close()
  }

}

export interface SimpleModalOptions {
  title?: String
  content?: String
}
