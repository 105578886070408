import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
//import { LoggingService } from './services/logging.service';
import { ErrorService } from '../services/error.service';
import { ToasterService } from '../services/toaster.service';
import { Router } from '@angular/router';
import { NgZone} from '@angular/core';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {

        const errorService = this.injector.get(ErrorService);
        //const logger = this.injector.get(LoggingService);
        const toaster = this.injector.get(ToasterService);
        const router = this.injector.get(Router);

        const zone = this.injector.get(NgZone);


        let message;
        let stackTrace;

        if (error instanceof HttpErrorResponse) {
            // Server Error

            message = errorService.getMessageFromErrorResponse(error);

            // if (error.status as number === 401 || error.status as number === 403) {
            //  zone.run(() => {
            //    router.navigate(['/unauthorised-area'], { queryParams: { errorMessage: message } });
            //  });
          
            //} else {
              //message = errorService.getServerMessage(error);
              //message = errorService.getMessageFromErrorResponse(error);
              stackTrace = errorService.getServerStack(error);
              toaster.showError(message);
            //}

        } else {
            // // Client Error
            message = errorService.getClientMessage(error);
            stackTrace = errorService.getClientStack(error);
            toaster.showError(message);

        }

        // Always log errors
        //logger.logError(message, stackTrace);

        console.error(error);
    }
}