
  <div class="card card-blue-border" style="margin-top: 10px">
    <div class="card-header card-blue-header">
      <span *ngIf="action=='view'">InventoryItem Details</span>
      <span *ngIf="action=='add'">Add New InventoryItem</span>
      <span *ngIf="action=='edit'">Edit InventoryItem</span>
    </div>
    <div class="card-body">


      <form [formGroup]="inventoryItemForm">
        <div class="row">
          <div class="col-md-8">


            <div class="form-group row">
              <label for="inventoryItemId" class="col-sm-3 col-form-label">Id:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="inventoryItemId" [readonly]="true" />
              </div>
            </div>
        
            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Name:</label> </div>
              <div class="col-sm-8">
                <span class="form-control readonly-bg">{{inventoryItem.inventoryItemName}}</span>
              </div>
            </div>

            <div class="form-group row">
              <label for="inventoryItemGuid" class="col-sm-3 col-form-label">InventoryItem GUID:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg">{{inventoryItem.inventoryItemGuid}}</span>
              </div>
            </div>

            <div class="form-group row">
              <label for="inventoryItemGuid" class="col-sm-3 col-form-label">Item Identifier:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg">{{inventoryItem.inventoryItemIdentifier}}</span>
              </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-3"> <label class="col-form-label">Is Active:</label> </div>
                <div class="col-sm-8">
                    <span *ngIf="inventoryItem.isActive" class="form-control readonly-bg"> Yes </span>
                    <span *ngIf="inventoryItem.isActive === false" class="form-control readonly-bg"> No </span>
                </div>
              </div>
          </div>


          <div class="col-md-4">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Created:</label>
              <div class="col-sm-6">
                <span class="form-control readonly-bg">{{inventoryItem.createdOn | stDateTime}}</span>
              </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Created By:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{inventoryItem.createdBy}}</span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Last Modified:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{inventoryItem.modifiedOn | stDateTime}}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Modified By:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{inventoryItem.modifiedBy}}</span>
                </div>
              </div>
          </div>


        </div>
        
      </form>

  </div>
</div>


<div class="form-group" style="margin-top:5px;">
  <button type="button" class="btn btn-outline-dark group-button" (click)="onCancel()">
    <span *ngIf="action=='view'">Close</span>
    <span *ngIf="action=='add'">Cancel</span>
    <span *ngIf="action=='edit'">Cancel</span>
  </button>
  <span *ngIf="action=='add' || action=='edit'"> 
    <button type="button" class="btn btn-outline-primary group-button" [disabled]="savingState === 'loading'" (click)="onSubmitClick()">
      <span *ngIf="savingState !== 'loading'">Save & Close</span>
      <span *ngIf="savingState === 'loading'">Saving...</span>
    </button>
  </span>

  <span *ngIf="action=='view'"> 
    <!-- <button type="button" class="btn btn-primary group-button" (click)="onModifyClick()"> Modify </button> -->
  </span>

</div>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>






