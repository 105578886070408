import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { InventoryItem } from '../models/inventory-item';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated-result';
import { AuthenticationService } from '../services/authentication.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class InventoryItemService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  search(filters: InventoryItem, page = 1, pageSize = 20): Observable<PaginatedResult<InventoryItem>> {

    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();

    // add the tenantId to the param list
    requestParams = requestParams.append("tenantId", userTenantId.toString());

    if (filters.inventoryItemId) {
      requestParams = requestParams.append("inventoryItemId", filters.inventoryItemId.toString());
    }
    if (filters.inventoryItemName) {
      requestParams = requestParams.append("inventoryItemName", filters.inventoryItemName);
    }


    requestParams = requestParams.append("sortkey", "1");
    requestParams = requestParams.append("sortDir", "asc");

    // pagination
    requestParams = requestParams.append("pageSize", pageSize.toString());
    requestParams = requestParams.append("page", (page).toString()); 

    return this.httpClient.get<PaginatedResult<InventoryItem>>(environment.api + "/InventoryItemCrud/search", {
      params: requestParams,
    });
  }
    


  public getInventoryItemList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      return this.httpClient.get<InventoryItem[]>(`${environment.api}/InventoryItemCrud/getInventoryItemList/${userTenantId}`);
  }

  public getById(inventoryItemId: number) :Observable<InventoryItem> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    requestParams = requestParams.append("inventoryItemId", inventoryItemId.toString());
    return this.httpClient.get<InventoryItem>(`${environment.api}/inventoryItemcrud`,  {params: requestParams} );
  }

  public add(inventoryItem: any): Observable<InventoryItem> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    inventoryItem.tenantId = userTenantId;
    return this.httpClient.post<InventoryItem>(`${environment.api}/inventoryItemcrud/`, inventoryItem, httpOptions);
  }

  public update(inventoryItem: any): Observable<any> {
    // const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    // inventoryItem.tenantId = userTenantId;
    return this.httpClient.put(`${environment.api}/inventoryItemcrud/`, inventoryItem, httpOptions);
  }

  delete(inventoryItemId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.api}/inventoryItemcrud/${inventoryItemId}`);
  }
  
}
