import { Constants } from './constants';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';

import {MatProgressSpinnerModule} from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';

import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from './shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';

import { DatePickerComponent } from './shared/date-picker/date-picker.component';
import { AuthGuard } from './core/guards/auth-guard';

import { AuthenticationInterceptor } from './auth/authentication-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { GlobalErrorHandler } from './core/errors/GlobalErrorHandler';

import { HomeComponent } from './pages/home/home.component';
import { PromoComponent } from './pages/promo/promo.component'
import { AboutComponent } from './pages/about/about.component';

import { CustomerComponent } from './pages/crud/customer/customer.component';
import { CustomerMaintComponent } from './pages/crud/customer/customer-maint/customer-maint.component';

import { SupplierComponent } from './pages/crud/supplier/supplier.component';
import { SupplierMaintComponent } from './pages/crud/supplier/supplier-maint/supplier-maint.component';
import { InventoryItemComponent } from './pages/crud/inventory-item/inventory-item.component';
import { InventoryItemMaintComponent } from './pages/crud/inventory-item/inventory-item-maint/inventory-item-maint.component';

import { AccountComponent } from './pages/crud/account/account.component';
import { TaxCodeComponent } from './pages/crud/tax-code/tax-code.component';

import { AuthUserComponent } from './pages/crud/auth-user/auth-user';
import { AuthUserMaintComponent } from './pages/crud/auth-user/auth-user-maint/auth-user-maint.component';
import { AuthUserSetPasswordComponent } from './pages/crud/auth-user/auth-user-set-pw-modal/auth-user-set-pw-modal.component';

import { PurchaseInvoiceSearchComponent } from './pages/pur-invoice/pur-invoice-search/pur-invoice-search.component';
import { PurchaseInvoiceDetailComponent } from './pages/pur-invoice/pur-invoice-detail/pur-invoice-detail.component';
import { PurchaseInvoiceMaintComponent } from './pages/pur-invoice/pur-invoice-maint/pur-invoice-maint.component';
import { PurchaseInvoiceLineModalComponent } from './pages//pur-invoice/pur-invoice-line-modal/pur-invoice-line-modal.component';
import { PurchaseInvoiceCollectComponent } from './pages/pur-invoice/pur-invoice-collect/pur-invoice-collect.component';
import { PurchaseInvoiceDashboardComponent } from './pages/pur-invoice/pur-invoice-dashboard/pur-invoice-dashboard.component';

import { SalesInvoiceSearchComponent } from './pages/sales-invoice/sales-invoice-search/sales-invoice-search.component';
import { SalesInvoiceExportComponent } from './pages/sales-invoice/sales-invoice-export/sales-invoice-export.component';
import { SalesInvoiceDetailComponent } from './pages/sales-invoice/sales-invoice-detail/sales-invoice-detail.component';

import { DataSyncComponent } from './pages/data-sync/data-sync.component';
import { ScheduledJobComponent } from './pages/sched-job/sched-job.component';
import { SettingsComponent } from './pages/settings/settings.component';

import { SimpleModalConfirmComponent } from './shared/simple-modal-confirm/simple-modal-confirm.component';
import { HelpComponent } from './pages/help/help.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    DatePickerComponent,
    HomeComponent,
    AboutComponent,
    SimpleModalConfirmComponent,
    CustomerComponent, CustomerMaintComponent,
    SupplierComponent, SupplierMaintComponent,
    InventoryItemComponent, InventoryItemMaintComponent,
    AuthUserComponent, AuthUserMaintComponent, AuthUserSetPasswordComponent,
    AccountComponent, TaxCodeComponent,
    PurchaseInvoiceSearchComponent, PurchaseInvoiceDetailComponent, PurchaseInvoiceMaintComponent, PurchaseInvoiceLineModalComponent,
    PurchaseInvoiceCollectComponent,
    PurchaseInvoiceDashboardComponent,
    SettingsComponent,
    SalesInvoiceSearchComponent, SalesInvoiceExportComponent, SalesInvoiceDetailComponent,
     DataSyncComponent, ScheduledJobComponent,
    PromoComponent,
    HelpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    SharedModule,
    NgxSpinnerModule, BrowserAnimationsModule,
    MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatExpansionModule,
    MatMenuModule,
    MatProgressSpinnerModule,
  ],


  entryComponents: [
    PurchaseInvoiceLineModalComponent,
    CustomerMaintComponent,
    SupplierMaintComponent,
    InventoryItemMaintComponent,
    AuthUserSetPasswordComponent,
    SimpleModalConfirmComponent
  ],


  providers: [

    [{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }],
    [
      ErrorHandler,
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler,
      }
    ],

    AuthGuard,
    Constants.DATE_PARSER_FORMATTER,
    Constants.DATE_ADAPTER
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
