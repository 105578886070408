
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  public constructor(private titleService: Title) { }
  ngOnInit() {
     this.titleService.setTitle('edipak');
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


}

