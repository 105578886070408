import { Guid } from "guid-typescript";

export class Supplier {
    supplierId: number;
    supplierGuid: Guid;
    erpIdentifier: string;
    ediParticipantIdentifier: string;
    ediSchemaId: string;

    isActive: boolean;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    tenantId: number;
    supplierName: string;
    contactEmail: string;
    invoiceLayoutType: string;
    accountingCostCode: string;
}
