
export enum PurchaseInvoiceStatusEnum {
    pending = 10,
    rejected = 40,
    cancelled = 50,
    uploadedToERP = 200,

    errorUploadingToERP = 550
}

export enum SalesInvoiceStatusEnum {
    new = 10,
    onHold = 15,
    cancelled = 20,
    readyToSend = 30,
    sendInProgress = 50,
    sendSuccess = 200,
    sendFailed = 550,
    sendFailedValidation = 552,
}

export enum AuthRoleEnum {
    administrator = 10,
    accountsPayable = 20,
    accountsReceivable = 25,
    approvePurchaseInvoices = 30
}
