import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../core/services/Account.service';
import { Account } from '../../../core/models/Account';
import { Params, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  accountList: Account[] = new Array<Account>();
 
  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ){ }

    ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.accountService.getAccountList()
      .subscribe(this.onDataLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(["account"]);
          throw error;       // will go to the global error handler
        }
    );
  }

  onDataLoaded(result: Account[]) {
    this.accountList = result;
    this.spinner.hide();
  }

}
