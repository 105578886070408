import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Injector, forwardRef, ViewChild, HostListener, ElementRef, Input } from '@angular/core';
import { NgbInputDatepicker, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {

  _selectedDate: Date;
  ngControl: NgControl;
  isDisabled = false;

  @ViewChild('datePicker',{static:false})
  datePicker: NgbInputDatepicker;

  @Input()
  minDate?: NgbDateStruct;

  @Input()
  maxDate?: NgbDateStruct;

  constructor(
    private inj: Injector,
    private eRef: ElementRef
  ) {
    
  }

  ngOnInit() {
    this.minDate = this.minDate || {
      year: 1900,
      month: 1,
      day: 1
    }
    const today = new Date();
    this.maxDate = this.maxDate || {
      year: today.getFullYear() + 10,
      month: today.getMonth() + 1,
      day: today.getDate()
    }
    this.ngControl = this.inj.get(NgControl);
  }

  writeValue(value: Date): void {
    this._selectedDate = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void { }

  propagateChange = (_: any) => { };

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  dateChanged() {
    this.propagateChange(this._selectedDate);
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.datePicker.close();
    }
  }

}
