import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Tenant } from '../models/tenant';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated-result';

import { AuthenticationService } from '../services/authentication.service';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  userTenantId = this.authenticationService.getCurrentUser().userTenantId;

  public getById(tenantId: number) :Observable<Tenant> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    return this.httpClient.get<Tenant>(`${environment.api}/tenant`,  {params: requestParams} );
  }

  public updateSettings(tenant: any): Observable<any> {
    return this.httpClient.patch(`${environment.api}/tenant/update-settings`, tenant, httpOptions);
  }
  
}
