import { Component, OnInit } from '@angular/core';
import { SalesInvoice, SalesInvoiceHistory, SalesInvoicePatch } from '../../../core/models/sales-invoice';
import { SalesInvoiceService } from '../../../core/services/sales-invoice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/authentication.service';
import { ToasterService } from '../../../core/services/toaster.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalOptions, SimpleModalConfirmComponent } from "../../../shared/simple-modal-confirm/simple-modal-confirm.component";
import { SalesInvoiceStatusEnum } from '../../../core/models/enum';

import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-sales-invoice-detail',
  templateUrl: './sales-invoice-detail.component.html',
  styleUrls: ['./sales-invoice-detail.component.scss']
})
export class SalesInvoiceDetailComponent implements OnInit {

  salesInvoiceId : number = 1;
  salesInvoice : SalesInvoice = new SalesInvoice();

  //isShowSalesInvoiceHistory: boolean = true;

  statusEnum = SalesInvoiceStatusEnum;

  validationErrorList: string[] = new Array<string>();

  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private spinner: NgxSpinnerService,
    private salesInvoiceService: SalesInvoiceService,
    private toaster: ToasterService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => this.salesInvoiceId = params['salesInvoiceId']);
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.salesInvoiceService.getById(this.salesInvoiceId)
      .subscribe(this.onDataLoaded.bind(this), this.onServiceError.bind(this) )
  }

  onDataLoaded(result: SalesInvoice){
     this.salesInvoice = result;
      this.spinner.hide();
  }
  
  onServiceError(error)
  {
    this.spinner.hide();
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["sales-invoice-search"])
  }




  //---Export the Invoice---------------------------------------------------------------------------------------------

  onSalesInvoiceRetrySendingClick() {
    this.spinner.show();
    this.salesInvoiceService.reSendFailedInvoice(this.salesInvoice)
      .subscribe(this.onReSendSuccess.bind(this), this.onServiceError.bind(this));
  } 

  onReSendSuccess(result: SalesInvoice) {
    this.spinner.hide();
    this.salesInvoice = result;

    if (result.salesInvoiceStatusId < 500)
      this.toaster.showSuccess('ReSent Invoice Successfully');
    else
      this.toaster.showError('FAILED to Send Invoice');
  }

//------------------------------------------------------------------------------------------------------------------


  onSalesInvoiceRefreshClick() {
    this.spinner.show();
    this.salesInvoiceService.refreshSalesInvoiced(this.salesInvoiceId)
      .subscribe(this.onInvoiceRefreshed.bind(this), this.onServiceError.bind(this) )
  } 

  onInvoiceRefreshed(result: SalesInvoice) {
    this.spinner.hide();
    if (result === null)
    this.toaster.showError('Refresh FAILED');
    else
    this.salesInvoice = result;
  }





  onCancelInvoiceConfirm() {
    let titleText: string = "Are you sure you want to CANCEL this Sales Invoice?";
    let newStatusId: number = SalesInvoiceStatusEnum.cancelled;
    this.changeInvoiceStatus(newStatusId, titleText );
  }

  onReinstateInvoiceConfirm() {
    let titleText: string = "Are you sure you want to REINSTATE this Sales Invoice to Ready to Send status?";
    let newStatusId: number = SalesInvoiceStatusEnum.readyToSend;
    this.changeInvoiceStatus(newStatusId, titleText );
  }

  onPutOnHoldConfirm() {
    let titleText: string = "Are you sure you want to put this Sales Invoice On Hold?";
    let newStatusId: number = SalesInvoiceStatusEnum.onHold;
    this.changeInvoiceStatus(newStatusId, titleText );
  }

  



  private changeInvoiceStatus(newStatusId: number, titleText: string ) {
    const data = <SimpleModalOptions>{ title: titleText };
    const modalRef = this.modalService.open(SimpleModalConfirmComponent);
    const modalInstance: SimpleModalConfirmComponent = modalRef.componentInstance;
    modalInstance.data = data;

    let salesInvoicePatch = new SalesInvoicePatch();
    salesInvoicePatch.salesInvoiceId = this.salesInvoice.salesInvoiceId;
    salesInvoicePatch.salesInvoiceStatusId = newStatusId;

    modalInstance.confirmed.subscribe(res => {
      if (res) {
        this.salesInvoiceService.setStatus(salesInvoicePatch).subscribe((response) => {
          this.closeThisView();
          this.salesInvoice.salesInvoiceStatusId = newStatusId;
        });
      }
    });
  }


  private closeThisView() {
    this.router.navigate(["sales-invoice-search"])
  }

  onShowSalesInvoiceHistoryClick() {
    window.scrollTo(0,document.body.scrollHeight);
  } 

}
