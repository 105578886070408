
<div style="background-image: url(/assets/images/edipak_header.jpg); background-repeat: no-repeat; background-size: cover;">
  <br/><br/><br/>
  <br/><br/><br/>
</div>

<div class="row" style="margin-left:5px">
    <div class="col-md-7">

      <div class="card card-blue-border" style="margin-top: 10px; max-width:375px;">
        <div class="card-header card-blue-header">
          Please Login:
        </div>
        <div class="card-body">

          <form [formGroup]="loginForm">
              <div class="row">

                  <div class="form-group row login-row">
                    <div class="col-md-1">
                        <span><i class="fa fa-envelope-o"></i></span>
                    </div>

                    <div class="col-md-10">

                        <input class="form-control" autofocus type="email" maxlength="80" placeholder = "Login Name (email address)"
                          [ngClass]="{'is-invalid': loginNameControl.invalid && (loginNameControl.dirty || loginNameControl.touched)}"
                          formControlName="loginName" /> 

                        <div *ngIf="loginNameControl.errors" class="invalid-feedback">
                            <div [hidden]="! loginNameControl.errors.required">LoginName is required.</div>
                        </div>

                    </div>
                  </div>

                  
                  <div class="form-group row  login-row">
                      <div class="col-md-1">
                          <span><i class="fa fa-key"></i></span>
                      </div>
                      <div class="col-md-10">
                        <input class="form-control" type="password" maxlength="50" placeholder = "Password" (keydown)="lastFieldKeydown($event)"
                        [ngClass]="{'is-invalid': passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)}"
                        formControlName="password" /> 

                        <div *ngIf=" passwordControl.errors" class="invalid-feedback">
                          <div [hidden]="! passwordControl.errors.required">Password is required.</div>
                        </div>

                      </div>
                  </div>

               </div>

          </form>


          <div class="form-group" style="margin-top:5px;">
              <div class="col-md-offset-2 col-md-10">
                  <button type="button" class="btn btn-primary" (click)="onSubmitClick()"> Login</button>
              </div>
          </div>

        </div>
      </div>

  </div>
</div>


<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>
