import { CustomValidators } from '../../../core/custom-validators';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../../core/helpers/form-helpers';

import { PurchaseInvoice, PurchaseInvoiceHistory } from '../../../core/models/pur-invoice';
import { InvoiceLine } from '../../../core/models/pur-invoice';
import { PurchaseInvoiceService } from '../../../core/services/pur-invoice.service';

import { PickListService } from '../../../core/services/pickList.service';
import { PickListItem } from '../../../core/models/pickList';

import { PurchaseInvoiceStatusEnum } from '../../../core/models/enum';
import { DateHelpers } from '../../../core/helpers/date-helpers';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterService } from '../../../core/services/toaster.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

import { PurchaseInvoiceLineModalComponent } from '../pur-invoice-line-modal/pur-invoice-line-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalOptions, SimpleModalConfirmComponent } from "../../../shared/simple-modal-confirm/simple-modal-confirm.component";

@Component({
  selector: 'app-pur-invoice-maint',
  templateUrl: './pur-invoice-maint.component.html',
  styleUrls: ['./pur-invoice-maint.component.scss']
})

export class PurchaseInvoiceMaintComponent implements OnInit {

  purchaseInvoiceId : number = null;
  purchaseInvoice : PurchaseInvoice = new PurchaseInvoice();

  purchaseInvoiceForm: FormGroup;

  supplierPickList: PickListItem[];
  invoiceLayoutPickList: PickListItem[];

  newInvoiceLine = new InvoiceLine();

  savingState: string;

  // isUserPurchaseInvoiceApprover: boolean = false;

  states = {
    isShowAddInvoiceLineForm: false
  };

  minDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 2000
  }


  constructor(
    private fb: FormBuilder,
    private purchaseInvoiceService: PurchaseInvoiceService,
    private pickListService: PickListService,
    private router: Router,
    private route:ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toaster: ToasterService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal

    ) {
    this.purchaseInvoiceForm = this.fb.group({
      'supplierId': [null, Validators.required],
      'invoiceLayoutType': [null, Validators.required],
    })
   }

  ngOnInit() {
    //this.route.params.subscribe(params => this.isAdding = JSON.parse(params['isAdding']) );
    this.route.params.subscribe(params => this.purchaseInvoiceId = params['purchaseInvoiceId']);

    this.spinner.show();

    this.pickListService.getSupplierPickList().subscribe(res => {
      this.supplierPickList = res;
    })

    this.pickListService.getInvoiceLayoutPickList().subscribe(res => {
      this.invoiceLayoutPickList = res;
    })

    // this.isUserPurchaseInvoiceApprover = this.authenticationService.isUserPurchaseInvoiceApprover();

    this.loadData();

  }


  loadData() {
    this.spinner.show();
    this.purchaseInvoiceService.getById(this.purchaseInvoiceId)
      .subscribe(this.onDataLoaded.bind(this), this.onDataLoadError.bind(this) )
  }

  onDataLoaded(result: PurchaseInvoice){
     this.purchaseInvoice = result;
     //this.purchaseInvoice.modifiedLines = new Array<number>();

    this.purchaseInvoiceForm.patchValue({ supplierId: result.supplierId, });
    this.purchaseInvoiceForm.patchValue({ invoiceLayoutType: result.invoiceLayoutType, });
    this.spinner.hide();
  }
  
  onDataLoadError(error)
  {
    this.spinner.hide();
    throw error;    // will go to the global error handler
  }


  onSubmitClick() {

    if (this.purchaseInvoiceForm.invalid) {

      FormHelpers.validateFormGroup(this.purchaseInvoiceForm);
    }
    else {

      // create a model with values returned from the reactive form
      const modifiedPurchaseInvoice: PurchaseInvoice = this.purchaseInvoiceForm.getRawValue();

      // get the data that may have changed from the reactive form controls 
      // & update the orinal purchaseInvoice model so what is not updated on Form is not clobbered in db
      this.purchaseInvoice.supplierId = modifiedPurchaseInvoice.supplierId;
      this.purchaseInvoice.invoiceLayoutType = modifiedPurchaseInvoice.invoiceLayoutType;

      // get the SupplierName from the curently selected picklistitem for creating history rec in backend
      const selectedSupplier: PickListItem = this.supplierPickList.find( s => s.id === this.purchaseInvoice.supplierId);
      this.purchaseInvoice.supplierName =  selectedSupplier.name;
  
      this.savingState = 'loading';

      this.purchaseInvoiceService.update(this.purchaseInvoice)
        .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
        
    }
  }


  onSavingSuccess(newPurchaseInvoiceId: number) {
    this.savingState = "finished";
    this.router.navigate(["pur-invoice-search"])
  }

  onSavingError(error) {
    this.savingState = "";
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["pur-invoice-search"])
  }

  isPurchaseInvoiceApproved() {
    return this.purchaseInvoice.isApproved;
  }

  layoutTypeOnChange = function (ddlObject) {
    // get the LayoutType from the curently selected picklistitem using it's index
    const selectedIndex = ddlObject.currentTarget.selectedIndex;
    const selectedLayoutType: PickListItem = this.invoiceLayoutPickList[selectedIndex];
    this.purchaseInvoice.invoiceLayoutType =  selectedLayoutType.name;
    // alert("BillType has changed:" + this.purchaseInvoice.invoiceLayoutType);
  }

//----------------------------------------------------------------------------------------
//InvoiceLine
//----------------------------------------------------------------------------------------

editInvoiceLineModalComponent(invoiceLine: InvoiceLine) {

  invoiceLine.isDirty = false;

  const modalRef = this.modalService.open(PurchaseInvoiceLineModalComponent, { size: 'lg' });
  modalRef.componentInstance.invoiceLine = invoiceLine;
  modalRef.componentInstance.invoiceLayoutType = this.purchaseInvoice.invoiceLayoutType;

  modalRef.componentInstance.setModalForm(invoiceLine);
}


  get supplierIdControl() { return this.purchaseInvoiceForm.get("supplierId"); }
  get invoiceLayoutTypeControl() { return this.purchaseInvoiceForm.get("invoiceLayoutType"); }

}
