
import { Injectable, NgZone} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  
  constructor(
    protected toastr: ToastrService,
    private zone: NgZone) { }
  
  showSuccess(message: string): void {
    //Had an issue with the snackbar/toastr being ran outside of angular's zone.
    this.zone.run(() => {
      this.toastr.success(message);
    });
  }
  
  showError(message: string): void {
      this.zone.run(() => {
      // The second parameter is the text for the heading which is optional. 
      this.toastr.error(message);
    });
  }

  showInfo(message: string): void {
    this.zone.run(() => {
      this.toastr.info(message);
    });
  }

  showWarning(message: string): void {
    this.zone.run(() => {
      this.toastr.warning(message);
    });
  }

}