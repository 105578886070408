import { Component, OnInit } from '@angular/core';
import { DataSyncService } from '../../core/services/data-sync.service';
import { BackgroundJobResult } from '../../core/models/bg-job-result';
import { ToasterService } from '../../core/services/toaster.service';

@Component({
  selector: 'app-data-sync',
  templateUrl: './data-sync.component.html',
  styleUrls: ['./data-sync.component.scss']
})
export class DataSyncComponent implements OnInit {

  backgroundJobResult: BackgroundJobResult = new BackgroundJobResult();
  syncState: string;

  constructor(
      private dataSyncService: DataSyncService,
      private toaster: ToasterService
    ) { }

  ngOnInit() {
  }

  onSyncSupplierClick() {
    this.syncState = 'busy';
    this.dataSyncService.syncSuppliers()
      .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  onSyncCustomerClick() {
    this.syncState = 'busy';
    this.dataSyncService.syncCustomers()
      .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  onSyncInventoryItemClick() {
    this.syncState = 'busy';
    this.dataSyncService.syncInventoryItems()
    .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  onSyncAccountClick() {
    this.syncState = 'busy';
    this.dataSyncService.syncAccounts()
    .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  onSyncTaxCodeIdMappingsClick() {
    this.syncState = 'busy';
    this.dataSyncService.syncTaxCodeIdMappings()
    .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  onUploadPurchaseInvoicesToErpClick() {
    this.syncState = 'busy';
    this.dataSyncService.uploadPurchaseInvoicesToErp()
    .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  // onExportSalesInvoiceFromErpClick() {
  //   this.syncState = 'busy';
  //   this.dataSyncService.exportSalesInvoicesFromErp()
  //   .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  // }

  onSyncSuccess(result: BackgroundJobResult) {
    this.backgroundJobResult = result;
    this.backgroundJobResult.errorCount = this.backgroundJobResult.errorList.length;
    this.syncState = "finished";
    this.toaster.showSuccess('Completed ' + this.backgroundJobResult.jobName);
  }

  onSyncError(error) {
    this.syncState = "";
    throw error;    // will go to the global error handler
  }



}
