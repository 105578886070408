
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../core/helpers/form-helpers';
import { TenantService } from '../../core/services/tenant.service';
import { Tenant } from '../../core/models/tenant';
import { PickListService } from '../../core/services/pickList.service';
import { PickListItem } from '../../core/models/pickList';
import { ToasterService } from '../../core/services/toaster.service';
import { AuthRoleEnum } from '../../core/models/enum';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Guid } from "guid-typescript";


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  tenantId: number;
  tenant: Tenant = new  Tenant();

  timeZonePickList: PickListItem[];
  erpTypePickList: PickListItem[];
  countryPickList: PickListItem[];

  selectedCountryName: string;

  tenantForm: FormGroup;
  isEditable: boolean;
  action: string;
  savingState: string;

  states = {
    isShowAddTenantRoleForm: false
  };

  @Output()
  indicatoreChange: EventEmitter<any> = new EventEmitter();

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private route:ActivatedRoute,
      private spinner: NgxSpinnerService,
      private tenantService: TenantService,
      private pickListService: PickListService,
      private toaster: ToasterService
   ) {}  


  ngOnInit() {
    this.route.params.subscribe(params => this.action = params['action'] );
    this.route.params.subscribe(params => this.tenantId = params['tenantId']);

    this.isEditable = this.action != 'view';

    this.pickListService.getTimeZonePickList().subscribe(res => {
      this.timeZonePickList = res;
    })

    this.pickListService.getErpTypePickList().subscribe(res => {
      this.erpTypePickList = res;
    })

    this.pickListService.getCountryPickList().subscribe(res => {
      this.countryPickList = res;
    })

    this.tenantForm = new FormGroup({
      tenantId: new FormControl(""),
      tenantName: new FormControl("", Validators.required),
      tenantGuid: new FormControl(""),
      erpIdentifier: new FormControl("", Validators.required),
      ediSchemaId: new FormControl(""),
      ediParticipantIdentifier: new FormControl("", Validators.required),
      ediClientId: new FormControl(""),
      contactName: new FormControl(""),
      contactPhone: new FormControl(""),
      contactEmail: new FormControl("", Validators.required),
      timeZoneCode: new FormControl(""),
      erpTypeId: new FormControl("", Validators.required),
      erpVersion: new FormControl(""),
      erpBaseApiUrl: new FormControl(""),
      erpApiUsername: new FormControl(""),
      erpApiPassword: new FormControl(""),

      papApiUsername: new FormControl(""),
      papApiPassword: new FormControl(""),

      currencyCode: new FormControl(true, Validators.required),
      isAutoApprovePurchaseInvoices: new FormControl(false, Validators.required),
      postal_Street: new FormControl(""),
      postal_City: new FormControl(""),
      postal_State: new FormControl(""),
      postal_PostCode: new FormControl(""),
      postal_CountryCode: new FormControl(""),

    });

    this.spinner.show();

    if (this.action === 'add') {
    }
    else
      this.loadData();

  }


  loadData() {
    this.spinner.show();
    this.tenantService.getById(this.tenantId)
      .subscribe(this.onDataLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(["auth-user"]);
          throw error;       // will go to the global error handler
        }
    );
  }


  onDataLoaded(obj: Tenant) {
    this.tenant = obj;
 
    this.tenantForm = this.formBuilder.group({
      tenantId: [obj.tenantId],
      tenantName: [obj.tenantName, Validators.required],
      tenantGuid: [obj.tenantGuid],
      erpIdentifier: obj.erpIdentifier,
      ediSchemaId:  [obj.ediSchemaId],
      ediParticipantIdentifier: obj.ediParticipantIdentifier,
      ediClientId: [obj.ediClientId],
      isActive: [obj.isActive, Validators.required],
      contactName: [obj.contactName],
      contactPhone: [obj.contactPhone],
      contactEmail: [obj.contactEmail, Validators.required],
      timeZoneCode: [obj.timeZoneCode, Validators.required],
      erpTypeId: [obj.erpTypeId, Validators.required],
      erpVersion: [obj.erpVersion],
      erpBaseApiUrl: [obj.erpBaseApiUrl],
      erpApiUsername: [obj.erpApiUsername],
      erpApiPassword: [obj.erpApiPassword],

      papApiUsername: [obj.papApiUsername],
      papApiPassword: [obj.papApiPassword],

      currencyCode: [obj.currencyCode, Validators.required],
      isAutoApprovePurchaseInvoices: [obj.isAutoApprovePurchaseInvoices],
      // isAllowDuplicatePurInvoiceNoForSupp: [obj.isAllowDuplicatePurInvoiceNoForSupp],
      postal_Street: [obj.postal_Street],
      postal_City: [obj.postal_City],
      postal_State: [obj.postal_State],
      postal_PostCode: [obj.postal_PostCode],
      postal_CountryCode: [obj.postal_CountryCode],
    });

    this.spinner.hide();

  }

  
  onSubmitClick() {
    if (this.tenantForm.invalid) {
      FormHelpers.validateFormGroup(this.tenantForm);
    }
    else {

            // change value here as using angular guids you can only change the value by crerating a new guid
      // which is not compatible with dotnet guids
      if (this.tenantForm.get("tenantGuid").value === "")
            this.tenantForm.patchValue( { tenantGuid: '00000000-0000-0000-0000-000000000000' } );

      // create a model with values returned from the reactive form
      const modifiedTenant: Tenant = this.tenantForm.getRawValue();

      // get the data that may have changed from the reactive form controls 
      // & update the orinal tenant model so what is not updated on Form is not clobbered in db
      this.tenant.tenantName = modifiedTenant.tenantName;
      this.tenant.tenantGuid = modifiedTenant.tenantGuid;
      if (this.isGuidValid(this.tenant.tenantGuid) === false) {
        this.toaster.showError('Tenant GUID is not valid.');
        return false;
     }

      this.tenant.erpIdentifier = modifiedTenant.erpIdentifier;
      this.tenant.ediSchemaId = modifiedTenant.ediSchemaId;
      this.tenant.ediParticipantIdentifier = modifiedTenant.ediParticipantIdentifier;
      this.tenant.ediClientId = modifiedTenant.ediClientId;
      this.tenant.contactName = modifiedTenant.contactName;
      this.tenant.contactPhone = modifiedTenant.contactPhone;
     this.tenant.contactEmail = modifiedTenant.contactEmail;
      this.tenant.timeZoneCode = modifiedTenant.timeZoneCode;
      this.tenant.erpTypeId = modifiedTenant.erpTypeId;

      this.tenant.erpVersion = modifiedTenant.erpVersion;
      this.tenant.erpBaseApiUrl = modifiedTenant.erpBaseApiUrl;
      this.tenant.erpApiUsername = modifiedTenant.erpApiUsername;
      this.tenant.erpApiPassword = modifiedTenant.erpApiPassword;

      this.tenant.papApiUsername = modifiedTenant.papApiUsername;
      this.tenant.papApiPassword = modifiedTenant.papApiPassword;

      this.tenant.currencyCode = modifiedTenant.currencyCode;
      this.tenant.isAutoApprovePurchaseInvoices = modifiedTenant.isAutoApprovePurchaseInvoices;
      // this.tenant.isAllowDuplicatePurInvoiceNoForSupp = modifiedTenant.isAllowDuplicatePurInvoiceNoForSupp;

      this.tenant.postal_Street = modifiedTenant.postal_Street;
      this.tenant.postal_City = modifiedTenant.postal_City;
      this.tenant.postal_State = modifiedTenant.postal_State;
      this.tenant.postal_PostCode = modifiedTenant.postal_PostCode;
      this.tenant.postal_CountryCode = modifiedTenant.postal_CountryCode;

      this.savingState = 'loading';

      this.tenantService.updateSettings(this.tenant)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
    }

  }

  onSavingSuccess() {
    this.savingState = "finished";
    this.router.navigate(["home"])
    this.toaster.showSuccess('Successfully saved Settings.');
  }

  onSavingError(error) {
    this.savingState = "";
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["home"])
  }

  onModifyClick() {
    this.action = 'edit';
    this.isEditable = true;
  } 

  lastFieldKeydown(event) {
    if (event.key === 'Enter') {
      this.onSubmitClick();
    }
  }

  isGuidValid = function (guidToTest: Guid): boolean {
    if (guidToTest.toString() === '00000000-0000-0000-0000-000000000000')
      return true;

    // note this regex will return false for empty guid '00000000-0000-0000-0000-000000000000'
		const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
		return validRegex.test(guidToTest.toString());
  }

  get tenantIdControl() { return this.tenantForm.get("tenantId"); }
  get tenantNameControl() { return this.tenantForm.get("tenantName"); }
  get tenantGuidControl() { return this.tenantForm.get("tenantGuid"); }
  get erpIdentifierControl() { return this.tenantForm.get("erpIdentifier"); }
  get ediSchemaIdControl() { return this.tenantForm.get("ediSchemaId"); }
  get ediParticipantIdentifierControl() { return this.tenantForm.get("ediParticipantIdentifier"); }
  get ediClientIdControl() { return this.tenantForm.get("ediClientId"); }
  get contactNameControl() { return this.tenantForm.get("contactName"); }
  get contactPhoneControl() { return this.tenantForm.get("contactEmail"); }
  get contactEmailControl() { return this.tenantForm.get("contactEmail"); }
  get timeZoneCodeControl() { return this.tenantForm.get("timeZoneCode"); }
  get erpTypeIdControl() { return this.tenantForm.get("erpTypeId"); }

  get erpVersionControl() { return this.tenantForm.get("erpVersion"); }
  get erpBaseApiUrlControl() { return this.tenantForm.get("erpBaseApiUrl"); }
  get erpApiUsernameControl() { return this.tenantForm.get("erpApiUsername"); }
  get erpApiPasswordControl() { return this.tenantForm.get("erpApiPassword"); }

  get papApiUsernameControl() { return this.tenantForm.get("papApiUsername"); }
  get papApiPasswordControl() { return this.tenantForm.get("papApiPassword"); }

  get currencyCodeControl() { return this.tenantForm.get("currencyCode"); }
  get isAutoApprovePurchaseInvoicesControl() { return this.tenantForm.get("isAutoApprovePurchaseInvoices"); }
  // get isAllowDuplicatePurInvoiceNoForSuppControl() { return this.tenantForm.get("isAllowDuplicatePurInvoiceNoForSupp"); }

  get postal_StreetControl() { return this.tenantForm.get("postal_Street"); }
  get postal_CityControl() { return this.tenantForm.get("postal_City"); }
  get postal_StateControl() { return this.tenantForm.get("postal_State"); }
  get postal_PostCodeControl() { return this.tenantForm.get("postal_PostCode"); }
  get postal_CountryCodeControl() { return this.tenantForm.get("postal_CountryCode"); }

}
