import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PurchaseInvoiceSearchFilters } from '../../core/models/pur-invoice-search-filters';
import { ParametersHelpers } from '../../core/helpers/parameters-helpers';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated-result';
import { AuthenticationService } from '../services/authentication.service';
import { PurchaseInvoice, PurchaseInvoiceHistory } from '../models/pur-invoice';

import { BackgroundJobResult } from '../models/bg-job-result';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  search(filters?: PurchaseInvoiceSearchFilters): Observable<PaginatedResult<PurchaseInvoice>> {

    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;

    // add the search param values from the form to the requestParams
    let requestParams = ParametersHelpers.getHttpParamsByFilters(filters || {});

    // add the tenantId to the param list
    requestParams = requestParams.append("tenantId", userTenantId.toString());

    return this.httpClient.get<PaginatedResult<PurchaseInvoice>>(environment.api + "/PurchaseInvoice/search", {
      params: requestParams,
    });

  }

  public getPurchaseInvoiceHistoryList(purchaseInvoiceId: number) :Observable<PurchaseInvoiceHistory[]>{
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    requestParams = requestParams.append("purchaseInvoiceId", purchaseInvoiceId.toString());
    return this.httpClient.get<PurchaseInvoiceHistory[]>(`${environment.api}/purchaseInvoice/get-invoice-history`, {params: requestParams} );
  }

  public getById(purchaseInvoiceId: number) :Observable<PurchaseInvoice> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    requestParams = requestParams.append("purchaseInvoiceId", purchaseInvoiceId.toString());
    return this.httpClient.get<PurchaseInvoice>(`${environment.api}/purchaseInvoice`,  {params: requestParams} );
  }

  // public add(purchaseInvoice: any): Observable<PurchaseInvoice> {
  //   const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
  //   purchaseInvoice.tenantId = userTenantId;
  //   return this.httpClient.post<PurchaseInvoice>(`${environment.api}/purchaseInvoice/`, purchaseInvoice, httpOptions);
  // }

  public update(purchaseInvoice: any): Observable<any> {
    return this.httpClient.put(`${environment.api}/purchaseInvoice/`, purchaseInvoice, httpOptions);
  }

  public setStatus(purchaseInvoicePatch: any): Observable<any> {
    return this.httpClient.patch(`${environment.api}/purchaseInvoice/set-status`, purchaseInvoicePatch, httpOptions);
  }

  public approve(purchaseInvoicePatch: any): Observable<any> {
    return this.httpClient.patch(`${environment.api}/purchaseInvoice/approve`, purchaseInvoicePatch, httpOptions);
  }

  // public getDashboardPurchaseInvoices(rowLimit: number) :Observable<PurchaseInvoice[]> {
  //   const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
  //   let requestParams = new HttpParams();
  //   requestParams = requestParams.append("tenantId", userTenantId.toString());
  //   requestParams = requestParams.append("rowLimit", rowLimit.toString());
  //   requestParams = requestParams.append("purchaseInvoiceStatusId", PurchaseInvoiceStatusEnum.open.toString());
  //   return this.httpClient.get<PurchaseInvoice[]>(`${environment.api}/purchaseInvoice/get-dashboard-purchaseInvoices`,  {params: requestParams} );
  // }

  public getNewPurchaseInvoicesFromEdiExchange() :Observable<BackgroundJobResult> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<BackgroundJobResult>(`${environment.api}/purchaseInvoice/getNewPurchaseInvoicesFromEdiExchange/${userTenantId}`);
  }
  
}
