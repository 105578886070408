import { SharedModule } from './../shared/shared.module';
import { RouterModule } from '@angular/router';

import { NgxSpinnerModule } from 'ngx-spinner';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorisedAreaComponent } from './unauthorised-area/unauthorised-area.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgxSpinnerModule

  ],
  declarations: [
    LoginComponent, LogoutComponent, UnauthorisedAreaComponent
  ],
  exports: [
    LoginComponent, LogoutComponent, UnauthorisedAreaComponent
  ],
  entryComponents: [
  ],
})
export class AuthModule { }
