import { FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';

import { NgbDateMomentParserFormatter } from '../formatters/ngbDateFormat';
export class FormHelpers {

    static formatter() { return new NgbDateMomentParserFormatter("DD/MM/YYYY") }

    static validateFormControls(formControls: AbstractControl[]) {     
        formControls.forEach(control => {  
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
                control.markAsDirty({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                FormHelpers.validateFormGroup(control);          
            }
        });
    };

    static validateFormGroup(formGroup: FormGroup) {     
        formGroup.markAsDirty();     
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);             
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
                control.markAsDirty({ onlySelf: true });
            } else if (control instanceof FormArray) {
                FormHelpers.validateFormArray(control);
            } else if (control instanceof FormGroup) {        
                FormHelpers.validateFormGroup(control);          
            }
        });
    };

    static validateFormArray(formArray: FormArray) {        
        formArray.markAsDirty(); 
        for (let control of formArray.controls) {
            if (control instanceof FormGroup) {
               this.validateFormGroup(control);
            }
         }
    };

    static disableEnableFormGroup(isDisabled: Boolean, formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
          if (isDisabled) {
            formGroup.get(key).disable();
          } else {
            let formGroupControl = formGroup.get(key);
            formGroupControl.enable();
            formGroupControl.updateValueAndValidity();
          }
        });
      }
    

}