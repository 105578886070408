import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

//import { Observable } from 'rxjs';
//import 'rxjs/add/observable/of';

@Injectable({providedIn: 'root'})

export class ErrorService {


    getClientMessage(error: Error): string {
        if (!navigator.onLine) {
            return 'No Internet Connection';
        }
        return error.message ? error.message : error.toString();
    }

    getClientStack(error: Error): string {
        return error.stack;
    }

    getServerMessage(error: HttpErrorResponse): string {
        return error.message;
    }

    getServerStack(error: HttpErrorResponse): string {
        // handle stack trace
        return 'stack';
    }


    /**
     * Extracts the error message from an error response object.
     * 
     * @param response A HttpErrorResponse object that was generated by the VAS API.
     */
    getMessageFromErrorResponse(response: HttpErrorResponse) {

       //alert('StatusText: ' + response.statusText +'\r\n'+ ' Status: ' + response.status  +'\r\n'+ 'response.error: ' + response.error  +'\r\n'+ 'response.message: ' + response.message   +'\r\n'+ 'response.error.message: ' + response.error.message );
        //  alert('StatusText: ' + response.statusText );
        //  alert('Status: ' + response.status);
        //  alert('response.error: ' + response.error );
        //  alert('response.message: ' + response.message );
        //  alert('response.error.message: ' + response.error.message );

        if (response.status as number === 401) {
            return `Error: ${response.status} - UnAuthorized`;
        } else 
            if (response.status as number === 403) {
                return `Error: ${response.status} - Sorry Access is denied`;
        } else
        if (response.error && response.error.length < 100) {
            // some error responses can have very long html formatted error strings
            return `Error: ${response.status} - ${response.error}`;
        } else
        if (response.message) {
            return `Error: ${response.status} - ${response.message}`;

            // if (response.error.message || response.statusText) {
            //     return `Error: ${response.status} - ${response.error.message ? response.error.message : response.statusText}`;

        } else {
            return `Error: ${response.status} - There was an error, please contact your administrator.`;
        }
    }

}
