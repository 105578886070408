
  <div class="card card-blue-border" style="margin-top: 10px">
    <div class="card-header card-blue-header">
      <span *ngIf="action=='view'">Customer Details</span>
      <span *ngIf="action=='add'">Add New Customer</span>
      <span *ngIf="action=='edit'">Edit Customer</span>
    </div>
    <div class="card-body">

      <form [formGroup]="customerForm">

          <div class="row">
              <div class="col-md-12">
                  
                <ngb-tabset [destroyOnHide]="false">
            
                    <ngb-tab title="Customer Details">
                      <ng-template ngbTabContent>
  
                        <div class="row" style="margin-top:20px;">
  
                            <div class="col-md-8">

                                <div class="form-group row">
                                  <label for="customerId" class="col-sm-3 col-form-label">Id:</label>
                                  <div class="col-sm-8">
                                    <input type="text" class="form-control" formControlName="customerId" [readonly]="true" />
                                  </div>
                                </div>
                            
                                <div class="form-group row">
                                  <div class="col-sm-3"> <label class="col-form-label">Name:</label> </div>
                                  <div class="col-sm-8">
                                    <span class="form-control readonly-bg">{{customer.customerName}}</span>
                                  </div>
                                </div>

                                <div class="form-group row">
                                    <label for="ediSchemaId" class="col-sm-3 col-form-label">EDI Schema Identifier :</label>
                                    <div class="col-sm-3">
                                                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                        [ngClass]="{'is-invalid': ediSchemaIdControl.invalid && (ediSchemaIdControl.dirty || ediSchemaIdControl.touched)}"
                                        class="form-control prevent-uppercase"
                                        formControlName="ediSchemaId" />
                                    </div>
                                </div>
                      
                      
                                <div class="form-group row">
                                  <label for="ediParticipantIdentifier" class="col-sm-3 col-form-label">EDI Participant Identifier :</label>
                                  <div class="col-sm-8">
                          
                                      <input type="text" maxlength="50" [readonly]="!isEditable"
                                      [ngClass]="{'is-invalid': ediParticipantIdentifierControl.invalid && (ediParticipantIdentifierControl.dirty || ediParticipantIdentifierControl.touched)}"
                                      class="form-control prevent-uppercase"
                                      formControlName="ediParticipantIdentifier" />
                                  </div>
                                </div>
                  
                  

                                <div class="form-group row">
                                  <div class="col-sm-3"> <label class="col-form-label">Enable e-Invoices:</label> <span class="required">*</span> </div>
                                  <div class="col-sm-3">
                                      <div class="small-checkbox" *ngIf="isEditable">
                                          <input type="checkbox" [disabled]="!isEditable"
                                              [ngClass]="{'is-invalid': isEInvoicingEnabledControl.invalid && (isEInvoicingEnabledControl.dirty || isEInvoicingEnabledControl.touched)}"
                                              class="form-control"
                                              formControlName="isEInvoicingEnabled"/>
                                          <div *ngIf=" isEInvoicingEnabledControl.errors" class="invalid-feedback">
                                          <div [hidden]="! isEInvoicingEnabledControl.errors.required">isEInvoicingEnabled is required.</div>
                                          </div>
                                      </div>
                                      <span *ngIf="!isEditable && customer.isEInvoicingEnabled" class="form-control readonly-bg"> Yes </span>
                                      <span *ngIf="!isEditable && customer.isEInvoicingEnabled === false" class="form-control readonly-bg"> No </span>
                                  </div>
                                </div>

                               

                                <div class="form-group row">
                                  <label for="customerGuid" class="col-sm-3 col-form-label">Customer GUID:</label>
                                  <div class="col-sm-8">
                                    <span class="form-control readonly-bg">{{customer.customerGuid}}</span>
                                  </div>
                                </div>
                    
                                <div class="form-group row">
                                  <label for="contactName" class="col-sm-3 col-form-label">Contact Name:</label>
                                  <div class="col-sm-8">
                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                    [ngClass]="{'is-invalid': contactNameControl.invalid && (contactNameControl.dirty || contactNameControl.touched)}"
                                    class="form-control prevent-uppercase"
                                    formControlName="contactName" />
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <label for="contactPhone" class="col-sm-3 col-form-label">Contact Phone:</label>
                                  <div class="col-sm-8">
                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                    [ngClass]="{'is-invalid': contactPhoneControl.invalid && (contactPhoneControl.dirty || contactPhoneControl.touched)}"
                                    class="form-control prevent-uppercase"
                                    formControlName="contactPhone" />
                                  </div>
                                </div>


                                <div class="form-group row">
                                  <div class="col-sm-3"> <label class="col-form-label">Contact Email:</label> <span class="required">*</span> </div>
                                    <div class="col-sm-8">
                          
                                      <input type="text" maxlength="50" [readonly]="!isEditable" (keydown)="lastFieldKeydown($event)"
                                      [ngClass]="{'is-invalid': contactEmailControl.invalid && (contactEmailControl.dirty || contactEmailControl.touched)}"
                                      class="form-control prevent-uppercase"
                                      formControlName="contactEmail" />
                                      <div *ngIf=" contactEmailControl.errors" class="invalid-feedback">
                                        <div [hidden]="! contactEmailControl.errors.required">Contact email is required.</div>
                                      </div>
                                      
                                    </div>
                                </div>
                    
                              </div>
                    
                    
                              <div class="col-md-4">
                                  <div class="form-group row">
                                      <div class="col-sm-4"> <label class="col-form-label">Is Active:</label> </div>
                                      <div class="col-sm-6">
                                          <span *ngIf="customer.isActive" class="form-control readonly-bg"> Yes </span>
                                          <span *ngIf="customer.isActive === false" class="form-control readonly-bg"> No </span>
                                      </div>
                                    </div>
                        
                                    <div class="form-group row">
                                      <div class="col-sm-4"> <label class="col-form-label">Is Individual:</label> </div>
                                      <div class="col-sm-6">
                                          <span *ngIf="customer.isIndividual" class="form-control readonly-bg"> Yes </span>
                                          <span *ngIf="customer.isIndividual === false" class="form-control readonly-bg"> No </span>
                                      </div>
                                    </div>
                    
                                <div class="form-group row">
                                  <label class="col-sm-4 col-form-label">Created:</label>
                                  <div class="col-sm-6">
                                    <span class="form-control readonly-bg">{{customer.createdOn | stDateTime}}</span>
                                  </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Created By:</label>
                                    <div class="col-sm-6">
                                      <span class="form-control readonly-bg">{{customer.createdBy}}</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Last Modified:</label>
                                    <div class="col-sm-6">
                                      <span class="form-control readonly-bg">{{customer.modifiedOn | stDateTime}}</span>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Modified By:</label>
                                    <div class="col-sm-6">
                                      <span class="form-control readonly-bg">{{customer.modifiedBy}}</span>
                                    </div>
                                  </div>
                              </div>
                                    
                        </div>
                    
                        
                      </ng-template>
                    </ngb-tab>
                  
                  
                    <ngb-tab title="Addresses">
                      <ng-template ngbTabContent>
  
  
                        <div class="row" style="margin-top:20px;">
                            <div class="col-md-6">
  
                              <H4>Billing Address:</H4>
  
                              <div class="form-group row">
                                <label for="billing_Street" class="col-sm-3 col-form-label">Address Line:</label>
                                <div class="col-sm-8">
                                  <input type="text" maxlength="50" [readonly]="!isEditable"
                                  [ngClass]="{'is-invalid': billing_StreetControl.invalid && (billing_StreetControl.dirty || billing_StreetControl.touched)}"
                                  class="form-control prevent-uppercase"
                                  formControlName="billing_Street" />
                                </div>
                              </div>
  
                              <div class="form-group row">
                                <label for="billing_City" class="col-sm-3 col-form-label">City:</label>
                                <div class="col-sm-8">
                                  <input type="text" maxlength="50" [readonly]="!isEditable"
                                  [ngClass]="{'is-invalid': billing_CityControl.invalid && (billing_CityControl.dirty || billing_CityControl.touched)}"
                                  class="form-control prevent-uppercase"
                                  formControlName="billing_City" />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label for="billing_State" class="col-sm-3 col-form-label">State:</label>
                                <div class="col-sm-8">
                                  <input type="text" maxlength="50" [readonly]="!isEditable"
                                  [ngClass]="{'is-invalid': billing_StateControl.invalid && (billing_StateControl.dirty || billing_StateControl.touched)}"
                                  class="form-control prevent-uppercase"
                                  formControlName="billing_State" />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label for="billing_PostCode" class="col-sm-3 col-form-label">PostCode:</label>
                                <div class="col-sm-8">
                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                    [ngClass]="{'is-invalid': billing_PostCodeControl.invalid && (billing_PostCodeControl.dirty || billing_PostCodeControl.touched)}"
                                    class="form-control prevent-uppercase"
                                    formControlName="billing_PostCode" />
                                </div>
                              </div>

                              
                              <div class="form-group row">
                                <div class="col-sm-3"> <label class="col-form-label">Country Code:</label> <span class="required">*</span> </div>
                        
                                <div class="col-sm-8">

                                    <div *ngIf="!isEditable && tenant != undefined">
                                        <span class="form-control readonly-bg">{{customer.billing_CountryCode}}</span>
                                    </div>

                                    <div *ngIf="isEditable">
                                        <select class="form-control"
                                            [ngClass]="{'is-invalid': billing_CountryCodeControl.invalid && (billing_CountryCodeControl.dirty || billing_CountryCodeControl.touched)}"
                                            class="form-control prevent-uppercase"
                                            formControlName="billing_CountryCode">
                                            <option *ngFor="let country of countryPickList" [ngValue]="country.code">{{country.name}}</option>
                                                <option [ngValue]=null>-- Select a Country --</option>
                                            </select>
                                    </div>

                                    <div *ngIf=" billing_CountryCodeControl.errors" class="invalid-feedback">
                                        <div [hidden]="! billing_CountryCodeControl.errors.required">Country is required.</div>
                                    </div>

                                </div>
                              </div>

                            </div>
                  
                  
                            <div class="col-md-6">
                                
                                <H4>ShipTo Address:</H4>
  
                                <div class="form-group row">
                                  <label for="shipTo_Street" class="col-sm-3 col-form-label">Address :</label>
                                  <div class="col-sm-8">
                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                    [ngClass]="{'is-invalid': shipTo_StreetControl.invalid && (shipTo_StreetControl.dirty || shipTo_StreetControl.touched)}"
                                    class="form-control prevent-uppercase"
                                    formControlName="shipTo_Street" />
                                  </div>
                                </div>
    
                                <div class="form-group row">
                                  <label for="shipTo_City" class="col-sm-3 col-form-label">City :</label>
                                  <div class="col-sm-8">
                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                    [ngClass]="{'is-invalid': shipTo_CityControl.invalid && (shipTo_CityControl.dirty || shipTo_CityControl.touched)}"
                                    class="form-control prevent-uppercase"
                                    formControlName="shipTo_City" />
                                  </div>
                                </div>
  
                                <div class="form-group row">
                                  <label for="shipTo_State" class="col-sm-3 col-form-label">State :</label>
                                  <div class="col-sm-8">
                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                    [ngClass]="{'is-invalid': shipTo_StateControl.invalid && (shipTo_StateControl.dirty || shipTo_StateControl.touched)}"
                                    class="form-control prevent-uppercase"
                                    formControlName="shipTo_State" />
                                  </div>
                                </div>
  
                                <div class="form-group row">
                                  <label for="shipTo_PostCode" class="col-sm-3 col-form-label">PostCode :</label>
                                  <div class="col-sm-8">
                                      <input type="text" maxlength="50" [readonly]="!isEditable"
                                      [ngClass]="{'is-invalid': shipTo_PostCodeControl.invalid && (shipTo_PostCodeControl.dirty || shipTo_PostCodeControl.touched)}"
                                      class="form-control prevent-uppercase"
                                      formControlName="shipTo_PostCode" />
                                  </div>
                                </div>

                                <div class="form-group row">
                                  <div class="col-sm-3"> <label class="col-form-label">Country Code:</label> <span class="required">*</span> </div>
                          
                                  <div class="col-sm-8">

                                      <div *ngIf="!isEditable && tenant != undefined">
                                          <span class="form-control readonly-bg">{{customer.shipTo_CountryCode}}</span>
                                      </div>

                                      <div *ngIf="isEditable">
                                          <select class="form-control"
                                              [ngClass]="{'is-invalid': shipTo_CountryCodeControl.invalid && (shipTo_CountryCodeControl.dirty || shipTo_CountryCodeControl.touched)}"
                                              class="form-control prevent-uppercase"
                                              formControlName="shipTo_CountryCode">
                                              <option *ngFor="let country of countryPickList" [ngValue]="country.code">{{country.name}}</option>
                                                  <option [ngValue]=null>-- Select a Country --</option>
                                              </select>
                                      </div>

                                      <div *ngIf=" shipTo_CountryCodeControl.errors" class="invalid-feedback">
                                          <div [hidden]="! shipTo_CountryCodeControl.errors.required">Country is required.</div>
                                      </div>

                                  </div>
                              </div>

                            </div>
                  
                        </div>
                        
                      </ng-template>
                    </ngb-tab>
                  
                  </ngb-tabset>
            
              </div>
          </div>
       
      </form>

  </div>
</div>


<div class="form-group" style="margin-top:5px;">
  <button type="button" class="btn btn-outline-dark group-button" (click)="onCancel()">
    <span *ngIf="action=='view'">Close</span>
    <span *ngIf="action=='add'">Cancel</span>
    <span *ngIf="action=='edit'">Cancel</span>
  </button>
  <span *ngIf="action=='add' || action=='edit'"> 
    <button type="button" class="btn btn-outline-primary group-button" [disabled]="savingState === 'loading'" (click)="onSubmitClick()">
      <span *ngIf="savingState !== 'loading'">Save & Close</span>
      <span *ngIf="savingState === 'loading'">Saving...</span>
    </button>
  </span>

  <span *ngIf="action=='view'"> 
    <button type="button" class="btn btn-primary group-button" (click)="onModifyClick()"> Modify </button>
  </span>

</div>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>






