  
<div class="card card-blue-border">
  <div class="card-header card-big-blue-text-header">
    Purchase Invoice
  </div>


  <div class="form-group row" style="margin-top:5px;">
    <div class="col-md-8">
        <button type="button" class="btn btn-outline-dark group-button" (click)="onCancel()"> Exit</button>

        <span *ngIf="purchaseInvoice.purchaseInvoiceStatusId !== statusEnum.uploadedToERP">
          <button type="button" class="btn btn-primary group-button" (click)="onPurchaseInvoiceModifyClick()"> Modify Invoice</button>
        </span>

        <span *ngIf="!purchaseInvoice.isApproved && purchaseInvoice.purchaseInvoiceStatusId === statusEnum.pending">
          <button type="button" class="btn btn-primary group-button" (click)="onApproveInvoiceConfirm()"> Approve Invoice</button>
        </span>

        <span *ngIf="purchaseInvoice.purchaseInvoiceStatusId !== statusEnum.rejected && purchaseInvoice.purchaseInvoiceStatusId !== statusEnum.cancelled  && purchaseInvoice.purchaseInvoiceStatusId !== statusEnum.uploadedToERP">
          <button type="button" class="btn btn-primary group-button" (click)="onRejectInvoiceConfirm()"> Reject Invoice</button>
          <button type="button" class="btn btn-primary group-button" (click)="onCancelInvoiceConfirm()"> Cancel Invoice</button>
        </span>

        <span *ngIf="purchaseInvoice.purchaseInvoiceStatusId === statusEnum.rejected || purchaseInvoice.purchaseInvoiceStatusId === statusEnum.cancelled">
          <button type="button" class="btn btn-primary group-button" (click)="onReinstateInvoiceConfirm()"> Reinstate Invoice</button>
        </span>

    </div>
    <div class="col-md-4">
    </div>
  </div>



  <div class="card-body">

    <div class="row">
      <div class="col-md-6">

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Id:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.purchaseInvoiceId}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Invoice No:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:300px;">{{purchaseInvoice.supplierInvoiceNumber}}</span>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Invoice Status:</label>
          <div class="col-sm-8">
            <span *ngIf="purchaseInvoice.purchaseInvoiceStatusId < 200" class="form-control readonly-bg" style="width:300px;">{{purchaseInvoice.purchaseInvoiceStatusName}}</span>
            <span *ngIf="purchaseInvoice.purchaseInvoiceStatusId === 200" class="form-control readonly-bg" style="width:300px;color:green;">{{purchaseInvoice.purchaseInvoiceStatusName}}</span>
            <span *ngIf="purchaseInvoice.purchaseInvoiceStatusId >= 500" class="form-control readonly-bg" style="width:300px;color:red;font-weight:bold;">{{purchaseInvoice.purchaseInvoiceStatusName}}</span>
          </div>
        </div>
 
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Validation:</label>
          <div class="col-sm-8">
            <span *ngIf="isPurchaseInvoiceValid()" class="form-control readonly-bg" style="width:200px;"> Passed</span>
        </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Supplier:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:300px;">{{purchaseInvoice.supplierName}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Bill Type:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:300px;">{{purchaseInvoice.invoiceLayoutType}}</span>
          </div>
        </div>

      </div>


      <div class="col-md-6">

        <div class="form-group row">
            <label *ngIf="isPurchaseInvoiceApproved()" class="col-sm-3 col-form-label">Approved by:</label>
            <label *ngIf="!isPurchaseInvoiceApproved()" class="col-sm-3 col-form-label">Approved:</label>
            <div class="col-sm-6">
              <span *ngIf="isPurchaseInvoiceApproved()" class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.approvedBy}}</span>
              <span *ngIf="!isPurchaseInvoiceApproved()" class="form-control readonly-bg" style="width:200px;"> No</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Received Date:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.receivedDate | stDate}}</span>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Issue Date:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.issueDate | stDate}}</span>
          </div>
        </div>
  
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Due Date:</label>
          <div class="col-sm-8">
              <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.dueDate | stDate}}</span>
          </div>
        </div>
    
        <div class="form-group row">
          <label class="col-sm-3 col-form-label"> Purch Order No:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.purchaseOrderNo}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Amount Payable:</label>
          <div class="col-sm-8">
            <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.invoiceCurrencyCode}} {{purchaseInvoice.payableAmount | currency}}</span>
          </div>
        </div>

    </div>

  </div>
</div>


<ngb-tabset [destroyOnHide]="false">

  <ngb-tab title="Invoice Lines">
    <ng-template ngbTabContent>
      
      <div class="card card-blue-border" style="margin-top: 10px">
        <div class="card-header card-blue-header">
          Invoice Lines
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col-md-12">
      
                <div class="table-responsive"  *ngIf="purchaseInvoice && purchaseInvoice.invoiceLines && purchaseInvoice.invoiceLines.length">

                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th>Item Id:</th>
                        <th>Description:</th>
                        <th>Account:</th>
                        <th>Quantity:</th>
                        <th>Unit Price:</th>
                        <th>Tax:</th>
                        <th>Line Total:</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let line of purchaseInvoice.invoiceLines; let i = index">
                        <td>{{line.itemIdentifier}}</td>
                        <td>{{line.itemDescription}}</td>
                        <td>{{line.accountingCostCode}}</td>
                        <td>{{line.invoicedQuantity}}</td>
                        <td>{{line.unitPrice | currency}}</td>
                        <td>{{line.taxCode}}</td>
                        <td>{{line.lineExtensionAmount | currency}}</td>

                      </tr>
      
                    </tbody>
                  </table>

                </div>

            </div>
          </div>

        </div>
      </div>
      
    </ng-template>
  </ngb-tab>


  <ngb-tab title="Workflow History">
    <ng-template ngbTabContent>

      <div class="card card-blue-bpurchaseInvoice" style="margin-top: 10px" [hidden]="!isShowPurchaseInvoiceHistory">
        <div class="card-header card-blue-header">
           Workflow History
        </div>
        <div class="card-body">
    
          <div class="row">
            <div class="col-md-12">
      
                <div class="table-responsive"  *ngIf="purchaseInvoiceHistoryList && purchaseInvoiceHistoryList.length">
    
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                          <th>When:</th>
                          <th>By Who:</th>
                          <th>Action:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of purchaseInvoiceHistoryList; let i = index">
                            <td>{{item.actionedOn | stDateSlash}}</td>
                        <td>{{item.actionedBy}}</td>
                        <td>{{item.actionDescription}}</td>
                      </tr>
      
                    </tbody>
                  </table>
    
                </div>
    
            </div>
          </div>
    
        </div>
      </div>
      
    </ng-template>
  </ngb-tab>

  
  <ngb-tab>

      <ng-template ngbTabTitle>
          <!-- <div (click)="getValidationErrorList()">Validation Errors</div> -->
          <div>Validation Errors</div>
      </ng-template>
  
    <ng-template ngbTabContent>

        <div style="margin:10px;" *ngFor="let item of purchaseInvoice.validationErrors; let i = index">
          <span class="error-text">{{item}}</span>
        </div>

    </ng-template>
  </ngb-tab>


  <ngb-tab>

      <ng-template ngbTabTitle>
          <div>Other Invoice Details</div>
      </ng-template>
  
    <ng-template ngbTabContent>


      <div class="row" style="margin:10px;">
        <div class="col-md-8">

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">EDI Message ID:</label>
            <div class="col-sm-8">
              <span class="form-control readonly-bg">{{purchaseInvoice.messageUuid}}</span>
            </div>
          </div>

        </div>
      </div>


      <div class="row" style="margin:10px;">
        <div class="col-md-6">

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Currency:</label>
            <div class="col-sm-8">
              <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.invoiceCurrencyCode}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Notes:</label>
            <div class="col-sm-8">
              <span class="form-control readonly-bg">{{purchaseInvoice.note}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Ship to Address:</label>
            <div class="col-sm-8">
              <span class="form-control readonly-bg">{{purchaseInvoice.shipToAddress}}</span>
            </div>
          </div>

        </div> 


        <div class="col-md-6">

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">LineExt Amount:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.lineExtensionAmount | currency}}</span>
              </div>
            </div>
      
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Ex TaxAmount:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.taxExclusiveAmount | currency}}</span>
              </div>
            </div>
      
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Inc Tax Amount:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.taxInclusiveAmount | currency}}</span>
              </div>
            </div>
      
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Charges Total:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.chargeTotalAmount | currency}}</span>
              </div>
            </div>
      
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Amount Payable:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.payableAmount | currency}}</span>
              </div>
            </div> 
  
          </div> 
  

      </div> 


    </ng-template>
  </ngb-tab>


</ngb-tabset>


<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>
