import { Component, OnInit } from '@angular/core';
import { ScheduledJobService } from '../../core/services/sched-job.service';
import { BackgroundJobResult } from '../../core/models/bg-job-result';
import { ToasterService } from '../../core/services/toaster.service';

@Component({
  selector: 'app-sched-job',
  templateUrl: './sched-job.component.html',
  styleUrls: ['./sched-job.component.scss']
})
export class ScheduledJobComponent implements OnInit {

  backgroundJobResult: BackgroundJobResult = new BackgroundJobResult();
  jobState: string;

  constructor(
      private scheduledJobService: ScheduledJobService,
      private toaster: ToasterService
    ) { }

  ngOnInit() {
  }

  ongetNewPurchaseInvoicesFromEdiExchangeClick() {
    // this.jobState = 'busy';
    // this.scheduledJobService.getNewPurchaseInvoicesFromEdiExchange()
    // .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  onSyncSuccess(result: BackgroundJobResult) {
    this.backgroundJobResult = result;
    this.backgroundJobResult.errorCount = this.backgroundJobResult.errorList.length;
    this.jobState = "finished";
    this.toaster.showSuccess('Completed ' + this.backgroundJobResult.jobName);
  }

  onSyncError(error) {
    this.jobState = "";
    throw error;    // will go to the global error handler
  }



}
