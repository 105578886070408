import { Component, OnInit } from '@angular/core';
import { PurchaseInvoice, PurchaseInvoiceHistory, PurchaseInvoicePatch } from '../../../core/models/pur-invoice';
import { InvoiceLine } from '../../../core/models/pur-invoice';
import { PurchaseInvoiceService } from '../../../core/services/pur-invoice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/authentication.service';
import { ToasterService } from '../../../core/services/toaster.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalOptions, SimpleModalConfirmComponent } from "../../../shared/simple-modal-confirm/simple-modal-confirm.component";
import { PurchaseInvoiceStatusEnum } from '../../../core/models/enum';

import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-pur-invoice-detail',
  templateUrl: './pur-invoice-detail.component.html',
  styleUrls: ['./pur-invoice-detail.component.scss']
})
export class PurchaseInvoiceDetailComponent implements OnInit {

  purchaseInvoiceId : number = 1;
  purchaseInvoice : PurchaseInvoice = new PurchaseInvoice();

  purchaseInvoiceHistoryList: PurchaseInvoiceHistory[] = new Array<PurchaseInvoiceHistory>();
  isShowPurchaseInvoiceHistory: boolean = true;

  statusEnum = PurchaseInvoiceStatusEnum;

  isUserPurchaseInvoiceApprover: boolean = false;

  validationErrorList: string[] = new Array<string>();

  constructor(
    private router: Router,
    private route:ActivatedRoute,
    private spinner: NgxSpinnerService,
    private purchaseInvoiceService: PurchaseInvoiceService,
    private toaster: ToasterService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => this.purchaseInvoiceId = params['purchaseInvoiceId']);
    this.loadData();

    this.purchaseInvoiceService.getPurchaseInvoiceHistoryList(this.purchaseInvoiceId).subscribe(res => {
      this.purchaseInvoiceHistoryList = res;
    })

    this.isUserPurchaseInvoiceApprover = this.authenticationService.isUserPurchaseInvoiceApprover();

  }

  loadData() {
    this.spinner.show();
    this.purchaseInvoiceService.getById(this.purchaseInvoiceId)
      .subscribe(this.onDataLoaded.bind(this), this.onDataLoadError.bind(this) )
  }

  onDataLoaded(result: PurchaseInvoice){
     this.purchaseInvoice = result;
      this.spinner.hide();
  }
  
  onDataLoadError(error)
  {
    this.spinner.hide();
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["pur-invoice-search"])
  }

  onPurchaseInvoiceModifyClick() {
    this.router.navigate(["pur-invoice-maint", false, this.purchaseInvoiceId])
  } 


  onApproveInvoiceConfirm() {
    const data = <SimpleModalOptions>{ title: "Are you sure you want to APPROVE this Purchase Invoice?" };
    const modalRef = this.modalService.open(SimpleModalConfirmComponent);
    const modalInstance: SimpleModalConfirmComponent = modalRef.componentInstance;
    modalInstance.data = data;

    let purchaseInvoicePatch = new PurchaseInvoicePatch();
    purchaseInvoicePatch.purchaseInvoiceId = this.purchaseInvoice.purchaseInvoiceId;

    modalInstance.confirmed.subscribe(res => {
      if (res) {
        this.purchaseInvoiceService.approve(purchaseInvoicePatch).subscribe((response) => {
          this.closeThisView();
        });
      }
    });
  }


  onCancelInvoiceConfirm() {
    let titleText: string = "Are you sure you want to CANCEL this Purchase Invoice?";
    let newStatusId: number = PurchaseInvoiceStatusEnum.cancelled;
    this.changeInvoiceStatus(newStatusId, titleText );
  }

  onRejectInvoiceConfirm() {
    let titleText: string = "Are you sure you want to REJECT this Purchase Invoice?";
    let newStatusId: number = PurchaseInvoiceStatusEnum.rejected;
    this.changeInvoiceStatus(newStatusId, titleText );
  }

  onReinstateInvoiceConfirm() {
    let titleText: string = "Are you sure you want to REINSTATE this Purchase Invoice to Pending status?";
    let newStatusId: number = PurchaseInvoiceStatusEnum.pending;
    this.changeInvoiceStatus(newStatusId, titleText );
  }

  private changeInvoiceStatus(newStatusId: number, titleText: string ) {
    const data = <SimpleModalOptions>{ title: titleText };
    const modalRef = this.modalService.open(SimpleModalConfirmComponent);
    const modalInstance: SimpleModalConfirmComponent = modalRef.componentInstance;
    modalInstance.data = data;

    let purchaseInvoicePatch = new PurchaseInvoicePatch();
    purchaseInvoicePatch.purchaseInvoiceId = this.purchaseInvoice.purchaseInvoiceId;
    purchaseInvoicePatch.purchaseInvoiceStatusId = newStatusId;

    modalInstance.confirmed.subscribe(res => {
      if (res) {
        this.purchaseInvoiceService.setStatus(purchaseInvoicePatch).subscribe((response) => {
          this.closeThisView();
          this.purchaseInvoice.purchaseInvoiceStatusId = newStatusId;
        });
      }
    });
  }

  private closeThisView() {
    this.router.navigate(["pur-invoice-search"])
  }

  onShowPurchaseInvoiceHistoryClick() {
    window.scrollTo(0,document.body.scrollHeight);
  } 

  isPurchaseInvoiceApproved() {
    return this.purchaseInvoice.isApproved;
  }

  isPurchaseInvoiceValid() {
    return this.purchaseInvoice.isValid;
  }


  // private getValidationErrorList() {
  //   alert('getting validation errors');
  //   this.validationErrorList  = new Array<string>();
  //   return;
  // }

}
