
export class PurchaseInvoiceSearchFilters  {
    tenantId: number;
    supplierId?: number;
    purchaseInvoiceStatusId?: number;
    receivedDateFromAsISOString: string;
    receivedDateToAsISOString: string;

    page?: number;
    pageSize?: number;
    sortKey?: string;
    sortDir?: string;

    constructor() {
        this.pageSize = 20;
    }

}