
<div style="margin-left:5px;">
    <p class="page-heading1">Collect New Purchase Invoices from Access Point</p>

    <div class="row" style="margin:5px">

        <button type="button" class="btn btn-primary" [disabled]="jobState === 'busy'" (click)="ongetNewPurchaseInvoicesFromEdiExchangeClick()">
            <span>Collect new Purchase Invoices from Access Point</span>
        </button>

    </div>

    <br/><br/><br/>

    <div [hidden]="jobState !== 'busy'">
            <mat-spinner strokeWidth="24" [diameter]="85" color="warn" ></mat-spinner>
                    <span>Processing - please wait</span>
    </div>


    <div style="margin-top: 10px" [hidden]="jobState != 'finished'">
        <div class="row" style="margin-top:5px">

            <div class="col-md-12">
                <span style="font-weight:bold;color:darkblue;"> Sync Results for {{backgroundJobResult.jobName}} </span>
            </div>

            <div class="col-md-2" *ngIf="backgroundJobResult.isSyncJob === false">
                Created: {{backgroundJobResult.itemsProcessedCount}}
            </div>

            <div class="col-md-2">
                Errors: {{backgroundJobResult.errorCount}}
            </div>

        </div>


        <div class="row" style="margin-top:5px">

        <div style="margin-left:15px;">
            <div class="table-responsive" *ngIf="backgroundJobResult.errorList && backgroundJobResult.errorList.length">
            <span style="color:red;font-size:16px;font-weight:bold;"> Errors: </span>
            <table class="table table-sm">
                <tbody>
                <tr *ngFor="let error of backgroundJobResult.errorList; let i = index">
                    <td>{{error}}</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>

    </div>

    </div>

</div>

<p>&nbsp;</p>
    
