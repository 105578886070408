
<div class="card card-blue-border">
  <div class="card-header card-big-blue-text-header">
      Sales Invoices:
  </div>
  <div class="card-body">

    <form [formGroup]="formGroup">

      <div class="form-row">

        <div class="col-md-3">
          <label>Customer:</label>
          <select class="form-control" formControlName="customerddl">
            <option *ngFor="let customer of customerPickList" [ngValue]="customer.id">{{customer.name}}</option>
            <option [ngValue]=null> All Customers </option>
          </select>
        </div>

        <div class="col-md-2">
            <label> Invoice Date From:</label>
            <app-date-picker formControlName="invoiceDateFrom" [minDate]="minDate"></app-date-picker>
        </div>

        <div class="col-md-2">
            <label> Invoice Date To:</label>
            <app-date-picker formControlName="invoiceDateTo" [minDate]="minDate"></app-date-picker>
          </div>

          <div class="col-md-3">
          </div>
  
          <div class="col-md-2">
              <button type="button" class="btn btn-primary group-button" (click)="onSearchSubmit()"> Search</button>
              <button type="button" class="btn btn-outline-info cancel group-button" [disabled]="searchState === 'loading'" (click)="onSearchReset()">Reset</button>
            </div>
    
      </div>

    </form>

  </div>
</div>


<div  style="margin-left:20px; margin-top:20px;" *ngIf="searchResults.length === 0">
  <span>No Sales Invoices found</span>
</div>

<div class="row">
  <div class="col-md-12">

    <div *ngIf="searchState == 'finished'">
      <div class="table-responsive" *ngIf="searchResults && searchResults.length">
    
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Id:</th>
              <th>Customer:</th>
              <th>Status:</th>
              <th>Invoice No:</th>
              <th>Invoice Total:</th>
              <th>Invoice Date:</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of searchResults">
              <td>{{r.salesInvoiceId}}</td>
              <td>{{r.customerName}}</td>

              <td>
                <span *ngIf="r.salesInvoiceStatusId < 200" > {{r.salesInvoiceStatusName}}</span>
                <span *ngIf="r.salesInvoiceStatusId === 200" style="color:green;" > {{r.salesInvoiceStatusName}}</span>
                <span *ngIf="r.salesInvoiceStatusId >= 500" style="width:200px;color:red;font-weight:bold;"> {{r.salesInvoiceStatusName}}</span>
              </td>

              <td>{{r.invoiceNumber}}</td>
              <td>{{r.payableAmount | currency}}</td>
              <td>{{r.invoiceDate | stDate}}</td>
              <td class="d-flex">
                <button (click)="onSalesInvoiceDetailClick(r.salesInvoiceId)" class="btn btn-sm btn-outline-primary mr-2">Details</button>
              </td>
            </tr>
          </tbody>
        </table>

        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pagination.totalCount" [pageSize]="pagination.pageSize"
        [(page)]="selectedPage" (pageChange)="onPageClicked($event)" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>

      </div>
    </div>

  </div>
</div>

<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>

