export class AuthUser {
    authUserId: number;
    isActive: boolean;
    isDeleted: boolean;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    tenantId: number;

    lastName: string;
    firstName: string;
    contactEmail: string;
    timeZoneCode: string;

    loginName: string;
    password: string;
    confirmPassword: string;

    childrenToBeDeleted: number[];
    authUserRoleList: AuthUserRole[];
}

export class AuthUserRole {
    authUserRoleId: number;
    authUserId: number;
    authRoleId: number;
    authRoleName: string;
}


export class AuthUserSetPw {
    authUserId: number;
    loginName: string;
    password: string;
    confirmPassword: string;
}

export class CurrentUser {
    authUserId: number;
    userTenantId: number;
    userTenantName: string;
    userErpTypeShortName: string;
    lastName: string;
    firstName: string;
    loginName: string;
    roleList: number[];
}


