
<div style="margin-left:5px;">
  <p class="page-heading1">Dashboard</p>

  <div class="row" style="margin:5px">

    <div class="col-md-3">
      <div class="card card-blue-border" style="margin-top:10px;">
        <div class="card-header card-blue-header">
            <span>Invoice Collection</span>
        </div>
        <div class="card-body">
  
            <span class="badge badge-danger pull-right"> {{dashboardData.collectionFailedCount}} </span>
            <p>Collection Failed.</p>
 
            <span class="badge badge-success pull-right"> {{dashboardData.collectedTodayCount}} </span>
            <p>Collected Today.</p>
     
        </div>
      </div>
    </div>

    <i class="material-icons">forward</i> 


    <div class="col-md-3">
      <div class="card card-blue-border" style="margin-top:10px;">
        <div class="card-header card-blue-header">
            <span>In Progress</span>
        </div>
        <div class="card-body">

          <span>
            <span class="badge badge-danger pull-right"> {{dashboardData.failedValidationCount}} </span>
            <p style="cursor:pointer" (click) = "onFailedValidationClick()"> Failed Validation.</p>
          </span>

            <span class="badge badge-info pull-right"> {{dashboardData.readyForApprovalCount}} </span>
            <p>Waiting for Approval.</p>

        </div>
      </div>
    </div>

    <i class="material-icons">forward</i> 

    
    <div class="col-md-3">
      <div class="card card-blue-border" style="margin-top:10px;">
        <div class="card-header card-blue-header">
            <span>Upload to {{erpName}}</span>
        </div>
        <div class="card-body">

          <span class="badge badge-danger pull-right"> {{dashboardData.failedToUploadCount}} </span>
          <p>Failed to Upload.</p>

          <span class="badge badge-info pull-right"> {{dashboardData.readyForUploadCount}} </span>
          <p>Ready for Upload.</p>

          <span class="badge badge-success pull-right"> {{dashboardData.uploadedTodayCount}}</span>
          <p>Uploaded Today.</p>

        </div>
      </div>
    </div>


    <div class="col-md-3">
    </div>

    
  </div>

</div>

<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>

 

  