import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PurchaseInvoiceDashboardData } from '../models/pur-invoice';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  public getPurchaseInvoiceDashboardData() :Observable<PurchaseInvoiceDashboardData> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
   return this.httpClient.get<PurchaseInvoiceDashboardData>(`${environment.api}/Dashboard/getPurchaseInvoiceDashboardData/${userTenantId}`);
  }
 
}
