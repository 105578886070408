import { Component, OnInit } from '@angular/core';
import { InventoryItemService } from '../../../core/services/inventory-item.service';
import { InventoryItem } from '../../../core/models/inventory-item';
import { Pagination } from '../../../core/models/pagination';
import { PaginatedResult } from '../../../core/models/paginated-result';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalOptions, SimpleModalConfirmComponent } from "../../../shared/simple-modal-confirm/simple-modal-confirm.component";
import { Params, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrls: ['./inventory-item.component.scss']
})
export class InventoryItemComponent implements OnInit {

  inventoryItemForm: FormGroup;
  
  selectedPage: number = 1;
  pagination: Pagination;
 
  searchState: string = 'loading';
  searchFilters = new InventoryItem();
  searchResults: InventoryItem[] = new Array<InventoryItem>();

  isDataDirty: boolean = false;
 
  constructor(
    private inventoryItemService: InventoryItemService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private router: Router
  )
  {
    this.createForm();
  }
  

  ngOnInit() {
    this.fireSearchRequests();
    this.searchState = 'finished'; 
  }

  resetSearch() {
    this.inventoryItemForm.reset();
    this.fireSearchRequests();
  }

  fireSearchRequests(page=1) {

    this.spinner.show();

      this.populateSearchFilter();
      this.searchState = 'loading';
  
      this.inventoryItemService.search(this.searchFilters, page)
        .subscribe(this.onSearchLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(['/home']);
          throw error;    // will go to the global error handler
        }
      );
  }


  onSearchLoaded(result: PaginatedResult<InventoryItem>){
    this.pagination = Pagination.fromJson(result);
    this.searchResults = result.results;
    this.searchState = 'finished';

    this.spinner.hide();
  
    // the json coming back from the server is adding one to the page (I think angular assumes it's zero based)
    if (this.pagination.page > 1)
      this.pagination.page = this.pagination.page - 1;
  }

  populateSearchFilter()
  {
    this.searchFilters.inventoryItemId = this.inventoryItemIdKey;
    this.searchFilters.inventoryItemName = this.inventoryItemNameKey;
  }

  onSearchSubmit() {
      if (this.inventoryItemForm.valid) {
          this.fireSearchRequests();
      }
  }

  onSearchReset()
  {
      this.selectedPage = 1;
      this.inventoryItemForm.reset();
      this.fireSearchRequests();
  }

    
  createForm() {
    this.inventoryItemForm = this.fb.group({
      inventoryItemIdKey: [''],
      inventoryItemNameKey: ['']
    });
    this.inventoryItemForm.get('inventoryItemIdKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
    this.inventoryItemForm.get('inventoryItemNameKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
  }


  //This function is called automatically by the ngBootstrap pagination component on page load passing page=1.
  onPageClicked(page: number) {
    if (page && page !== this.pagination.page) {
      this.fireSearchRequests(page);
    }
  }


  onInventoryItemDetailClick(inventoryItemId: number) {
    this.router.navigate(["inventory-item-maint", 'view', inventoryItemId]);
  } 

  onInventoryItemCreateClick() {
    this.router.navigate(["inventory-item-maint", 'add', 0]);
  } 

  onInventoryItemModifyClick(inventoryItemId: number) {
    this.router.navigate(["inventory-item-maint", 'edit', inventoryItemId]);
  } 

  deleteConfirm(inventoryItemId: number) {

    const data = <SimpleModalOptions>{
      title: "Are you sure you want to delete this InventoryItem?"
    };

    const modalRef = this.modalService.open(SimpleModalConfirmComponent);
    const modalInstance: SimpleModalConfirmComponent =
      modalRef.componentInstance;
    modalInstance.data = data;

    modalInstance.confirmed.subscribe(res => {
      if (res) {
        this.inventoryItemService.delete(inventoryItemId).subscribe((response) => {
          this.onSearchSubmit();
        });
       
      }
    });

  }


  handleModalClosed(isSuccess: boolean) {
   if (isSuccess) {
     this.onSearchSubmit();
   }
  }


  get inventoryItemIdKey() { return this.inventoryItemForm.get('inventoryItemIdKey').value; }
  get inventoryItemNameKey() { return this.inventoryItemForm.get('inventoryItemNameKey').value; }

}
