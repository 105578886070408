import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../../core/services/customer.service';
import { Customer } from '../../../core/models/customer';
import { Pagination } from '../../../core/models/pagination';
import { PaginatedResult } from '../../../core/models/paginated-result';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalOptions, SimpleModalConfirmComponent } from "../../../shared/simple-modal-confirm/simple-modal-confirm.component";
import { Params, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  customerForm: FormGroup;
  
  selectedPage: number = 1;
  pagination: Pagination;
 
  searchState: string = 'loading';
  searchFilters = new Customer();
  searchResults: Customer[] = new Array<Customer>();

  isDataDirty: boolean = false;
 
  constructor(
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private router: Router
  )
  {
    this.createForm();
  }
  

  ngOnInit() {
    this.fireSearchRequests();
    this.searchState = 'finished'; 
  }

  resetSearch() {
    this.customerForm.reset();
    this.fireSearchRequests();
  }

  fireSearchRequests(page=1) {

    this.spinner.show();

      this.populateSearchFilter();
      this.searchState = 'loading';
  
      this.customerService.search(this.searchFilters, page)
        .subscribe(this.onSearchLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(['/home']);
          throw error;    // will go to the global error handler
        }
      );
  }


  onSearchLoaded(result: PaginatedResult<Customer>){
    this.pagination = Pagination.fromJson(result);
    this.searchResults = result.results;
    this.searchState = 'finished';

    this.spinner.hide();
  
    // the json coming back from the server is adding one to the page (I think angular assumes it's zero based)
    if (this.pagination.page > 1)
      this.pagination.page = this.pagination.page - 1;
  }

  populateSearchFilter()
  {
    this.searchFilters.customerId = this.customerIdKey;
    this.searchFilters.customerName = this.customerNameKey;
  }

  onSearchSubmit() {
      if (this.customerForm.valid) {
          this.fireSearchRequests();
      }
  }

  onSearchReset()
  {
      this.selectedPage = 1;
      this.customerForm.reset();
      this.fireSearchRequests();
  }

    
  createForm() {
    this.customerForm = this.fb.group({
      customerIdKey: [''],
      customerNameKey: ['']
    });
    this.customerForm.get('customerIdKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
    this.customerForm.get('customerNameKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
  }


  //This function is called automatically by the ngBootstrap pagination component on page load passing page=1.
  onPageClicked(page: number) {
    if (page && page !== this.pagination.page) {
      this.fireSearchRequests(page);
    }
  }


  onCustomerDetailClick(customerId: number) {
    this.router.navigate(["customer-maint", 'view', customerId]);
  } 

  onCustomerCreateClick() {
    this.router.navigate(["customer-maint", 'add', 0]);
  } 

  onCustomerModifyClick(customerId: number) {
    this.router.navigate(["customer-maint", 'edit', customerId]);
  } 

  deleteConfirm(customerId: number) {

    const data = <SimpleModalOptions>{
      // title: "Are you sure you want to delete this Customer: " + customerId + "?"
      title: "Are you sure you want to delete this Customer?"
    };

    const modalRef = this.modalService.open(SimpleModalConfirmComponent);
    const modalInstance: SimpleModalConfirmComponent =
      modalRef.componentInstance;
    modalInstance.data = data;

    modalInstance.confirmed.subscribe(res => {
      if (res) {
        this.customerService.delete(customerId).subscribe((response) => {
          this.onSearchSubmit();
        });
       
      }
    });

  }


  handleModalClosed(isSuccess: boolean) {
   if (isSuccess) {
    //alert('modal has closed. isSuccess is true');
     this.onSearchSubmit();
   }
  }


  get customerIdKey() { return this.customerForm.get('customerIdKey').value; }
  get customerNameKey() { return this.customerForm.get('customerNameKey').value; }

}
