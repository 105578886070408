import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { StDatePipe } from './pipes/st-date.pipe';
import { StDateSlashPipe } from './pipes/st-date-slash-.pipe';
import { StDateTimePipe } from './pipes/st-date-time.pipe';
import { BooleanYesNoPipe } from './pipes/boolean-yes-no.pipe';

import { ErrorComponent } from './../core/errors/error-component/error.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxCurrencyModule } from "ngx-currency";

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      countDuplicates: true
    }),
    NgxCurrencyModule

  ],
  declarations: [
    StDatePipe, StDateSlashPipe, StDateTimePipe,
    BooleanYesNoPipe,
    ErrorComponent

  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    StDatePipe, StDateSlashPipe, StDateTimePipe,
    BooleanYesNoPipe,
    NgxCurrencyModule
  ],
  entryComponents: [
    //SimpleModalConfirmComponent
  ]
})
export class SharedModule { }
