import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from '..//..//core/services/authentication.service';
import { Router } from '@angular/router';
import { CurrentUser } from 'src/app/core/models/auth-user';

import { Subscription } from 'rxjs/Rx';


import {MatDividerModule} from '@angular/material/divider';


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {

  private subscription = new Subscription();

  currentUser: CurrentUser;

  userDisplayName = '';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    // subscribe to the currentUser in the AuthenticationService so that it is updated here when it changes (another user logs in)
    this.subscription = this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isLoggedIn() {
    return this.authenticationService.isLoggedIn();
  }

  onLogoutClick() {
    this.authenticationService.logout();
    this.router.navigate(["logout"]);
  } 

}
