import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { DashboardService } from '../../../core/services/dashboard.service';
import { PurchaseInvoiceDashboardData } from '../../../core/models/pur-invoice';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pur-invoice-dashboard',
  templateUrl: './pur-invoice-dashboard.component.html',
  styleUrls: ['./pur-invoice-dashboard.component.scss']
})
export class PurchaseInvoiceDashboardComponent implements OnInit {

  erpName: string = "ERP";

  dashboardData: PurchaseInvoiceDashboardData = new PurchaseInvoiceDashboardData();

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    const userErpTypeShortName = this.authenticationService.getCurrentUser().userErpTypeShortName;
    this.erpName = userErpTypeShortName === null ? "ERP" : userErpTypeShortName;

    this.spinner.show();

    this.dashboardService.getPurchaseInvoiceDashboardData()
      .subscribe(this.onDataLoaded.bind(this),
      error => {
        this.spinner.hide();
        throw error;    // will go to the global error handler
      }
    );

  }


  onDataLoaded(result: PurchaseInvoiceDashboardData) {
    this.dashboardData = result;
    this.spinner.hide();
  }


  onFailedValidationClick() {
    this.router.navigate(["pur-invoice-search"]);
  } 


}
