
  <div class="text-center">
    <i class="material-icons">lock</i>
    You are not authorized to view this area.<br><br>
    <section *ngIf="errorMessage">
      <p> {{errorMessage}}
      </p>
    </section>
    <br>
    <button class="btn btn-primary m-4" [routerLink]="['/']">Go to Home Page</button>
  </div>


