
<div style="margin-left:5px;">
    <p class="page-heading1">ERP Data Syncronization</p>

    <div class="row" style="margin:5px">

        <button type="button" class="btn btn-primary group-button" [disabled]="syncState === 'busy'" (click)="onSyncSupplierClick()">
            <span>Sync Suppliers</span>
        </button>
        
        <button type="button" class="btn btn-primary group-button" [disabled]="syncState === 'busy'" (click)="onSyncCustomerClick()">
            <span>Sync Customers</span>
        </button>
        
        <button type="button" class="btn btn-primary group-button" [disabled]="syncState === 'busy'" (click)="onSyncInventoryItemClick()">
            <span>Sync Inventory Items</span>
        </button>
 
        <button type="button" class="btn btn-primary group-button" [disabled]="syncState === 'busy'" (click)="onSyncAccountClick()">
           <span>Sync Accounts</span>
        </button>

        <button type="button" class="btn btn-primary group-button" [disabled]="syncState === 'busy'" (click)="onSyncTaxCodeIdMappingsClick()">
            <span>Sync Tax Codes</span>
         </button>
 
    </div>

    <div class="row" style="margin:5px">

        <button type="button" class="btn btn-primary group-button" [disabled]="syncState === 'busy'" (click)="onUploadPurchaseInvoicesToErpClick()">
            <span>Upload Purchase Invoices</span>
        </button>

        <!-- <button type="button" class="btn btn-primary group-button" [disabled]="syncState === 'busy'" (click)="onExportSalesInvoiceFromErpClick()">
            <span>Export Sales Invoices</span>
         </button> -->
 
    </div>


    <br/><br/><br/>

    <div [hidden]="syncState !== 'busy'">
        <mat-spinner strokeWidth="24" [diameter]="85" color="warn" ></mat-spinner>
        <span>Processing - please wait</span>
    </div>


    <div style="margin-top: 10px" [hidden]="syncState != 'finished'">
        <div class="row" style="margin-top:5px">

            <div class="col-md-12">
                <span style="font-weight:bold;color:darkblue;"> Sync Results for {{backgroundJobResult.jobName}} </span>
            </div>

            <div class="col-md-2" *ngIf="backgroundJobResult.isSyncJob === false">
                Created: {{backgroundJobResult.itemsProcessedCount}}
            </div>

            <div class="col-md-2" *ngIf="backgroundJobResult.isSyncJob">
                Created: {{backgroundJobResult.insertedNewCount}}
            </div>

            <div class="col-md-2" *ngIf="backgroundJobResult.isSyncJob">
                Updated: {{backgroundJobResult.updatedExistingCount}}
            </div>

            <div class="col-md-2"  *ngIf="backgroundJobResult.isSyncJob">
                Set to INActive: {{backgroundJobResult.setToInActiveCount}}
            </div>

            <div class="col-md-2">
                Errors: {{backgroundJobResult.errorCount}}
            </div>

        </div>


        <div class="row" style="margin-top:5px">

        <div style="margin-left:15px;">
            <div class="table-responsive" *ngIf="backgroundJobResult.errorList && backgroundJobResult.errorList.length">
            <span style="color:red;font-size:16px;font-weight:bold;"> Errors: </span>
            <table class="table table-sm">
                <tbody>
                <tr *ngFor="let error of backgroundJobResult.errorList; let i = index">
                    <td>{{error}}</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>

    </div>

    </div>

</div>

<p>&nbsp;</p>
    
