<div class="modal-header">
  <h4 class="modal-title">
     <span>Change Password</span>
 </h4>
 <button (click)="activeModal.dismiss('Cancelled')" class="fa fa-close fa-lg"></button>
</div>

  
<form [formGroup]="authUserForm">
  <div class="modal-body">
    <div class="container">

      <div class="form-group row">
        <label for="authUserId" class="col-sm-3 col-form-label">Id:</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="authUserId" [readonly]="true" />
        </div>
      </div>
 
      <div class="form-group row">
        <label for="loginName" class="col-sm-3 col-form-label">Login Name:</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" formControlName="loginName" [readonly]="true" />
        </div>
      </div>
  
      <div class="form-group row">
        <label for="password" class="col-sm-3 col-form-label">New Password:</label>
        <div class="col-sm-8">
          <input class="form-control" style="width:500px;" type="password" placeholder = "Password"
          [ngClass]="{'is-invalid': passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)}"
          formControlName="password" /> 

          <div *ngIf=" passwordControl.errors" class="invalid-feedback">
            <div [hidden]="! passwordControl.errors.required">Password is required.</div>
          </div>

        </div>
      </div>


      <div class="form-group row">
        <label for="confirmPassword" class="col-sm-3 col-form-label">Confirm Password:</label>
        <div class="col-sm-8">
          <input class="form-control" style="width:500px;" type="password" placeholder = "Confirm Password" (keydown)="lastFieldKeydown($event)"
          [ngClass]="{'is-invalid': confirmPasswordControl.invalid && (confirmPasswordControl.dirty || confirmPasswordControl.touched)}"
          formControlName="confirmPassword" /> 

          <div *ngIf=" confirmPasswordControl.errors" class="invalid-feedback">
            <div [hidden]="! confirmPasswordControl.errors.required">Password is required.</div>
          </div>

        </div>
      </div>


    </div>
  </div>


  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">
        <span>Cancel</span>
      </button>
      <span> 
        <button type="button" class="btn btn-outline-primary" [disabled]="authUserForm.invalid" (click)="onSubmitClick()">
          <span *ngIf="savingState !== 'loading'">Save & Close</span>
          <span *ngIf="savingState === 'loading'">Saving...</span>
        </button>
      </span>
    </div>

</form>


