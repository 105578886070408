import { HttpParams } from "@angular/common/http";
import * as moment from "moment";
//import { ReportExtraParam } from "./models/report/report-extra-param";

class GenericParam {
    paramName: string;
    paramValue: string;
}

export class ParamOptions {
    excludeList?: string[];
    paramNameMap?: Map<string, string>;
    capitalize?: boolean;
    constructor() {
        this.capitalize = true;
        this.paramNameMap = new Map<string, string>();
    }
}

export class ParametersHelpers {

    private static getTransformedParams(params: any): GenericParam[] {
        const result: GenericParam[] = [];
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                let value = params[key];
                if (value != undefined && value != null && value !== '') {
                    // if (key === 'page') {
                    //     value = value - 1;
                    // }
                    if (value instanceof Date) {
                        value = moment(value).format('YYYY-MM-DD');
                    }
                    result.push({
                        paramName: key,
                        paramValue: value.toString()
                    })
                }
            }
        }
        return result;
    }

    /**
     * Recursively traverse the list of properties of the params
     * and append those different that null|undefined to the list of http parameters.
     * If it's a Date, then uses the standard ISO format
     * @param params 
     */
    static getHttpParamsByFilters(params: any): HttpParams {
        let requestParams = new HttpParams();
        this.getTransformedParams(params).forEach(p => {
            requestParams = requestParams.append(p.paramName, p.paramValue);
        });
        return requestParams;
    }

    /**
     * Given an object with a list of properties, transform that into a list of paramters to pass to the report server.
     * Useful for quickly transforming search table filters into list of report params. 
     * ATTENTION!!! Parameter names are automatically converted so it is assumed that SSRS report server uses the same names as UI
     * @param params 
     */
    // static getReportParams(params: any, options?: ParamOptions): ReportExtraParam[] {
    //     options = options ? options : new ParamOptions();
    //     options.excludeList = options.excludeList ? options.excludeList : [];
    //     options.paramNameMap = options.paramNameMap ? options.paramNameMap : new Map<string, string>();
    //     return this.getTransformedParams(params).filter(p => !options.excludeList.includes(p.paramName)).map(p => {
    //         const rp = new ReportExtraParam();
    //         const newParamName = options.paramNameMap.has(p.paramName) ? options.paramNameMap.get(p.paramName) : p.paramName;
    //         rp.paramName = options.capitalize ? (newParamName.charAt(0).toUpperCase() + newParamName.slice(1)) : newParamName;  // transform the paramter name capitalizing the first letter e.g. caseDate => CaseDate
    //         rp.paramValue = p.paramValue;
    //         return rp;
    //     });
    // }

}