import { Component, OnInit } from '@angular/core';
import { SupplierService } from '../../../core/services/supplier.service';
import { Supplier } from '../../../core/models/supplier';
import { Pagination } from '../../../core/models/pagination';
import { PaginatedResult } from '../../../core/models/paginated-result';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalOptions, SimpleModalConfirmComponent } from "../../../shared/simple-modal-confirm/simple-modal-confirm.component";
import { Params, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

  supplierForm: FormGroup;
  
  selectedPage: number = 1;
  pagination: Pagination;
 
  searchState: string = 'loading';
  searchFilters = new Supplier();
  searchResults: Supplier[] = new Array<Supplier>();

  isDataDirty: boolean = false;
 
  constructor(
    private supplierService: SupplierService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private router: Router
  )
  {
    this.createForm();
  }
  

  ngOnInit() {
    this.fireSearchRequests();
    this.searchState = 'finished'; 
  }

  resetSearch() {
    this.supplierForm.reset();
    this.fireSearchRequests();
  }

  fireSearchRequests(page=1) {

    this.spinner.show();

      this.populateSearchFilter();
      this.searchState = 'loading';
  
      this.supplierService.search(this.searchFilters, page)
        .subscribe(this.onSearchLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(['/home']);
          throw error;    // will go to the global error handler
        }
      );
  }


  onSearchLoaded(result: PaginatedResult<Supplier>){
    this.pagination = Pagination.fromJson(result);
    this.searchResults = result.results;
    this.searchState = 'finished';

    this.spinner.hide();
  
    // the json coming back from the server is adding one to the page (I think angular assumes it's zero based)
    if (this.pagination.page > 1)
      this.pagination.page = this.pagination.page - 1;
  }

  populateSearchFilter()
  {
    this.searchFilters.supplierId = this.supplierIdKey;
    this.searchFilters.supplierName = this.supplierNameKey;
  }

  onSearchSubmit() {
      if (this.supplierForm.valid) {
          this.fireSearchRequests();
      }
  }

  onSearchReset()
  {
      this.selectedPage = 1;
      this.supplierForm.reset();
      this.fireSearchRequests();
  }

    
  createForm() {
    this.supplierForm = this.fb.group({
      supplierIdKey: [''],
      supplierNameKey: ['']
    });
    this.supplierForm.get('supplierIdKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
    this.supplierForm.get('supplierNameKey').valueChanges.subscribe(this.onSearchSubmit.bind(this));
  }


  //This function is called automatically by the ngBootstrap pagination component on page load passing page=1.
  onPageClicked(page: number) {
    if (page && page !== this.pagination.page) {
      this.fireSearchRequests(page);
    }
  }


  onSupplierDetailClick(supplierId: number) {
    this.router.navigate(["supplier-maint", 'view', supplierId]);
  } 

  onSupplierCreateClick() {
    this.router.navigate(["supplier-maint", 'add', 0]);
  } 

  onSupplierModifyClick(supplierId: number) {
    this.router.navigate(["supplier-maint", 'edit', supplierId]);
  } 

  deleteConfirm(supplierId: number) {

    const data = <SimpleModalOptions>{
      title: "Are you sure you want to delete this Supplier?"
    };

    const modalRef = this.modalService.open(SimpleModalConfirmComponent);
    const modalInstance: SimpleModalConfirmComponent =
      modalRef.componentInstance;
    modalInstance.data = data;

    modalInstance.confirmed.subscribe(res => {
      if (res) {
        this.supplierService.delete(supplierId).subscribe((response) => {
          this.onSearchSubmit();
        });
       
      }
    });

  }


  handleModalClosed(isSuccess: boolean) {
   if (isSuccess) {
     this.onSearchSubmit();
   }
  }


  get supplierIdKey() { return this.supplierForm.get('supplierIdKey').value; }
  get supplierNameKey() { return this.supplierForm.get('supplierNameKey').value; }

}
