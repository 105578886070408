
<div style="background-image: url(/assets/images/edipak_header.jpg); background-repeat: no-repeat; background-size: cover;">
    <br/><br/><br/>
    <br/><br/><br/>
  </div>


<div style="margin:5px;">

    <p class="page-heading1">Help for {{erpName}} Users</p>

    <p class="page-heading2">Set up</p>
    <div class="row">
        <div class="col-md-12">
            <ul>
                <li style="list-style:circle;">On the Preferences tab on the Settings screen, set the EDI Participant Identifier for your company. </li>
                <li style="list-style:circle;">Enter your login credentials for {{erpName}} and EDI Access Point provider.</li>
                <li style="list-style:circle;">For each Supplier that gets syncd from {{erpName}}, set the EDI Participant Identifier and default Bill Type.</li>
                <li style="list-style:circle;">Todo.</li>
                <li style="list-style:circle;">Todo.</li>
            </ul>
        </div>
    </div>



    <p class="page-heading2">Invoices from unknown Suppliers</p>
    <div class="row">
        <div class="col-md-12">
            <span>If the Supplier is legitimate and you wish to trade with them then do the following:</span>
            <ul>
                <li style="list-style:circle;">Ensure that the Supplier exists in {{erpName}} with a valid email address.</li>
                <li style="list-style:circle;">Ensure that the Supplier has been syncd to the Edipak Card List.</li>
                <li style="list-style:circle;">Ensure that the correct EDI Participant Identifier has been entered for the Supplier.</li>
            </ul>
            <span>If all of the above tasks have been performed then the Invoice message will be collected snd processed in the next overnight scheduled job.</span>
                            
            <p> If the Invoice message is from a Supplier you do not trade with you should set this message to "Rejected" and black-list the Supplier EDI Participant Identifier with your Access Point provider. </p>
        </div>
    </div>







    <p class="page-heading2">Business Rules</p>
    <div class="row">
        <div class="col-md-12">

            <span>For a Purchase Invoice message to be successfully collected and accepted from your EDI Access Point:</span>
            <ul>
                <li style="list-style:circle;">Must have a valid Supplier EDI Participant Identifier which matches a Supplier in this system.</li>
                <li style="list-style:circle;">The Supplier in {{erpName}} must be set to Active.</li>
                <li style="list-style:circle;">The Supplier Invoice Number can not be blank.</li>
                <li style="list-style:circle;">The Invoice Number must not already exist in the system for the Supplier.</li>
                <li style="list-style:circle;">Todo.</li>
                <li style="list-style:circle;">Todo.</li>
           </ul>

           <p>Purchase Invoices will be validated differently depnding on the Bill Type (template type in {{erpName}}).  </p>

           <br/>
           <span>For a Line Purchase Invoice Validation Rules:</span>
           <ul>
               <li style="list-style:circle;">.</li>
               <li style="list-style:circle;">Todo.</li>
               <li style="list-style:circle;">Todo.</li>
               <li style="list-style:circle;">Todo.</li>
          </ul>

          <p>Purchase Invoices will need to be Validated and Approved before they will be uploaded into {{erpName}}).  </p>


        </div>
    </div>


    <p class="page-heading2">Invoice Workflow</p>
    <div class="row">
        <div class="col-md-12">
            <ul>
                <li style="list-style:circle;">Validation lists are syncronized from {{erpName}} to a local Edipak database on a regular basis.</li>
                <li style="list-style:circle;">A Scheduled Job runs each night hat collects all new Purchase Invoices from the EDI Acces Point.</li>
                <li style="list-style:circle;">Todo.</li>
                <li style="list-style:circle;">Todo.</li>
            </ul>
        </div>
    </div>

    
    <p class="page-heading2">Troubleshooting</p>
    <div class="row">
        <div class="col-md-12">
            <span>bla bla</span>
        </div>
    </div>


    <p class="page-heading2">Limitations</p>
    <div class="row">
        <div class="col-md-12">
            <p>Invoices with LCT (Luxury Car Tax) or WET (Wine Equalization Tax) tax codes are not supported yet. </p>
            <p>Professional and Miscellaneous type Purchase Invoices are not supported yet. </p>
            <p>{{erpName}} only has facility in api for freight - but not for other charges. Therefore the OtherCharges total will be posted to Freight in {{erpName}} </p>
            <p>The ShipTo Address on Sales Invoice will be the ShipTo Address for the Customer in {{erpName}} as the api contains the address in a single string. </p>
            <p>Must enable Sales Invoice auto-numbering in {{erpName}}. </p>
        </div>
    </div>

    <br/>

</div>

