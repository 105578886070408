
import { AuthenticationService } from './../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/** This is used to protect the routes from unauthorized access */

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
    ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean  {

    if (this.authenticationService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(["login"]);

    return false;
  }

    // if (this.authService.isLoggedIn()) {
    //     return true;
    // }

    // // This triggers a redirect to an external site (IdentityServer) where a login page is shown.
    // this.authService.login();

//     return false;
// }

}
