
<div class="card card-blue-border">
  <div class="card-header card-big-blue-text-header">
    <span>Edit Purchase Invoice</span>
  </div>
  <div class="card-body">

    <form [formGroup]="purchaseInvoiceForm">
        <div class="row">
          <div class="col-md-6">

              <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Id:</label>
                  <div class="col-sm-8">
                    <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.purchaseInvoiceId}}</span>
                  </div>
              </div>
        
           
            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Supplier:</label> <span class="required">*</span> </div>

              <div class="col-sm-8">
                <select class="form-control"
                
                  [ngClass]="{'is-invalid': supplierIdControl.invalid && (supplierIdControl.dirty || supplierIdControl.touched)}"
                  class="form-control prevent-uppercase"
                  formControlName="supplierId">

                  <option *ngFor="let supplier of supplierPickList" [ngValue]="supplier.id">{{supplier.name}}</option>
                  <option [ngValue]=null>-- Select a Supplier --</option>
                </select>

                  <div *ngIf=" supplierIdControl.errors" class="invalid-feedback">
                      <div [hidden]="! supplierIdControl.errors.required">Supplier is required.</div>
                  </div>

              </div>
            </div>


            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Bill Type:</label> <span class="required">*</span> </div>
              <div class="col-sm-8">
                <select class="form-control" (change)="layoutTypeOnChange($event)"
                  [ngClass]="{'is-invalid': invoiceLayoutTypeControl.invalid && (invoiceLayoutTypeControl.dirty || invoiceLayoutTypeControl.touched)}"
                    class="form-control prevent-uppercase"
                    formControlName="invoiceLayoutType">
                    <option *ngFor="let invoiceLayout of invoiceLayoutPickList" [ngValue]="invoiceLayout.code">{{invoiceLayout.name}}</option>
                </select>
                <div *ngIf=" invoiceLayoutTypeControl.errors" class="invalid-feedback">
                    <div [hidden]="! invoiceLayoutTypeControl.errors.required">Bill Type is required.</div>
                </div>
              </div>
            </div>


            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Invoice No:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:300px;">{{purchaseInvoice.supplierInvoiceNumber}}</span>
              </div>
            </div>
      
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Invoice Status:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg">{{purchaseInvoice.purchaseInvoiceStatusName}}</span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Received Date:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.receivedDate | stDate}}</span>
              </div>
            </div>
  
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Issue Date:</label>
              <div class="col-sm-8">
                  <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.issueDate | stDate}}</span>
                </div>
            </div>
    
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Due Date:</label>
              <div class="col-sm-8">
                  <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.dueDate | stDate}}</span>
              </div>
            </div>
      
          </div>


          <div class="col-md-6">
            
            <div class="form-group row">
              <label class="col-sm-4 col-form-label"> Purch Order No:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.purchaseOrderNo}}</span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Approved:</label>
              <div class="col-sm-8">
                <span *ngIf="isPurchaseInvoiceApproved()" class="form-control readonly-bg" style="width:200px;"> Yes</span>
                <span *ngIf="!isPurchaseInvoiceApproved()" class="form-control readonly-bg" style="width:200px;"> No</span>
              </div>
            </div>
    
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Currency:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.invoiceCurrencyCode}}</span>
              </div>
            </div>
    
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">LineExtensionAmount:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.lineExtensionAmount | currency}}</span>
              </div>
            </div>
        
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">TaxExclusiveAmount:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.taxExclusiveAmount | currency}}</span>
              </div>
            </div>
      
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">TaxInclusiveAmount:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.taxInclusiveAmount | currency}}</span>
              </div>
            </div>
      
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">ChargeTotalAmount:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.chargeTotalAmount | currency}}</span>
              </div>
            </div>
      
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Amount Payable:</label>
              <div class="col-sm-8">
                <span class="form-control readonly-bg" style="width:200px;">{{purchaseInvoice.payableAmount | currency}}</span>
              </div>
            </div>
        

          </div>

        </div>

    </form>

  </div>
</div>

<div class="form-group" style="margin-top:5px;">
  <div class="col-md-offset-2 col-md-10">
    <button type="button" class="btn btn-primary" [disabled]="savingState === 'loading'" (click)="onSubmitClick()">
        <span *ngIf="savingState !== 'loading'">Save Invoice</span>
        <span *ngIf="savingState === 'loading'">Saving...</span>
        </button>
    <button type="button" class="btn btn-outline-dark" (click)="onCancel()"> Cancel</button>
  </div>
</div>


<!-- PurchaseInvoice Lines -->
<div class="card card-blue-border" style="margin-top: 10px">
  <div class="card-header card-blue-header">
    Invoice Lines
  </div>
  <div class="card-body">

    <div class="row">
        <div class="col-md-12">
  
              <div class="table-editable" *ngIf="purchaseInvoice.invoiceLines != undefined">
  
  
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                      <th>Item Id:</th>
                      <th>Description:</th>
                      <th>Account:</th>
                      <th>Quantity:</th>
                      <th>Unit Price:</th>
                      <th>Tax:</th>
                      <th>Line Total:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let line of purchaseInvoice.invoiceLines; let i = index">
  
                  <td>{{line.itemIdentifier}}</td>

                  <td>{{line.itemDescription}}</td>
                  <!-- <span contenteditable="true" (keyup)="changeLineValue(line.purchaseInvoiceLineId, 'itemName', $event)" (blur)="updateLineList(line.purchaseInvoiceLineId, 'itemName', $event)">{{line.itemName}}</span> -->

                  <td>{{line.accountingCostCode}}</td>

                  <td>{{line.invoicedQuantity}}</td>
                  <td>{{line.unitPrice | currency}}</td>
                  <td>{{line.taxCode}}</td>
                  <td>{{line.lineExtensionAmount | currency}}</td>
  
                  <td>
                    <button (click)="editInvoiceLineModalComponent(line)" class="btn btn-sm btn-outline-primary mr-2">Edit</button>
                  </td>

                  </tr>
  
                  <tr *ngIf="!states.isShowAddPurchaseInvoiceItemForm && purchaseInvoice.invoiceLines.length == 0">
                      <td colspan="5" class="text-center">There are no PurchaseInvoice Lines to display</td>
                  </tr>
  
                </tbody>
              </table>
  
            </div>
  
        </div>
      </div>
  
  </div>
</div>


<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>
