
  <div class="card">
    <div class="card-header">
        TaxCodes
    </div>
    <div class="card-body">

    </div>


  <div class="row">
    <div class="col-md-12">

      <div class="table-responsive" *ngIf="!!taxCodeList && !!taxCodeList.length">
    
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Code:</th>
              <th>Description</th>
              <th>TaxCode Type</th>
              <th>EDI Code</th>
              <th>Rate</th>
              <th>Last Modified</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of taxCodeList">
              <td>{{r.code}}</td>
              <td>{{r.description}}</td>
              <td>{{r.taxCodeType}}</td>
              <td>{{r.peppolTaxCategoryCode}}</td>
              <td>{{r.rate}}</td>
              <td>{{r.modifiedOn | stDate}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

</div>

<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>


