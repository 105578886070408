import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../../../core/helpers/form-helpers';
import { AuthUserService } from '../../../../core/services/auth-user.service';
import { AuthUser } from '../../../../core/models/auth-user';
import { AuthUserRole } from '../../../../core/models/auth-user';
import { PickListService } from '../../../../core/services/pickList.service';
import { PickListItem } from '../../../../core/models/pickList';
import { ToasterService } from '../../../../core/services/toaster.service';
import { AuthRoleEnum } from '../../../../core/models/enum';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-auth-user-maint',
  templateUrl: './auth-user-maint.component.html',
  styleUrls: ['./auth-user-maint.component.scss']
})

export class AuthUserMaintComponent implements OnInit {

  authUserId: number;
  authUser: AuthUser = new  AuthUser();

  authRolePickList: PickListItem[];
  selectedAuthRole: PickListItem;

  newAuthUserRole = new AuthUserRole();

  authUserForm: FormGroup;
  isEditable: boolean;
  action: string;
  savingState: string;

  states = {
    isShowAddAuthUserRoleForm: false
  };


  @Output()
  indicatoreChange: EventEmitter<any> = new EventEmitter();

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private route:ActivatedRoute,
      private spinner: NgxSpinnerService,
      private authUserService: AuthUserService,
      private pickListService: PickListService,
      private toaster: ToasterService
   ) {}  

  ngOnInit() {
    this.route.params.subscribe(params => this.action = params['action'] );
    this.route.params.subscribe(params => this.authUserId = params['authUserId']);

    this.isEditable = this.action != 'view';

    this.authUserForm = new FormGroup({
      authUserId: new FormControl(""),
      loginName: new FormControl("", Validators.required),
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      isActive: new FormControl(true, Validators.required),
      contactEmail: new FormControl("", Validators.required),
      authRoleId: new FormControl(0, Validators.required),
      password: new FormControl("", Validators.required),
      confirmPassword: new FormControl("", Validators.required)
    });

    this.resetNewAuthUserRole();
    this.spinner.show();

    this.pickListService.getAuthRolePickList().subscribe(res => {
      this.spinner.hide();
      this.authRolePickList = res;
    })

    if (this.action === 'add') {
      this.authUser.authUserRoleList = new Array<AuthUserRole>();
    }
    else
      this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.authUserService.getById(this.authUserId)
      .subscribe(this.onDataLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(["auth-user"]);
          throw error;       // will go to the global error handler
        }
    );
  }


  onDataLoaded(obj: AuthUser) {
    this.authUser = obj;
    this.authUser.childrenToBeDeleted = new Array<number>();
 
    this.authUserForm = this.formBuilder.group({
      authUserId: [obj.authUserId],
      loginName: [obj.loginName, Validators.required],
      firstName: [obj.firstName, Validators.required],
      lastName: [obj.lastName, Validators.required],
      isActive: [obj.isActive, Validators.required],
      contactEmail: [obj.contactEmail, Validators.required],
      password: [obj.password, Validators.required],
      confirmPassword: [obj.confirmPassword, Validators.required]
    });

    this.spinner.hide();

    // unless we are adding we don't want to input pw - this will keep validation happy
    this.authUserForm.patchValue( { password: 'password' } );
    this.authUserForm.patchValue( { confirmPassword: 'password' } );
  }

  
  onSubmitClick() {
    if (this.authUserForm.invalid) {
      FormHelpers.validateFormGroup(this.authUserForm);
    }
    else {

      // show an error if no user roles have been added
      if ( this.authUser.authUserRoleList.length === 0) {
        this.toaster.showError('You must add a user role.');
        return false;
      }
      
      // create a model with values returned from the reactive form
      const modifiedAuthUser: AuthUser = this.authUserForm.getRawValue();

      // get the data that may have changed from the reactive form controls 
      // & update the orinal order model so what is not updated on Form is not clobbered in db
      this.authUser.loginName = modifiedAuthUser.loginName;
      this.authUser.firstName = modifiedAuthUser.firstName;
      this.authUser.lastName = modifiedAuthUser.lastName;
      this.authUser.isActive = modifiedAuthUser.isActive;
      this.authUser.contactEmail = modifiedAuthUser.contactEmail;


      this.savingState = 'loading';

      if (this.action === 'add') {
        this.authUser.authUserId = 0;

        this.authUser.password = modifiedAuthUser.password;
        this.authUser.confirmPassword = modifiedAuthUser.confirmPassword;

        if (this.authUser.password !== this.authUser.confirmPassword) {
          this.toaster.showError('The password and confirmation password do not match.');
          return;
        }
  
        if (this.authUser.password.length < 6 || this.authUser.password.length > 50) {
          this.toaster.showError('The password must be between 6 and 50 characters in length.');
          return;
        }
  
        var newAuthUser = this.authUserService.add(this.authUser)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }
      else {
        this.authUserService.update(this.authUser)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }

    }
  }

  onSavingSuccess() {
    this.savingState = "finished";
    this.router.navigate(["auth-user"])
    this.toaster.showSuccess('Successfully saved user.');
  }

  onSavingError(error) {
    this.savingState = "";
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["home"])
  }

  onModifyClick() {
    this.action = 'edit';
    this.isEditable = true;
  } 

  onLoginNameBlur() {
    const loginName = this.authUserForm.get("loginName").value ;
    if ( ! this.authUserForm.get("contactEmail").value)
      this.authUserForm.patchValue( { contactEmail: loginName } );
  }

  lastFieldKeydown(event) {
    if (event.key === 'Enter') {
      this.onSubmitClick();
    }
  }



//----------------------------------------------------------------------------------------
//AuthUserRoles
//----------------------------------------------------------------------------------------

  showAddAuthUserRoleForm = function (show) {
    this.states.isShowAddAuthUserRoleForm = show;
  }

  addAuthUserRoleToList = function () {

    // make sure it does not already exist
    if (this.authUser.authUserRoleList.find(x=> x.authRoleId === this.newAuthUserRole.authRoleId ) )
    {
      this.toaster.showError('This User Role has aleady been added.');
      return false;
    }

    // show an error if a UserRole has not been selected
    if ( typeof this.selectedAuthRole === 'undefined' ) {
      this.toaster.showError('You must select a User Role.');
      return false;
    }

    this.authUser.authUserRoleList.push({
      authUserRoleId: 0,
      authUserId: this.authUser.authUserId,
      authRoleId: this.newAuthUserRole.authRoleId,
      authRoleName: this.newAuthUserRole.authRoleName,
    });


    this.resetNewAuthUserRole();

    this.states.isShowAddAuthUserRoleForm = false;
  }

  resetNewAuthUserRole() {
  // reset newAuthUserRole to empty for adding next line
  this.newAuthUserRole = { authUserRoleId:0, authUserId:0, authRoleId:0, authRoleName:"" };
  }

  removeAuthUserRole = function (index, authUserRoleId) {
    this.authUser.authUserRoleList.splice(index, 1);
    this.authUser.childrenToBeDeleted.push(authUserRoleId);
  }

  onAuthRoleChange(newValue) {
    this.selectedAuthRole = this.authRolePickList.find( p => p.id === newValue);
    this.newAuthUserRole.authRoleName =  this.selectedAuthRole.name;
  }


  get authUserIdControl() { return this.authUserForm.get("authUserId"); }
  get loginNameControl() { return this.authUserForm.get("loginName"); }
  get firstNameControl() { return this.authUserForm.get("firstName"); }
  get lastNameControl() { return this.authUserForm.get("lastName"); }
  get isActiveControl() { return this.authUserForm.get("isActive"); }
  get erpcRefCodeControl() { return this.authUserForm.get("erpcRefCode"); }
  get contactEmailControl() { return this.authUserForm.get("contactEmail"); }
  //get authRoleIdControl() { return this.authUserForm.get("authRoleId"); }
  get passwordControl() { return this.authUserForm.get("password"); }
  get confirmPasswordControl() { return this.authUserForm.get("confirmPassword"); }


}
