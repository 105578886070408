import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Account } from '../models/account';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated-result';
import { AuthenticationService } from '../services/authentication.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }


  public getAccountList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      return this.httpClient.get<Account[]>(`${environment.api}/AccountCrud/getAccountList/${userTenantId}`);
  }

  public getById(accountId: number) :Observable<Account> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    requestParams = requestParams.append("accountId", accountId.toString());
    return this.httpClient.get<Account>(`${environment.api}/accountcrud`,  {params: requestParams} );
  }
  
}
