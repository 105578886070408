import { Component, OnInit } from '@angular/core';
import { TaxCodeService } from '../../../core/services/tax-code.service';
import { TaxCode } from '../../../core/models/tax-code';
import { Params, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tax-code',
  templateUrl: './tax-code.component.html',
  styleUrls: ['./tax-code.component.scss']
})
export class TaxCodeComponent implements OnInit {

  taxCodeList: TaxCode[] = new Array<TaxCode>();
 
  constructor(
    private taxCodeService: TaxCodeService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ){ }

    ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.taxCodeService.getTaxCodeList()
      .subscribe(this.onDataLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(["tax-code"]);
          throw error;       // will go to the global error handler
        }
    );
  }

  onDataLoaded(result: TaxCode[]) {
    this.taxCodeList = result;
    this.spinner.hide();
  }

}
