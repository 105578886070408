import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '..//..//core/services/authentication.service';

import { PurchaseInvoiceDashboardComponent } from '..//..//pages/pur-invoice/pur-invoice-dashboard/pur-invoice-dashboard.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService
    ) { }

  ngOnInit() {
  }

  isLoggedIn() {
    return this.authenticationService.isLoggedIn();
  }

}
