import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorised-area',
  templateUrl: './unauthorised-area.component.html',
  styleUrls: ['./unauthorised-area.component.scss']
})
export class UnauthorisedAreaComponent implements OnInit {

  errorMessage: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.errorMessage = params['errorMessage'];
    });
  }

}
