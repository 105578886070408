import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TaxCode } from '../models/tax-code';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated-result';
import { AuthenticationService } from '../services/authentication.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class TaxCodeService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }


  public getTaxCodeList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      return this.httpClient.get<TaxCode[]>(`${environment.api}/TaxCodeCrud/getTaxCodeList/${userTenantId}`);
  }

  public getById(taxCodeId: number) :Observable<TaxCode> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    requestParams = requestParams.append("taxCodeId", taxCodeId.toString());
    return this.httpClient.get<TaxCode>(`${environment.api}/taxCodecrud`,  {params: requestParams} );
  }
  
}
