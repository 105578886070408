import { Component, OnInit } from '@angular/core';

import { SalesInvoiceErp } from '../../../core/models/sales-invoice';
import { Guid } from "guid-typescript";

import { SalesInvoiceSearchFilters } from '../../../core/models/sales-invoice-search-filters';
import { SalesInvoiceService } from '../../../core/services/sales-invoice.service';
import { PickListService } from '../../../core/services/pickList.service';
import { PickListItem } from '../../../core/models/pickList';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pagination } from '../../../core/models/pagination';
import { PaginatedResult } from '../../../core/models/paginated-result';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../../../core/services/toaster.service';
import { AuthenticationService } from '../../../core/services/authentication.service';


@Component({
  selector: 'app-sales-invoice-export',
  templateUrl: './sales-invoice-export.component.html',
  styleUrls: ['./sales-invoice-export.component.scss']
})
export class SalesInvoiceExportComponent implements OnInit {

  erpName: string = "ERP";

  formGroup: FormGroup;
  
  searchState: string = 'loading';
  searchFilters = new SalesInvoiceSearchFilters();
  searchResults: SalesInvoiceErp[] = new Array<SalesInvoiceErp>();

  customerPickList: PickListItem[];

  minDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 2000
}

  constructor(
    private salesInvoiceService: SalesInvoiceService,
    private authenticationService: AuthenticationService,
    private toaster: ToasterService,
    private pickListService: PickListService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder)
 
  {
    this.createForm();
  }

   ngOnInit() {

    const userErpTypeShortName = this.authenticationService.getCurrentUser().userErpTypeShortName;
    this.erpName = userErpTypeShortName === null ? "ERP" : userErpTypeShortName;

     this.initDates();

    this.pickListService.getCustomerPickList().subscribe(res => {
      this.customerPickList = res;
    })

  this.fireSearchRequests();
    this.searchState = 'finished'; 
  }


  // perform the search
  fireSearchRequests(page=1) {

    this.spinner.show();

    // get the search params from the form controls

    let customerId = this.formGroup.get("customerddl").value;
    let customerGuid = Guid.createEmpty();
    // get the Guid from the curently selected picklistitem
    if (customerId !== null) {
      const selectedCustomer: PickListItem = this.customerPickList.find( s => s.id === customerId);
      customerGuid =  selectedCustomer.uid;
    }

    let invoiceDateFromAsISOString: string = null;
    var invoiceDateFrom = this.formGroup.get("invoiceDateFrom").value;
    if (invoiceDateFrom) {
      invoiceDateFromAsISOString = invoiceDateFrom.toISOString().split("T")[0];   // format for OData search at backend
    }
    else {
      this.spinner.hide();
      this.toaster.showError('You must add a date range.');    // this can be removed when pagination is added
      return;
    }

    let invoiceDateToAsISOString: string = null;
    var invoiceDateTo = this.formGroup.get("invoiceDateTo").value;
    if (invoiceDateTo) {
      invoiceDateToAsISOString = invoiceDateTo.toISOString().split("T")[0];   // format for OData search at backend
    }
    else {
      this.spinner.hide();
      this.toaster.showError('You must add a date range.');
      return;
    }


    this.searchState = 'loading';
    this.salesInvoiceService.getNewSalesInvoicesFromErp(customerGuid, invoiceDateFromAsISOString, invoiceDateToAsISOString )
      .subscribe(this.onSearchLoaded.bind(this),
      error => {
        this.spinner.hide();
        this.router.navigate(['/home']);
        throw error;    // will go to the global error handler
      }
    );

  }

  onSearchLoaded(result:  SalesInvoiceErp[]){
    this.searchResults = result;
    this.searchState = 'finished';
    this.spinner.hide();
  }


  onSearchSubmit() {
      if (this.formGroup.valid) {
          this.fireSearchRequests();
      }
  }

  onSearchReset()
  {
      this.formGroup.reset();
      this.initDates();
      this.fireSearchRequests();
  }


  //---Export the Invoice---------------------------------------------------------------------------------------------

  onExportInvoiceClick(invoiceGuid: Guid, invoiceLayoutType: string, invoiceNumber: string ) {
    //this.formState = 'busy';
    this.spinner.show();
    this.salesInvoiceService.exportSingleSalesInvoice(invoiceGuid, invoiceLayoutType)
    .subscribe(this.onExportSuccess.bind(this), this.onServiceError.bind(this));
  } 

  onExportSuccess(result: boolean) {
    this.spinner.hide();

    if (result)
      this.toaster.showSuccess('Completed Exporting Invoice');
    else
      this.toaster.showError('FAILED to Export Invoice');

      this.router.navigate(["sales-invoice-search"])

  }

  onServiceError(error) {
    this.spinner.hide();
    //this.formState = "";
    throw error;    // will go to the global error handler
  }

//------------------------------------------------------------------------------------------------------------------


  createForm() {

    this.formGroup = this.fb.group({
      customerddl: [null],
      invoiceDateFrom: [''],
      invoiceDateTo: [''],
    });

  }
  

  // pagination will also only return one page of data
  private initDates() {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 2);
    this.invoiceDateFromControl.setValue(lastMonth);
    this.invoiceDateToControl.setValue(today);
  }

  get invoiceDateFromControl() { return this.formGroup.get('invoiceDateFrom'); }
  get invoiceDateToControl() { return this.formGroup.get('invoiceDateTo'); }







	checkAllCheckBox(ev) {
		this.searchResults.forEach(x => x.checked = ev.target.checked)
	}

	isAllCheckBoxChecked() {
		return this.searchResults.every(p => p.checked);
  }
  
  onSendSelectedToQueueClick() {
    // TODO: creata an array of selected items and send the GUID and LayoutType as a list to the backend to save & create file
    this.toaster.showInfo('Not Implemented yet');
    this.searchResults.forEach(function (value) {
      console.log('Invoice: ' + value.invoiceNumber + 'IsChecked: ' + value.checked);
    });
  }



  onExportInvoiceButDontWaitClick(invoiceGuid: Guid, invoiceLayoutType: string, invoiceNumber: string ) {
    //this.formState = 'busy';
    //this.spinner.show();
    this.salesInvoiceService.exportSingleSalesInvoice(invoiceGuid, invoiceLayoutType)
    .subscribe();
  } 



}

