import { now } from 'moment';

export class DateHelpers {

    static getNowDateWithEmptyTime() {
        var emptyTimeDate = new Date(now()) 
        
        emptyTimeDate.setHours(0);
        emptyTimeDate.setMinutes(0);
        emptyTimeDate.setSeconds(0);
        emptyTimeDate.setMilliseconds(0);

        return emptyTimeDate;

    };

    static removeTimeFromDate(dateToChange: Date) {
        dateToChange.setHours(0);
        dateToChange.setMinutes(0);
        dateToChange.setSeconds(0);
        dateToChange.setMilliseconds(0);

        return dateToChange;
    };

   

}