
import { Component, OnInit } from '@angular/core';
import { SalesInvoice } from '../../../core/models/sales-invoice';
import { SalesInvoiceSearchFilters } from '../../../core/models/sales-invoice-search-filters';
import { SalesInvoiceService } from '../../../core/services/sales-invoice.service';
import { SalesInvoiceStatusEnum } from '../../../core/models/enum';

import { PickListService } from '../../../core/services/pickList.service';
import { PickListItem } from '../../../core/models/pickList';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pagination } from '../../../core/models/pagination';
import { PaginatedResult } from '../../../core/models/paginated-result';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { DateHelpers } from '../../../core/helpers/date-helpers';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import * as moment from "moment";

@Component({
  selector: 'app-sales-invoice-search',
  templateUrl: './sales-invoice-search.component.html',
  styleUrls: ['./sales-invoice-search.component.scss']
})
export class SalesInvoiceSearchComponent implements OnInit {

  formGroup: FormGroup;
  
  selectedPage: number = 1;
  pagination: Pagination;

  statusEnum = SalesInvoiceStatusEnum;
 
  searchState: string = 'loading';
  searchFilters = new SalesInvoiceSearchFilters();
  searchResults: SalesInvoice[] = new Array<SalesInvoice>();

  customerPickList: PickListItem[];
  // salesInvoiceStatusPickList: PickListItem[];

  minDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 2000
}

  constructor(
    private salesInvoiceService: SalesInvoiceService,
    private pickListService: PickListService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder)
 
  {
    this.createForm();
  }

   ngOnInit() {

     this.initDates();

    this.pickListService.getCustomerPickList().subscribe(res => {
      this.customerPickList = res;
    })

    // this.pickListService.getSalesInvoiceStatusPickList().subscribe(res => {
    //   this.salesInvoiceStatusPickList = res;
    // })

    this.fireSearchRequests();
    this.searchState = 'finished'; 
  }

  resetSearch() {
    this.formGroup.reset();
    this.fireSearchRequests();
   }


  // perform the search
  fireSearchRequests(page=1) {

    this.spinner.show();

    // get the search params from the form controls
    this.searchFilters.customerId = this.formGroup.get("customerddl").value;
    // this.searchFilters.salesInvoiceStatusId = this.formGroup.get("salesInvoiceStatusId").value;

    this.searchFilters.invoiceDateFromAsISOString = null;
    var invoiceDateFrom = this.formGroup.get("invoiceDateFrom").value;
    if (invoiceDateFrom) {
      invoiceDateFrom = DateHelpers.removeTimeFromDate(invoiceDateFrom);
      this.searchFilters.invoiceDateFromAsISOString = invoiceDateFrom.toISOString();
    }

    this.searchFilters.invoiceDateToAsISOString = null;
    var invoiceDateTo = this.formGroup.get("invoiceDateTo").value;
    if (invoiceDateTo) {
      invoiceDateTo = DateHelpers.removeTimeFromDate(invoiceDateTo);
      this.searchFilters.invoiceDateToAsISOString = invoiceDateTo.toISOString();
    }

    this.searchFilters.page = page;

    this.searchState = 'loading';
    this.salesInvoiceService.search(this.searchFilters)
      .subscribe(this.onSearchLoaded.bind(this),
      error => {
        this.spinner.hide();
        this.router.navigate(['/home']);
        throw error;    // will go to the global error handler
      }
    );
  }


  onSearchLoaded(result: PaginatedResult<SalesInvoice>){
    this.pagination = Pagination.fromJson(result);
    this.searchResults = result.results;
    this.searchState = 'finished';

    this.spinner.hide();
  
    // the json coming back from the server is adding one to the page (I think angular assumes it's zero based ???)
    if (this.pagination.page > 1)
      this.pagination.page = this.pagination.page - 1;
  }


  onSearchSubmit() {
      if (this.formGroup.valid) {
          this.fireSearchRequests();
      }
  }

  onSearchReset()
  {
      this.selectedPage = 1;
      this.formGroup.reset();
      this.fireSearchRequests();
  }

  createForm() {
    this.formGroup = this.fb.group({
      customerddl: [null],
     // salesInvoiceStatusId: [null],
      invoiceDateFrom: [''],
      invoiceDateTo: [''],
    });
  }

  onSalesInvoiceDetailClick(salesInvoiceId: number) {
    this.router.navigate(["sales-invoice-detail", salesInvoiceId])
  } 

  //This function is called automatically by the ngBootstrap pagination component on page load passing page=1.
  onPageClicked(page: number) {
    if (page && page !== this.pagination.page) {
      this.fireSearchRequests(page);
    }
  }


  // in addition to this filtering, pagination will also only return one page of data
  initDates() {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 2);
    this.invoiceDateFromControl.setValue(lastMonth);
    this.invoiceDateToControl.setValue(today);
  }


  get invoiceDateFromControl() { return this.formGroup.get('invoiceDateFrom'); }
  get invoiceDateToControl() { return this.formGroup.get('invoiceDateTo'); }

}
