import { Guid } from "guid-typescript";

export class InventoryItem {
    inventoryItemId: number;
    inventoryItemGuid: Guid;
    erpIdentifier: string;
    inventoryItemIdentifier: string;

    isActive: boolean;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    tenantId: number;

    inventoryItemName: string;
}