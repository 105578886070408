import { Guid } from "guid-typescript";

export class  PurchaseInvoice {

    purchaseInvoiceId: number;
    purchaseInvoiceCollectIdId: number;
    messageUuid: string;
    tenantId: number;
    supplierId: number;
    supplierName: string;
    purchaseInvoiceStatusId: number;
    purchaseInvoiceStatusName: string;
    isValid: boolean;

    invoiceLayoutType: string; 
    supplierInvoiceNumber: string;
    xmlFileFullName: string;
    invoiceErpGuid: Guid;
    invoiceTypeId: number;
    receivedDate: Date;
    issueDate: Date;
    dueDate: Date;
    purchaseOrderNo: string;
    buyerReference: string;
    isApproved: boolean;
    approvedOn: Date;
    approvedBy: string;
    accountingCostCode: string;
    invoiceCurrencyCode: string;

    freightChargeAmount: number;
    freightChargeTaxCode: string;

    note: string;
    shipToAddress: string;

    lineExtensionAmount: number;
    taxExclusiveAmount: number;
    taxInclusiveAmount: number;
    chargeTotalAmount: number;
    chargeTotalTaxCode: string;
    payableAmount: number;

    invoiceLines: InvoiceLine[];

    validationErrors: string[];

}

export class InvoiceLine {
    purchaseInvoiceLineId: number;
    purchaseInvoiceId: number;
    tenantId: number;
    invoiceLineNumber: number;
    orderLineNumber: number;
    accountingCostCode: string;
    invoicedQuantity: number;
    invoicedQuantityUnitCode: string;
    lineExtensionAmount: number;
    lineExtensionAmountCurrencyCode: string;
    unitPrice: number;
    unitPriceCurrencyCode: string;
    itemIdentifier: string;
    itemName: string;
    itemDescription: string;
    buyersItemIdentification: string;
    standardItemIdentification: string;
    originCountryCode: string;
    commodityClassificationCode: string;
    taxCode: string;
    taxCodePercent: number;
    isDirty: boolean;
}

export class PurchaseInvoiceHistory {
    purchaseInvoiceHistoryId: number;
    purchaseInvoiceId: number;
    tenantId: number;
    workFlowActionTypeId: number;
    actionedOn: Date;
    actionedBy: string;
    actionDescription: string;
}

export class PurchaseInvoicePatch {
    purchaseInvoiceId: number;
    purchaseInvoiceStatusId: number;
}

export class  PurchaseInvoiceDashboardData {
    collectionFailedCount: number;
    collectedTodayCount: number;
    failedValidationCount: number;
    readyForApprovalCount: number;
    failedToUploadCount: number;
    readyForUploadCount: number;
    uploadedTodayCount: number;
}



