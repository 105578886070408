import { NgbDateNativeAdapter } from './core/adapters/ngb-date-native-adapter';
import { NgbDateMomentParserFormatter } from './core/formatters/ngbDateFormat';
import { NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormHelpers } from './core/helpers/form-helpers';

export class Constants {
    static readonly DATE_FMT = 'dd MMM yyyy';
    static readonly DATE_SLASH_FMT = 'dd/MM/yyyy';
    static readonly DATETIME_FMT = 'dd MMM yyyy HH:mm';
    static readonly DATE_PARSER_FORMATTER = { provide: NgbDateParserFormatter, useFactory: FormHelpers.formatter };
    static readonly DATE_ADAPTER = { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter };
    static readonly LOCAL_STORAGE_AUTH_DEFAULT_COMPONENT_KEY = 'AUTH_DEFAULT_COMPONENT';
}