export class SalesInvoiceSearchFilters  {
    tenantId: number;
    customerId?: number;
    //salesInvoiceStatusId?: number;
    invoiceDateFromAsISOString: string;
    invoiceDateToAsISOString: string;

    page?: number;
    pageSize?: number;
    sortKey?: string;
    sortDir?: string;

    constructor() {
        this.pageSize = 20;
    }

}