export class  BackgroundJobResult {
    jobName: string;
    tenantId: number;
    isSyncJob: boolean;
    itemsProcessedCount: number;
    insertedNewCount: number;
    updatedExistingCount: number;
    setToInActiveCount: number;
    startDateTime: Date;
    finishDateTime: Date;
    errorCount: number;
    errorList: string[];
}
