import { Component, OnInit } from '@angular/core';
// import { ScheduledJobService } from '../../../core/services/sched-job.service';
import { PurchaseInvoiceService } from '../../../core/services/pur-invoice.service';
import { BackgroundJobResult } from '../../../core/models/bg-job-result';
import { ToasterService } from '../../../core/services/toaster.service';

@Component({
  selector: 'app-pur-invoice-collect',
  templateUrl: './pur-invoice-collect.component.html',
  styleUrls: ['./pur-invoice-collect.component.scss']
})
export class PurchaseInvoiceCollectComponent implements OnInit {

  backgroundJobResult: BackgroundJobResult = new BackgroundJobResult();
  jobState: string;

  constructor(
      private purchaseInvoiceService: PurchaseInvoiceService,
      private toaster: ToasterService
    ) { }

  ngOnInit() {
  }

  ongetNewPurchaseInvoicesFromEdiExchangeClick() {
    this.jobState = 'busy';
    this.purchaseInvoiceService.getNewPurchaseInvoicesFromEdiExchange()
    .subscribe(this.onSyncSuccess.bind(this), this.onSyncError.bind(this));
  }

  onSyncSuccess(result: BackgroundJobResult) {
    this.backgroundJobResult = result;
    this.backgroundJobResult.errorCount = this.backgroundJobResult.errorList.length;
    this.jobState = "finished";
    this.toaster.showSuccess('Completed ' + this.backgroundJobResult.jobName);
  }

  onSyncError(error) {
    this.jobState = "";
    throw error;    // will go to the global error handler
  }



}
