import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SalesInvoiceSearchFilters } from '../../core/models/sales-invoice-search-filters';
import { ParametersHelpers } from '../../core/helpers/parameters-helpers';
import { SalesInvoice } from '../models/sales-invoice';
import { SalesInvoiceHistory } from '../models/sales-invoice';

import { PaginatedResult } from '../models/paginated-result';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

import { SalesInvoiceErp } from '../models/sales-invoice';
import { Guid } from "guid-typescript";

// this is required fot post/put/patch - defines the body
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
  })
  export class SalesInvoiceService {
  
    constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }


    
    search(filters?: SalesInvoiceSearchFilters): Observable<PaginatedResult<SalesInvoice>> {

      const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
  
      // add the search param values from the form to the requestParams
      let requestParams = ParametersHelpers.getHttpParamsByFilters(filters || {});
  
      // add the tenantId to the param list
      requestParams = requestParams.append("tenantId", userTenantId.toString());
  
      return this.httpClient.get<PaginatedResult<SalesInvoice>>(environment.api + "/SalesInvoice/search", {
        params: requestParams,
      });
  
    }
 

    // public getSalesInvoiceHistoryList(salesInvoiceId: number) :Observable<SalesInvoiceHistory[]>{
    //   const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    //   let requestParams = new HttpParams();
    //   requestParams = requestParams.append("tenantId", userTenantId.toString());
    //   requestParams = requestParams.append("salesInvoiceId", salesInvoiceId.toString());
    //   return this.httpClient.get<SalesInvoiceHistory[]>(`${environment.api}/salesInvoice/get-invoice-history`, {params: requestParams} );
    // }
  
    public getById(salesInvoiceId: number) :Observable<SalesInvoice> {
      const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      let requestParams = new HttpParams();
      requestParams = requestParams.append("tenantId", userTenantId.toString());
      requestParams = requestParams.append("salesInvoiceId", salesInvoiceId.toString());
      return this.httpClient.get<SalesInvoice>(`${environment.api}/salesInvoice`,  {params: requestParams} );
    }
  
    public getNewSalesInvoicesFromErp(customerGuid: Guid, invoiceDateFromAsISOString: string, invoiceDateToAsISOString: string) :Observable<SalesInvoiceErp[]> {
      const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      return this.httpClient.get<SalesInvoiceErp[]>(`${environment.api}/SalesInvoice/getNewSalesInvoicesFromErp/${userTenantId}/${customerGuid}/${invoiceDateFromAsISOString}/${invoiceDateToAsISOString}`);
    }


    public exportSingleSalesInvoice( invoiceGuid: Guid, invoiceLayoutType: string) :Observable<boolean> {
      const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      return this.httpClient.post<boolean>(`${environment.api}/SalesInvoice/exportSingleSalesInvoice/${userTenantId}/${invoiceGuid}/${invoiceLayoutType}`, httpOptions);
    }

    public reSendFailedInvoice(salesInvoice: SalesInvoice) :Observable<SalesInvoice> {
      return this.httpClient.post<SalesInvoice>(`${environment.api}/SalesInvoice/resend-invoice/`, salesInvoice, httpOptions);
    }

    public refreshSalesInvoiced(salesInvoiceId: number) :Observable<SalesInvoice> {
      const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      return this.httpClient.get<SalesInvoice>(`${environment.api}/SalesInvoice/refresh/${userTenantId}/${salesInvoiceId}`);
    }

    public setStatus(salesInvoicePatch: any): Observable<any> {
      return this.httpClient.patch(`${environment.api}/salesInvoice/set-status`, salesInvoicePatch, httpOptions);
    }
  
  }
  
  