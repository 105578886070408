
  <div class="card">
    <div class="card-header">
        Search Customers
    </div>
    <div class="card-body">
  
      <form [formGroup]="customerForm" (onSubmit)="onSearchSubmit()">
        <div class="form-row">

          <label class="col-md-2 col-sm-3 col-form-label">Search:</label>

          <div class="col-lg-3 col-md-4 col-sm-6">
            <input formControlName="customerNameKey" class="form-control" placeholder="Name" />
          </div>

          <div class="col-lg-5 col-md-4 col-sm-6">
              <button type="button" class="btn btn-outline-info cancel" [disabled]="searchState === 'loading'" (click)="onSearchReset()">Reset</button>
          </div>
    
      </div>
    </form>

    </div>
  </div>


  <div>
    <div class="row" style='margin-top:10px'>
      <div class="col-md-6">
        <!-- <button class="btn btn-primary" (click)="onCustomerCreateClick()">Create New</button> -->
      </div>
  </div>

  <div class="row">
    <div class="col-md-8">

      <div *ngIf="searchState == 'finished'">
        <div class="table-responsive" *ngIf="!!searchResults && !!searchResults.length">
      
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th>Id:</th>
                <th>Name:</th>
                <th>Is Active:</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of searchResults">
                <td>{{r.customerId}}</td>
                <td>{{r.customerName}}</td>
                <td>{{r.isActive}}</td>
                <td class="d-flex">
                    <button (click)="onCustomerDetailClick(r.customerId)" class="btn btn-sm btn-outline-primary mr-2">Details</button>
                    <button (click)="onCustomerModifyClick(r.customerId)" class="btn btn-sm btn-outline-primary mr-2">Edit</button>
                </td>
                <td>
                    <!-- <button (click)="deleteConfirm(r.customerId)" class="fa fa-trash-o fa-lg"></button> -->
                </td>
              </tr>
            </tbody>
          </table>

          <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pagination.totalCount" [pageSize]="pagination.pageSize"
          [(page)]="selectedPage" (pageChange)="onPageClicked($event)" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>

        </div>
      </div>

    </div>
  </div>

</div>

<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>


