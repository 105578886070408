import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard';
import { AuthModule } from './auth/auth.module';

import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { DataSyncComponent } from './pages/data-sync/data-sync.component';
import { ScheduledJobComponent } from './pages/sched-job/sched-job.component';

import { AuthUserComponent } from './pages/crud/auth-user/auth-user';
import { AuthUserMaintComponent } from './pages/crud/auth-user/auth-user-maint/auth-user-maint.component';
import { CustomerComponent } from './pages/crud/customer/customer.component';
import { CustomerMaintComponent } from './pages/crud/customer/customer-maint/customer-maint.component';
import { SupplierComponent } from './pages/crud/supplier/supplier.component';
import { SupplierMaintComponent } from './pages/crud/supplier/supplier-maint/supplier-maint.component';
import { InventoryItemComponent } from './pages/crud/inventory-item/inventory-item.component';
import { InventoryItemMaintComponent } from './pages/crud/inventory-item/inventory-item-maint/inventory-item-maint.component';
import { AccountComponent } from './pages/crud/account/account.component';
import { TaxCodeComponent } from './pages/crud/tax-code/tax-code.component';
import { HelpComponent } from './pages/help/help.component';

import { PurchaseInvoiceSearchComponent } from './pages/pur-invoice/pur-invoice-search/pur-invoice-search.component';
import { PurchaseInvoiceDetailComponent } from './pages/pur-invoice/pur-invoice-detail/pur-invoice-detail.component';
import { PurchaseInvoiceMaintComponent } from './pages/pur-invoice/pur-invoice-maint/pur-invoice-maint.component';
import { PurchaseInvoiceCollectComponent } from './pages/pur-invoice/pur-invoice-collect/pur-invoice-collect.component';
import { PurchaseInvoiceDashboardComponent } from './pages/pur-invoice/pur-invoice-dashboard/pur-invoice-dashboard.component';

import { SalesInvoiceExportComponent } from './pages/sales-invoice/sales-invoice-export/sales-invoice-export.component';
import { SalesInvoiceSearchComponent } from './pages/sales-invoice/sales-invoice-search/sales-invoice-search.component';
import { SalesInvoiceDetailComponent } from './pages/sales-invoice/sales-invoice-detail/sales-invoice-detail.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'about', component: AboutComponent },
  { path: 'help', component: HelpComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },

  { path: 'pur-invoice-search', component: PurchaseInvoiceSearchComponent, canActivate: [AuthGuard] },
  { path: 'pur-invoice-detail/:purchaseInvoiceId', component: PurchaseInvoiceDetailComponent, canActivate: [AuthGuard] },
  { path: 'pur-invoice-maint/:isAdding/:purchaseInvoiceId', component:  PurchaseInvoiceMaintComponent, canActivate: [AuthGuard] },
  { path: 'pur-invoice-collect', component:  PurchaseInvoiceCollectComponent, canActivate: [AuthGuard] },
  { path: 'pur-invoice-dashboard', component:  PurchaseInvoiceDashboardComponent, canActivate: [AuthGuard] },

  { path: 'sales-invoice-search', component: SalesInvoiceSearchComponent, canActivate: [AuthGuard] },
  { path: 'sales-invoice-export', component: SalesInvoiceExportComponent, canActivate: [AuthGuard] },
  { path: 'sales-invoice-detail/:salesInvoiceId', component: SalesInvoiceDetailComponent, canActivate: [AuthGuard] },

  { path: 'settings/:action', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'data-sync', component: DataSyncComponent, canActivate: [AuthGuard] },
  { path: 'sched-job', component: ScheduledJobComponent, canActivate: [AuthGuard] },

  { path: 'auth-user', component: AuthUserComponent, canActivate: [AuthGuard] },
  { path: 'auth-user-maint/:action/:authUserId', component: AuthUserMaintComponent },

  { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard] },
  { path: 'customer-maint/:action/:customerId', component: CustomerMaintComponent },

  { path: 'supplier', component: SupplierComponent, canActivate: [AuthGuard] },
  { path: 'supplier-maint/:action/:supplierId', component: SupplierMaintComponent },

  { path: 'inventory-item', component: InventoryItemComponent, canActivate: [AuthGuard] },
  { path: 'inventory-item-maint/:action/:inventoryItemId', component: InventoryItemMaintComponent },

  { path: 'tax-code', component: TaxCodeComponent, canActivate: [AuthGuard] },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },

  { path: '', redirectTo: '/home', pathMatch:'full'},
  { path: '**', redirectTo: '/home', pathMatch: 'full' }

];

@NgModule({
  imports: [ AuthModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
