import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../core/services/authentication.service';

/**
  * Intercepts HTTP requests and adds the "Authorization: Bearer <token>" HTTP header.
  * This is required to securely call the API.
  * See: https://angular.io/guide/http#intercepting-requests-and-responses
*/
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) { }

     intercept(request: HttpRequest<any>, next: HttpHandler) {
        // add authorization header with jwt token if available
        const token = this.authenticationService.getRawAccessToken();

        // Clone the request to add the new header.
        if (token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }


}