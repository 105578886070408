import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PickListItem } from '../../core/models/pickList';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class PickListService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

// TODO: cache these - see vas->debt-service

  public getAuthRolePickList(){
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getAuthRolePickList`);
  }

  public getCustomerPickList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
     return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getCustomerPickList/${userTenantId}`);
  }
 
  public getSupplierPickList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getSupplierPickList/${userTenantId}`);
  }

  public getInventoryItemPickList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getInventoryItemPickList/${userTenantId}`);
  }

  public getAccountPickList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getAccountPickList/${userTenantId}`);
  }

  public getPurchaseInvoiceStatusPickList(){
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getPurchaseInvoiceStatusPickList`);
  }

  public getSalesInvoiceStatusPickList(){
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getSalesInvoiceStatusPickList`);
  }

  public getErpTypePickList(){
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getErpTypePickList`);
  }

  public getInvoiceLayoutPickList(){
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getInvoiceLayoutPickList`);
  }

  public getTimeZonePickList(){
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getTimeZonePickList`);
  }

  public getCountryPickList(){
    return this.httpClient.get<PickListItem[]>(`${environment.api}/PickList/getCountryPickList`);
  }

}
