<mat-sidenav-container class="sidenav-container">
  
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">

    <!-- <mat-toolbar class="mat-toolbar_blue">Menu </mat-toolbar> -->
    <!-- <mat-toolbar color="primary">Menu </mat-toolbar> -->
    <!-- <span style="margin-left:15px;"><img src="/assets/images/edipak_logo_small.jpg"></span> -->
    <span style="width:100%;"><img src="/assets/images/edipak_logo_small.jpg" style="width:100%;"></span>

    <div *ngIf="!isLoggedIn()">
      <mat-nav-list>
        <a mat-list-item routerLink="/home">Home</a>

        <a mat-list-item routerLink="/about">About</a>

      </mat-nav-list>
    </div>


    <div *ngIf="isLoggedIn()">
      <mat-nav-list>

        <mat-list-item>
          <i class="material-icons">home</i> 
          <a mat-list-item routerLink="/home">Home</a>
        </mat-list-item>

        <mat-list-item>
            <i class="material-icons">local_library</i> 
            <a mat-list-item routerLink="/about">About</a>
        </mat-list-item>
  
        <mat-list-item>
          <i class="material-icons">help</i> 
          <a mat-list-item routerLink="/help">Help</a>
        </mat-list-item>
  
        <!-- <mat-list-item>
          <i class="material-icons">dashboard</i> 
          <a mat-list-item href="#">Dashboard</a>
        </mat-list-item> -->

        <!-- <mat-list-item>
          <i class="material-icons">note_add</i> 
          <a mat-list-item routerLink="/order-maint/true/0">Place Order</a>
        </mat-list-item> -->

        <mat-divider style="border-top-color: blue;"></mat-divider>
        <mat-list-item  style="background-color: beige;">
          Sales Invoices
        </mat-list-item>

        <mat-list-item style = "margin-left:15px;">
          <i class="material-icons">unarchive</i> 
          <a mat-list-item routerLink="/sales-invoice-export">UnSent</a>
        </mat-list-item>


        <mat-list-item style = "margin-left:15px;">
          <i class="material-icons">all_out</i> 
          <a mat-list-item routerLink="/sales-invoice-search">In Progress</a>
        </mat-list-item>

        <mat-divider style="border-top-color: blue;"></mat-divider>




        <mat-divider style="border-top-color: blue;"></mat-divider>
        <mat-list-item  style="background-color: beige;">
          Purchase Invoices
        </mat-list-item>

        <mat-list-item style = "margin-left:15px;">
          <i class="material-icons">archive</i> 
          <a mat-list-item routerLink="/pur-invoice-collect">Receive New</a>
        </mat-list-item>

        <mat-list-item style = "margin-left:15px;">
          <i class="material-icons">all_inbox</i> 
          <a mat-list-item routerLink="/pur-invoice-search">In Progress</a>
        </mat-list-item>

        <mat-list-item style = "margin-left:15px;">
          <i class="material-icons">dashboard</i> 
          <a mat-list-item routerLink="/pur-invoice-dashboard">Dashboard</a>
        </mat-list-item>

        <mat-divider style="border-top-color: blue;"></mat-divider>


        <mat-list-item>
          <i class="material-icons">schedule</i> 
          <a mat-list-item routerLink="/sched-job">Scheduled</a>
      </mat-list-item>

        <mat-list-item>
            <i class="material-icons">sync_alt</i> 
            <a mat-list-item routerLink="/data-sync">ERP Data Sync</a>
        </mat-list-item>

        <mat-list-item>
          <i class="material-icons">build</i> 
          <a mat-list-item routerLink="/settings/view">Settings</a>
        </mat-list-item>

        <mat-list-item [matMenuTriggerFor]="menu">
            <mat-icon>unfold_more</mat-icon>
            <a matline>Card Lists</a>
        </mat-list-item>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['/auth-user']" >Users</button>
          <button mat-menu-item [routerLink]="['/customer']" >Customers</button>
          <button mat-menu-item [routerLink]="['/supplier']" >Suppliers</button>
          <button mat-menu-item [routerLink]="['/inventory-item']" >Inventory Items</button>
          <button mat-menu-item [routerLink]="['/account']" >Accounts</button>
          <button mat-menu-item [routerLink]="['/tax-code']" >Tax Codes</button>
        </mat-menu>


      </mat-nav-list>
    </div>

  </mat-sidenav>

  <mat-sidenav-content>

    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <!-- <span><img src="/assets/images/edipak_logo_small.jpg"></span> -->

      <div *ngIf="currentUser">
        <span>  {{currentUser.userTenantName}} </span>
      </div>

      <span class="spacer"></span>

      <span *ngIf="!isLoggedIn()"> <a routerLink="/login">Login</a> </span>
      <span *ngIf="isLoggedIn() && currentUser"> <mat-icon style="font-size: 25px">person</mat-icon>  {{currentUser.loginName}} </span>
      <span *ngIf="isLoggedIn()"> <button class="btn btn-primary" (click)="onLogoutClick()">Logout</button> </span>

    </mat-toolbar>

    <!-- Add Content Here -->
    <!-- this will show all router output (pages) here -->
    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>

