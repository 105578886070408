import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Customer } from '../models/customer';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated-result';
import { AuthenticationService } from '../services/authentication.service';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  search(filters: Customer, page = 1, pageSize = 20): Observable<PaginatedResult<Customer>> {

    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();

    // add the tenantId to the param list
    requestParams = requestParams.append("tenantId", userTenantId.toString());

    if (filters.customerId) {
      requestParams = requestParams.append("customerId", filters.customerId.toString());
    }
    if (filters.customerName) {
      requestParams = requestParams.append("customerName", filters.customerName);
    }


    requestParams = requestParams.append("sortkey", "1");
    requestParams = requestParams.append("sortDir", "asc");

    // pagination
    requestParams = requestParams.append("pageSize", pageSize.toString());
    requestParams = requestParams.append("page", (page).toString()); 

    return this.httpClient.get<PaginatedResult<Customer>>(environment.api + "/CustomerCrud/search", {
      params: requestParams,
    });
  }
    


  public getCustomerList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
      return this.httpClient.get<Customer[]>(`${environment.api}/CustomerCrud/getCustomerList/${userTenantId}`);
  }

  public getById(customerId: number) :Observable<Customer> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    requestParams = requestParams.append("customerId", customerId.toString());
    return this.httpClient.get<Customer>(`${environment.api}/customercrud`,  {params: requestParams} );
  }

  public add(customer: any): Observable<Customer> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    customer.tenantId = userTenantId;
    return this.httpClient.post<Customer>(`${environment.api}/customercrud/`, customer, httpOptions);
  }

  public update(customer: any): Observable<any> {
    // const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    // customer.tenantId = userTenantId;
    return this.httpClient.put(`${environment.api}/customercrud/`, customer, httpOptions);
  }

  public updatePassword(customerSetPw: any): Observable<any> {
    return this.httpClient.put(`${environment.api}/customercrud/update-pw`, customerSetPw, httpOptions);
  }
  
  delete(customerId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.api}/customercrud/${customerId}`);
  }
  
}

