
import { Guid } from "guid-typescript";

export class Tenant {
    tenantId: number;
    tenantGuid: Guid;
    erpIdentifier: string;
    ediSchemaId: string;
    ediParticipantIdentifier: string;
    ediClientId: string;
    isActive: boolean;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    tenantName: string;

    contactName: string;
    contactEmail: string;
    contactPhone: string;

    timeZoneCode: string;
    erpTypeId: number;
    erpTypeName: string;
    erpVersion: string;
    erpBaseApiUrl: string;
    erpApiUsername: string;
    erpApiPassword: string;

    papApiUsername: string;
    papApiPassword: string;

    currencyCode: string;
    isAutoApprovePurchaseInvoices: boolean;
    // isAllowDuplicatePurInvoiceNoForSupp: boolean;
    //isValidateInvoiceTaxCode: boolean;
    isValidateInvoiceAccountCode: boolean;

    postal_Street: string;
    postal_City: string;
    postal_State: string;
    postal_PostCode: string;
    postal_CountryCode: string;
}
