
  <div class="card card-blue-border" style="margin-top: 10px">
    <div class="card-header card-blue-header">
      <span *ngIf="action=='view'">User Details</span>
      <span *ngIf="action=='add'">Add New User</span>
      <span *ngIf="action=='edit'">Edit User</span>
    </div>
    <div class="card-body">


      <form [formGroup]="authUserForm">
        <div class="row">
          <div class="col-md-8">

            <div class="form-group row">
              <label for="authUserId" class="col-sm-3 col-form-label">Id:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="authUserId" [readonly]="true" />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Login Name:</label> <span class="required">*</span> </div>
      
              <div class="col-sm-8">
      
                <input autofocus type="text" maxlength="80" [readonly]="!isEditable" (blur)="onLoginNameBlur()" loginPwKeydown
                [ngClass]="{'is-invalid': loginNameControl.invalid && (loginNameControl.dirty || loginNameControl.touched)}"
                class="form-control prevent-uppercase"
                formControlName="loginName" />
                <div *ngIf=" loginNameControl.errors" class="invalid-feedback">
                  <div [hidden]="! loginNameControl.errors.required">Login Name is required.</div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="FirstName" class="col-sm-3 col-form-label">First Name:</label>
              <div class="col-sm-8">
      
                <input type="text" maxlength="80" [readonly]="!isEditable"
                [ngClass]="{'is-invalid': firstNameControl.invalid && (firstNameControl.dirty || firstNameControl.touched)}"
                class="form-control prevent-uppercase"
                formControlName="firstName" />
                <div *ngIf=" firstNameControl.errors" class="invalid-feedback">
                  <div [hidden]="! firstNameControl.errors.required">First Name is required.</div>
                </div>
              </div>
            </div>
        
      
            <div class="form-group row">
              <label for="LastName" class="col-sm-3 col-form-label">Last Name:</label>
              <div class="col-sm-8">
      
                <input type="text" maxlength="80" [readonly]="!isEditable"
                [ngClass]="{'is-invalid': lastNameControl.invalid && (lastNameControl.dirty || lastNameControl.touched)}"
                class="form-control prevent-uppercase"
                formControlName="lastName" />
                <div *ngIf=" lastNameControl.errors" class="invalid-feedback">
                  <div [hidden]="! lastNameControl.errors.required">Last Name is required.</div>
                </div>
              </div>
            </div>
      
      
            <div class="form-group row">
                <div class="col-sm-3"> <label class="col-form-label">Is Active:</label> <span class="required">*</span> </div>
                <div class="col-sm-8">
      
                    <div class="small-checkbox">
                      <input type="checkbox" [disabled]="!isEditable"
                            [ngClass]="{'is-invalid': isActiveControl.invalid && (isActiveControl.dirty || isActiveControl.touched)}"
                            class="form-control"
                            formControlName="isActive"/>
                      <div *ngIf=" isActiveControl.errors" class="invalid-feedback">
                        <div [hidden]="! isActiveControl.errors.required">IsActive is required.</div>
                      </div>
                    </div>
                </div>
            </div>
      
          
            <div class="form-group row">
              <div class="col-sm-3"> <label class="col-form-label">Contact Email:</label> <span class="required">*</span> </div>
                <div class="col-sm-8">
        
                  <input type="text" maxlength="80" [readonly]="!isEditable" (keydown)="lastFieldKeydown($event)"
                  [ngClass]="{'is-invalid': contactEmailControl.invalid && (contactEmailControl.dirty || contactEmailControl.touched)}"
                  class="form-control prevent-uppercase"
                  formControlName="contactEmail" />
                  <div *ngIf=" contactEmailControl.errors" class="invalid-feedback">
                    <div [hidden]="! contactEmailControl.errors.required">Contact email is required.</div>
                  </div>
                  
                </div>
            </div>
                  

            <div *ngIf="action=='add'">
              <div class="form-group row">
                <div class="col-sm-3"> <label class="col-form-label">Password:</label> <span class="required">*</span> </div>
                <div class="col-sm-8">
                  <input class="form-control" style="width:500px;" type="password" placeholder = "Password"
                  [ngClass]="{'is-invalid': passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)}"
                  formControlName="password" /> 
      
                  <div *ngIf=" passwordControl.errors" class="invalid-feedback">
                    <div [hidden]="! passwordControl.errors.required">Password is required.</div>
                  </div>
                </div>
              </div>
      
      
              <div class="form-group row">
                <div class="col-sm-3"> <label class="col-form-label">Confirm Password:</label> <span class="required">*</span> </div>
                <div class="col-sm-8">
                  <input class="form-control" style="width:500px;" type="password" placeholder = "Confirm Password" (keydown)="lastFieldKeydown($event)"
                  [ngClass]="{'is-invalid': confirmPasswordControl.invalid && (confirmPasswordControl.dirty || confirmPasswordControl.touched)}"
                  formControlName="confirmPassword" /> 
      
                  <div *ngIf=" confirmPasswordControl.errors" class="invalid-feedback">
                    <div [hidden]="! confirmPasswordControl.errors.required">Password is required.</div>
                  </div>
      
                </div>
              </div>
      
            </div>

          </div>


          <div class="col-md-4">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Created:</label>
              <div class="col-sm-6">
                <span class="form-control readonly-bg">{{authUser.createdOn | stDateTime}}</span>
              </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Created By:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{authUser.createdBy}}</span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Last Modified:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{authUser.modifiedOn | stDateTime}}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Modified By:</label>
                <div class="col-sm-6">
                  <span class="form-control readonly-bg">{{authUser.modifiedBy}}</span>
                </div>
              </div>
          </div>


        </div>
        
      </form>

  </div>
</div>


<div class="form-group" style="margin-top:5px;">
  <button type="button" class="btn btn-outline-dark group-button" (click)="onCancel()">
    <span *ngIf="action=='view'">Close</span>
    <span *ngIf="action=='add'">Cancel</span>
    <span *ngIf="action=='edit'">Cancel</span>
  </button>
  <span *ngIf="action=='add' || action=='edit'"> 
    <button type="button" class="btn btn-outline-primary group-button" [disabled]="savingState === 'loading'" (click)="onSubmitClick()">
      <span *ngIf="savingState !== 'loading'">Save & Close</span>
      <span *ngIf="savingState === 'loading'">Saving...</span>
    </button>
  </span>

  <span *ngIf="action=='view'"> 
    <button type="button" class="btn btn-primary group-button" (click)="onModifyClick()"> Modify </button>
  </span>

</div>



  <!-- AuthUserRoles -->
  <div class="card card-blue-border" style="margin-top: 10px">
    <div class="card-header card-blue-header">
      User Roles
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-md-12">
  
             <div class="table-responsive" *ngIf="authUser.authUserRoleList != undefined">

              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>Role:</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of authUser.authUserRoleList; let i = index">
                    <td>{{item.authRoleName}}</td>
                    <td *ngIf="action != 'view'">
                        <button (click)="removeAuthUserRole(i, item.authUserRoleId)" class="fa fa-trash-o fa-lg"></button>
                    </td>
                  </tr>

                  <tr *ngIf="!states.isShowAddAuthUserRoleForm &&  authUser.authUserRoleList.length == 0">
                      <td colspan="5" class="text-center">There are no User Roles to display</td>
                  </tr>


                  <tr *ngIf="states.isShowAddAuthUserRoleForm">
                      <td>
                        <select class="form-control" [(ngModel)]="newAuthUserRole.authRoleId" (ngModelChange)="onAuthRoleChange($event)" required="true" name="ddlAuthRole"> 
                          <option *ngFor="let authRole of authRolePickList" [ngValue]="authRole.id">{{authRole.name}}</option>
                          <option [ngValue]=0>-- Select a Role --</option>
                        </select>
                      </td>

                      <td>
                        <button type="button" class="btn btn-primary" (click)="addAuthUserRoleToList()">Ok</button>
                        <button type="button" class="btn btn-outline-dark" (click)="showAddAuthUserRoleForm(false)">Cancel</button>
                      </td>
                  </tr>

                </tbody>
              </table>

            </div>

            <span *ngIf="action != 'view'"> 
              <button  *ngIf="!states.isShowAddAuthUserRoleForm" type="button" class="btn btn-primary" (click)="showAddAuthUserRoleForm(true)">Add User Role</button>
            </span>
  
        </div>
      </div>
  

    </div>
  </div>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>






