<div class="input-group">
    <input class="form-control" placeholder="dd/mm/yyyy" [(ngModel)]="_selectedDate" ngbDatepicker #datePicker="ngbDatepicker"
        (ngModelChange)="dateChanged()" (focusout)="dateChanged()" [disabled]="isDisabled" [ngClass]="{'is-invalid': (ngControl.invalid && ngControl.dirty)}"
        [maxDate]="maxDate" [minDate]="minDate">
    <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="datePicker.toggle()" type="button">
            <img src="assets/images/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
        </button>
    </div>
    <div *ngIf="ngControl.errors" class="invalid-feedback">
        <div [hidden]="!ngControl.errors.required">This parameter is required.</div>
        <div *ngIf="ngControl.errors.maxDate">Date must be before {{ngControl.errors.maxDate.min | stDate}}.</div>
        <div *ngIf="ngControl.errors.minDate">Date must be after {{ngControl.errors.minDate.min | stDate}}.</div>
        <div *ngIf="ngControl.errors.notInPeriods">Date is not in a valid/open period.</div>
    </div>
</div>
