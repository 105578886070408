export class Pagination {

    page: number;   // 1 based
    pageSize: number;
    pageCount: number;
    totalCount: number;

    /**
     * Extract a pagination object from the reponse of the server.
     * Sometime response is zero based and sometimes it is 1 based, 
     * so a boolean parameter isZeroBased tells the method how to treat the reponse.
     * @param response 
     * @param isZeroBased 
     */
    static fromJson(response, isZeroBasedServer = true): Pagination {
        let result = new Pagination();
        result.page = isZeroBasedServer ? response.page + 1 : response.page;
        result.pageSize = response.pageSize;
        result.pageCount = response.pageCount;
        result.totalCount = response.totalCount;
        return result;
    }

}