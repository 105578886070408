
<div class="card card-blue-border" style="margin-top: 10px">
    <div class="card-header card-blue-header">
        <span *ngIf="action=='view'">Settings</span>
        <span *ngIf="action=='edit'">Edit Settings</span>
    </div>
    <div class="card-body">

        <form [formGroup]="tenantForm">

                <div class="row">
                    <div class="col-md-12">
                        
                        <ngb-tabset [destroyOnHide]="false">
                    
                            <ngb-tab title="Preferences">
                                <ng-template ngbTabContent>
            
                                    <div class="row" style="margin-top:20px;">
                                    
                                    <div class="col-md-6">

                                        <div class="form-group row">
                                            <label for="tenantId" class="col-sm-4 col-form-label">Id:</label>
                                            <div class="col-sm-8">
                                            <input type="text" class="form-control" formControlName="tenantId" [readonly]="true" />
                                            </div>
                                        </div>
                            
                                        <div class="form-group row">
                                            <div class="col-sm-4"> <label class="col-form-label">Tenant Name:</label> <span class="required">*</span> </div>
                                            <div class="col-sm-8">
                                            <input type="text" maxlength="80" [readonly]="!isEditable"
                                            [ngClass]="{'is-invalid': tenantNameControl.invalid && (tenantNameControl.dirty || tenantNameControl.touched)}"
                                            class="form-control prevent-uppercase"
                                            formControlName="tenantName" />
                                            <div *ngIf=" tenantNameControl.errors" class="invalid-feedback">
                                                <div [hidden]="! tenantNameControl.errors.required">Tenant Name is required.</div>
                                            </div>
                                            </div>
                                        </div>
                    
                                        <div class="form-group row">
                                            <label for="tenantGuid" class="col-sm-4 col-form-label">ERP Tenant GUID:</label>
                                            <div class="col-sm-8">
                                    
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': tenantGuidControl.invalid && (tenantGuidControl.dirty || tenantGuidControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="tenantGuid" />
                                            </div>
                                        </div>
                                
                        
                                        <div class="form-group row">
                                            <div class="col-sm-4"> <label class="col-form-label">ERP Type:</label> <span class="required">*</span> </div>
                                            <div class="col-sm-8">
                    
                                                <div *ngIf="!isEditable && tenant != undefined">
                                                    <span class="form-control readonly-bg">{{tenant.erpTypeName}}</span>
                                                </div>
                                                
                                                <div *ngIf="isEditable">
                    
                                                    <select class="form-control"
                                                        [ngClass]="{'is-invalid': erpTypeIdControl.invalid && (erpTypeIdControl.dirty || erpTypeIdControl.touched)}"
                                                        class="form-control prevent-uppercase"
                                                        formControlName="erpTypeId">
                                            
                                                        <option *ngFor="let erpType of erpTypePickList" [ngValue]="erpType.id">{{erpType.name}}</option>
                                                        <!-- <option [ngValue]=null>-- Select an ERP Type --</option> -->
                                                    </select>
                                            
                                                    <div *ngIf=" erpTypeIdControl.errors" class="invalid-feedback">
                                                        <div [hidden]="! erpTypeIdControl.errors.required">ERP Type is required.</div>
                                                    </div>
                    
                                                </div>
                    
                                            </div>
                                        </div>
                            
                        
                                        <div class="form-group row">
                                            <label for="erpVersion" class="col-sm-4 col-form-label">ERP Version:</label>
                                            <div class="col-sm-8">
                                    
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': erpVersionControl.invalid && (erpVersionControl.dirty || erpVersionControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="erpVersion" />
                                            </div>
                                        </div>
                    
                                        <div class="form-group row">
                                            <label for="erpBaseApiUrl" class="col-sm-4 col-form-label">ERP Base API URL:</label>
                                            <div class="col-sm-8">
                                    
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': erpBaseApiUrlControl.invalid && (erpBaseApiUrlControl.dirty || erpBaseApiUrlControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="erpBaseApiUrl" />
                                            </div>
                                        </div>
                            
                                        <div class="form-group row">
                                            <label for="erpApiUsername" class="col-sm-4 col-form-label">ERP API Username:</label>
                                            <div class="col-sm-8">
                                    
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': erpApiUsernameControl.invalid && (erpApiUsernameControl.dirty || erpApiUsernameControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="erpApiUsername" />
                                            </div>
                                        </div>
                                                    
                                        <div class="form-group row">
                                            <label for="erpApiPassword" class="col-sm-4 col-form-label">ERP API Password:</label>
                                            <div class="col-sm-8">
                                                <input class="form-control" type="password" placeholder = "Password"  maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': erpApiPasswordControl.invalid && (erpApiPasswordControl.dirty || erpApiPasswordControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="erpApiPassword" />
                                            </div>
                                        </div>

                                    </div>
                        
                                    <div class="col-md-6">

                                        <div class="form-group row">
                                            <div class="col-sm-4"> <label class="col-form-label">Time Zone:</label> <span class="required">*</span> </div>
                                            <div class="col-sm-8">
                    
                                                <div *ngIf="!isEditable && tenant != undefined">
                                                    <span class="form-control readonly-bg">{{tenant.timeZoneCode}}</span>
                                                </div>
                    
                                                <div *ngIf="isEditable">
                    
                                                    <select class="form-control"
                                                        [ngClass]="{'is-invalid': timeZoneCodeControl.invalid && (timeZoneCodeControl.dirty || timeZoneCodeControl.touched)}"
                                                        class="form-control prevent-uppercase"
                                                        formControlName="timeZoneCode">
                                            
                                                        <option *ngFor="let timeZone of timeZonePickList" [ngValue]="timeZone.code">{{timeZone.name}}</option>
                                                        <option [ngValue]=null>-- Select a Time Zone --</option>
                                                    </select>
                                            
                                                    <div *ngIf=" timeZoneCodeControl.errors" class="invalid-feedback">
                                                        <div [hidden]="! timeZoneCodeControl.errors.required">Time Zone is required.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            
        
                                        <div class="form-group row">
                                            <label for="contactName" class="col-sm-4 col-form-label">Contact Name:</label>
                                            <div class="col-sm-8">
                                            <input type="text" maxlength="50" [readonly]="!isEditable"
                                            [ngClass]="{'is-invalid': contactNameControl.invalid && (contactNameControl.dirty || contactNameControl.touched)}"
                                            class="form-control prevent-uppercase"
                                            formControlName="contactName" />
                                            </div>
                                        </div>
        
                                        <div class="form-group row">
                                            <label for="contactPhone" class="col-sm-4 col-form-label">Contact Phone:</label>
                                            <div class="col-sm-8">
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': contactPhoneControl.invalid && (contactPhoneControl.dirty || contactPhoneControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="contactPhone" />
                                            </div>
                                        </div>
        
        
                                        <div class="form-group row">
                                            <div class="col-sm-4"> <label class="col-form-label">Contact Email:</label> <span class="required">*</span> </div>
                                            <div class="col-sm-8">
                                                <input type="text" maxlength="80" [readonly]="!isEditable" (keydown)="lastFieldKeydown($event)"
                                                [ngClass]="{'is-invalid': contactEmailControl.invalid && (contactEmailControl.dirty || contactEmailControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="contactEmail" />
                                                <div *ngIf=" contactEmailControl.errors" class="invalid-feedback">
                                                <div [hidden]="! contactEmailControl.errors.required">Contact email is required.</div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="form-group row">
                                            <div class="col-sm-4"> <label class="col-form-label">Currency Code:</label> <span class="required">*</span> </div>
                                            <div class="col-sm-3">
                                            <input type="text" maxlength="80" [readonly]="!isEditable"
                                            [ngClass]="{'is-invalid': currencyCodeControl.invalid && (currencyCodeControl.dirty || currencyCodeControl.touched)}"
                                            class="form-control prevent-uppercase"
                                            formControlName="currencyCode" />
                                            <div *ngIf=" currencyCodeControl.errors" class="invalid-feedback">
                                                <div [hidden]="! currencyCodeControl.errors.required">Currency Code is required.</div>
                                            </div>
                                            </div>
                                        </div>
                            
                                    
                                        <div class="form-group row">
                                            <div class="col-sm-5"> <label class="col-form-label">Auto Approve Purch Invoices:</label> <span class="required">*</span> </div>
                                            <div class="col-sm-3">
                                                <div class="small-checkbox" *ngIf="isEditable">
                                                    <input type="checkbox" [disabled]="!isEditable"
                                                        [ngClass]="{'is-invalid': isAutoApprovePurchaseInvoicesControl.invalid && (isAutoApprovePurchaseInvoicesControl.dirty || isAutoApprovePurchaseInvoicesControl.touched)}"
                                                        class="form-control"
                                                        formControlName="isAutoApprovePurchaseInvoices"/>
                                                    <div *ngIf=" isAutoApprovePurchaseInvoicesControl.errors" class="invalid-feedback">
                                                    <div [hidden]="! isAutoApprovePurchaseInvoicesControl.errors.required">isAutoApprovePurchaseInvoices is required.</div>
                                                    </div>
                                                </div>
                                                <span *ngIf="!isEditable && tenant.isAutoApprovePurchaseInvoices" class="form-control readonly-bg"> Yes </span>
                                                <span *ngIf="!isEditable && tenant.isAutoApprovePurchaseInvoices === false" class="form-control readonly-bg"> No </span>
                                            </div>
                                        </div>
                                            
                                    </div>
                                                
                                    </div>
                                
                                </ng-template>
                            </ngb-tab>
                        

                            





                            <ngb-tab title="e-Invoicing">
                                <ng-template ngbTabContent>
            
                                    <div class="row" style="margin-top:20px;">
                                    
                                    <div class="col-md-6">
    
                        
                                        <div class="form-group row">
                                            <label for="ediSchemaId" class="col-sm-4 col-form-label">EDI Schema Identifier :</label>
                                            <div class="col-sm-3">
                                    
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': ediSchemaIdControl.invalid && (ediSchemaIdControl.dirty || ediSchemaIdControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="ediSchemaId" />
                                            </div>
                                        </div>
                                    
                                        <div class="form-group row">
                                            <label for="ediParticipantIdentifier" class="col-sm-4 col-form-label">EDI Participant Identifier :</label>
                                            <div class="col-sm-8">
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': ediParticipantIdentifierControl.invalid && (ediParticipantIdentifierControl.dirty || ediParticipantIdentifierControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="ediParticipantIdentifier" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="ediClientId" class="col-sm-4 col-form-label">EDI Provider ClientId :</label>
                                            <div class="col-sm-8">
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': ediClientIdControl.invalid && (ediClientIdControl.dirty || ediClientIdControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="ediClientId" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                                <label for="papApiUsername" class="col-sm-4 col-form-label">PAP Account Username:</label>
                                                <div class="col-sm-8">
                                        
                                                    <input type="text" maxlength="50" [readonly]="!isEditable"
                                                    [ngClass]="{'is-invalid': papApiUsernameControl.invalid && (papApiUsernameControl.dirty || papApiUsernameControl.touched)}"
                                                    class="form-control prevent-uppercase"
                                                    formControlName="papApiUsername" />
                                                </div>
                                            </div>
                                                        
                                            <div class="form-group row">
                                                <label for="papApiPassword" class="col-sm-4 col-form-label">PAP Account Password:</label>
                                                <div class="col-sm-8">
                                                    <input class="form-control" type="password" placeholder = "Password"  maxlength="50" [readonly]="!isEditable"
                                                    [ngClass]="{'is-invalid': papApiPasswordControl.invalid && (papApiPasswordControl.dirty || papApiPasswordControl.touched)}"
                                                    class="form-control prevent-uppercase"
                                                    formControlName="papApiPassword" />
                                                </div>
                                            </div>
    
                       
                                    </div>
                        
                                    <div class="col-md-6">
                                            
                                    </div>
                                                
                                </div>
                            
                            </ng-template>
                            </ngb-tab>
                            
    
    














                            <ngb-tab title="Addresses">
                            <ng-template ngbTabContent>
        
        
                                <div class="row" style="margin-top:20px;">
                                    <div class="col-md-6">
        
                                        <H4>Postal Address:</H4>
            
                                        <div class="form-group row">
                                            <label for="postal_Street" class="col-sm-3 col-form-label">Address Line:</label>
                                            <div class="col-sm-8">
                                            <input type="text" maxlength="50" [readonly]="!isEditable"
                                            [ngClass]="{'is-invalid': postal_StreetControl.invalid && (postal_StreetControl.dirty || postal_StreetControl.touched)}"
                                            class="form-control prevent-uppercase"
                                            formControlName="postal_Street" />
                                            </div>
                                        </div>
            
                                        <div class="form-group row">
                                            <label for="postal_City" class="col-sm-3 col-form-label">City:</label>
                                            <div class="col-sm-8">
                                            <input type="text" maxlength="50" [readonly]="!isEditable"
                                            [ngClass]="{'is-invalid': postal_CityControl.invalid && (postal_CityControl.dirty || postal_CityControl.touched)}"
                                            class="form-control prevent-uppercase"
                                            formControlName="postal_City" />
                                            </div>
                                        </div>
        
                                        <div class="form-group row">
                                            <label for="postal_State" class="col-sm-3 col-form-label">State:</label>
                                            <div class="col-sm-8">
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': postal_StateControl.invalid && (postal_StateControl.dirty || postal_StateControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="postal_State" />
                                            </div>
                                        </div>
            
                                        <div class="form-group row">
                                            <label for="postal_PostCode" class="col-sm-3 col-form-label">PostCode:</label>
                                            <div class="col-sm-8">
                                                <input type="text" maxlength="50" [readonly]="!isEditable"
                                                [ngClass]="{'is-invalid': postal_PostCodeControl.invalid && (postal_PostCodeControl.dirty || postal_PostCodeControl.touched)}"
                                                class="form-control prevent-uppercase"
                                                formControlName="postal_PostCode" />
                                            </div>
                                        </div>
            
                                        
                                        <div class="form-group row">
                                            <div class="col-sm-3"> <label class="col-form-label">Country Code:</label> <span class="required">*</span> </div>
                                    
                                            <div class="col-sm-8">

                                                <div *ngIf="!isEditable && tenant != undefined">
                                                    <span class="form-control readonly-bg">{{tenant.postal_CountryCode}}</span>
                                                </div>

                                                <div *ngIf="isEditable">
                                                    <select class="form-control"
                                                        [ngClass]="{'is-invalid': postal_CountryCodeControl.invalid && (postal_CountryCodeControl.dirty || postal_CountryCodeControl.touched)}"
                                                        class="form-control prevent-uppercase"
                                                        formControlName="postal_CountryCode">
                                                        <option *ngFor="let country of countryPickList" [ngValue]="country.code">{{country.name}}</option>
                                                            <option [ngValue]=null>-- Select a Country --</option>
                                                        </select>
                                                </div>

                                                <div *ngIf=" postal_CountryCodeControl.errors" class="invalid-feedback">
                                                    <div [hidden]="! postal_CountryCodeControl.errors.required">Country is required.</div>
                                                </div>

                                            </div>
                                        </div>
                                        
                                    </div>
                        
                        
                                    <div class="col-md-6">
                                        
                                    </div>
                        
                                </div>
                                
                            </ng-template>
                            </ngb-tab>
                        
                        </ngb-tabset>
                    
                    </div>
                </div>
          
        </form>

    </div>
</div>


<div class="form-group" style="margin-top:5px;">
    <button type="button" class="btn btn-outline-dark group-button" (click)="onCancel()">
        <span *ngIf="action=='view'">Close</span>
        <span *ngIf="action=='add'">Cancel</span>
        <span *ngIf="action=='edit'">Cancel</span>
    </button>
    <span *ngIf="action=='add' || action=='edit'"> 
        <button type="button" class="btn btn-outline-primary group-button" [disabled]="savingState === 'loading'" (click)="onSubmitClick()">
            <span *ngIf="savingState !== 'loading'">Save & Close</span>
            <span *ngIf="savingState === 'loading'">Saving...</span>
        </button>
    </span>

    <span *ngIf="action=='view'"> 
        <button type="button" class="btn btn-primary group-button" (click)="onModifyClick()"> Modify </button>
    </span>

</div>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>
    
      
  