<div style="background-image: url(/assets/images/edipak_header.jpg); background-repeat: no-repeat; background-size: cover;">
	<br/><br/><br/>
	<br/><br/><br/>
  </div>
  
<div style="margin-left:5px">
<h1>You have successfully been logged out</h1>
<p class="mt-4">
	You can log in again using the button below<br />
	<br />
	<a routerLink="/login" class="btn btn-primary">Login</a>
</p>
</div>

