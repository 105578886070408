
import { Guid } from "guid-typescript";

export class Customer {
    customerId: number;
    customerGuid: Guid;
    erpIdentifier: string;
    ediParticipantIdentifier: string;
    ediSchemaId: string;
    isActive: boolean;
    isIndividual: boolean;
    isEInvoicingEnabled: boolean;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    tenantId: number;
    customerName: string;
    accountingCostCode: string;

    contactName: string;
    contactEmail: string;
    contactPhone: string;

    billing_Street: string;
    billing_City: string;
    billing_State: string;
    billing_PostCode: string;
    billing_CountryCode: string;

    shipTo_Street: string;
    shipTo_City: string;
    shipTo_State: string;
    shipTo_PostCode: string;
    shipTo_CountryCode: string;
}

