import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BackgroundJobResult } from '../models/bg-job-result';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class DataSyncService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  public syncSuppliers() :Observable<BackgroundJobResult> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
   return this.httpClient.get<BackgroundJobResult>(`${environment.api}/DataSync/syncSuppliers/${userTenantId}`);
  }

  public syncCustomers() :Observable<BackgroundJobResult> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
   return this.httpClient.get<BackgroundJobResult>(`${environment.api}/DataSync/syncCustomers/${userTenantId}`);
  }

  public syncInventoryItems() :Observable<BackgroundJobResult> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<BackgroundJobResult>(`${environment.api}/DataSync/syncInventoryItems/${userTenantId}`);
  }

  public syncAccounts() :Observable<BackgroundJobResult> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<BackgroundJobResult>(`${environment.api}/DataSync/syncAccounts/${userTenantId}`);
  }

  public syncTaxCodeIdMappings() :Observable<BackgroundJobResult> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<BackgroundJobResult>(`${environment.api}/DataSync/syncTaxCodes/${userTenantId}`);
  }

  public uploadPurchaseInvoicesToErp() :Observable<BackgroundJobResult> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<BackgroundJobResult>(`${environment.api}/DataSync/uploadPurchaseInvoicesToErp/${userTenantId}`);
  }

}

