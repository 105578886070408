
<div style="background-image: url(/assets/images/edipak_header.jpg); background-repeat: no-repeat; background-size: cover;">
  <br/><br/><br/>
  <br/><br/><br/>
</div>

<div *ngIf="!isLoggedIn()">
  <app-promo></app-promo>
</div>

<div *ngIf="isLoggedIn()">
  <app-pur-invoice-dashboard></app-pur-invoice-dashboard>
</div>

