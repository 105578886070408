import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthUser } from '../models/auth-user';
import { environment } from '../../../environments/environment';
import { PaginatedResult } from '../models/paginated-result';

import { AuthenticationService } from '../services/authentication.service';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  search(filters: AuthUser, page = 1, pageSize = 20): Observable<PaginatedResult<AuthUser>> {

    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();

    // add the tenantId to the param list
    requestParams = requestParams.append("tenantId", userTenantId.toString());

    if (filters.authUserId) {
      requestParams = requestParams.append("authUserId", filters.authUserId.toString());
    }
    if (filters.loginName) {
      requestParams = requestParams.append("loginName", filters.loginName);
    }

    requestParams = requestParams.append("sortkey", "1");
    requestParams = requestParams.append("sortDir", "asc");

    // pagination
    requestParams = requestParams.append("pageSize", pageSize.toString());
    requestParams = requestParams.append("page", (page).toString()); 

    return this.httpClient.get<PaginatedResult<AuthUser>>(environment.api + "/AuthUserCrud/search", {
      params: requestParams,
    });
  }
    


  public getAuthUserList(){
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    return this.httpClient.get<AuthUser[]>(`${environment.api}/AuthUserCrud/getAuthUserList/${userTenantId}`);
  }

  public getById(authUserId: number) :Observable<AuthUser> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    let requestParams = new HttpParams();
    requestParams = requestParams.append("tenantId", userTenantId.toString());
    requestParams = requestParams.append("authUserId", authUserId.toString());
    return this.httpClient.get<AuthUser>(`${environment.api}/authUsercrud`,  {params: requestParams} );
  }

  public add(authUser: any): Observable<AuthUser> {
    const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    authUser.tenantId = userTenantId;
    return this.httpClient.post<AuthUser>(`${environment.api}/authUsercrud/`, authUser, httpOptions);
  }

  public update(authUser: any): Observable<any> {
    // const userTenantId = this.authenticationService.getCurrentUser().userTenantId;
    // authUser.tenantId = userTenantId;
   return this.httpClient.put(`${environment.api}/authUsercrud/`, authUser, httpOptions);
  }

  public updatePassword(authUserSetPw: any): Observable<any> {
    return this.httpClient.patch(`${environment.api}/authUsercrud/update-pw`, authUserSetPw, httpOptions);
  }

  public delete(authUserId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.api}/authUsercrud/${authUserId}`);
  }
  
}

