import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormHelpers } from '../../../../core/helpers/form-helpers';
import { Supplier } from '../../../../core/models/supplier';
import { SupplierService } from '../../../../core/services/supplier.service';
import { ToasterService } from '../../../../core/services/toaster.service';
import { PickListService } from '../../../../core/services/pickList.service';
import { PickListItem } from '../../../../core/models/pickList';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Guid } from "guid-typescript";
import { stringify } from 'querystring';


@Component({
  selector: 'app-supplier-maint',
  templateUrl: './supplier-maint.component.html',
  styleUrls: ['./supplier-maint.component.scss']
})

export class SupplierMaintComponent implements OnInit {
  supplierId: number;
  supplier: Supplier = new Supplier();

  invoiceLayoutPickList: PickListItem[];
  accountPickList: PickListItem[];

  supplierForm: FormGroup;
  isEditable: boolean;
  action: string;
  savingState: string;

  states = {
    isShowAddSupplierRoleForm: false
  };

  @Output()
  indicatoreChange: EventEmitter<any> = new EventEmitter();

  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private route:ActivatedRoute,
      private spinner: NgxSpinnerService,
      private supplierService: SupplierService,
      private pickListService: PickListService,
      private toaster: ToasterService
   ) {}  

  ngOnInit() {
    this.route.params.subscribe(params => this.action = params['action'] );
    this.route.params.subscribe(params => this.supplierId = params['supplierId']);

    this.isEditable = this.action != 'view';

    this.pickListService.getInvoiceLayoutPickList().subscribe(res => {
      this.invoiceLayoutPickList = res;
    })

    this.pickListService.getAccountPickList().subscribe(res => {
      this.accountPickList = res;
    })

    this.supplierForm = new FormGroup({
      supplierId: new FormControl(""),
      supplierName: new FormControl("", Validators.required),
      supplierGuid: new FormControl(""),
      erpIdentifier: new FormControl(""),
      ediSchemaId: new FormControl(""),
      ediParticipantIdentifier: new FormControl(""),
      contactEmail: new FormControl("", Validators.required),
      invoiceLayoutType: new FormControl("", Validators.required),
      accountingCostCode: new FormControl(""),
    });


    if (this.action === 'add') {
    }
    else
      this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.supplierService.getById(this.supplierId)
      .subscribe(this.onDataLoaded.bind(this),
        error => {
          this.spinner.hide();
          this.router.navigate(["supplier"]);
          throw error;       // will go to the global error handler
        }
    );
  }


  onDataLoaded(obj: Supplier) {
    this.supplier = obj;
 
    this.supplierForm = this.formBuilder.group({

      supplierId: [obj.supplierId],
      supplierName: [obj.supplierName, Validators.required],
      supplierGuid: [obj.supplierGuid],
      ediSchemaId:  [obj.ediSchemaId],
      ediParticipantIdentifier: [obj.ediParticipantIdentifier],
      erpIdentifier: [obj.erpIdentifier],
      contactEmail: [obj.contactEmail, Validators.required],
      invoiceLayoutType: [obj.invoiceLayoutType, Validators.required],
      accountingCostCode: [obj.accountingCostCode]
    });

    this.spinner.hide();

  }

  
  onSubmitClick() {
    if (this.supplierForm.invalid) {
      FormHelpers.validateFormGroup(this.supplierForm);
    }
    else {

      // create a model with values returned from the reactive form
      const modifiedSupplier: Supplier = this.supplierForm.getRawValue();

      // get the data that may have changed from the reactive form controls 
      // & update the orinal order model so what is not updated on Form is not clobbered in db

      this.supplier.supplierId = modifiedSupplier.supplierId;
      this.supplier.erpIdentifier = modifiedSupplier.erpIdentifier;
      this.supplier.ediSchemaId = modifiedSupplier.ediSchemaId;
      this.supplier.ediParticipantIdentifier = modifiedSupplier.ediParticipantIdentifier;
      this.supplier.contactEmail = modifiedSupplier.contactEmail;
      this.supplier.invoiceLayoutType = modifiedSupplier.invoiceLayoutType;
      this.supplier.accountingCostCode = modifiedSupplier.accountingCostCode;

      this.savingState = 'loading';

      if (this.action === 'add') {
        this.supplier.supplierId = 0;

        var newSupplier = this.supplierService.add(this.supplier)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }
      else {
        this.supplierService.update(this.supplier)
          .subscribe(this.onSavingSuccess.bind(this), this.onSavingError.bind(this));
      }

    }
  }


  onSavingSuccess() {
    this.savingState = "finished";
    this.router.navigate(["supplier"])
    this.toaster.showSuccess('Successfully saved supplier.');
  }

  onSavingError(error) {
    this.savingState = "";
    throw error;    // will go to the global error handler
  }

  onCancel() {
    this.router.navigate(["home"])
  }

  onModifyClick() {
    this.action = 'edit';
    this.isEditable = true;
  } 

  lastFieldKeydown(event) {
    if (event.key === 'Enter') {
      this.onSubmitClick();
    }
  }

  get supplierIdControl() { return this.supplierForm.get("supplierId"); }
  get erpIdentifierControl() { return this.supplierForm.get("erpIdentifier"); }
  get ediSchemaIdControl() { return this.supplierForm.get("ediSchemaId"); }
  get ediParticipantIdentifierControl() { return this.supplierForm.get("ediParticipantIdentifier"); }
  get contactEmailControl() { return this.supplierForm.get("contactEmail"); }
  get invoiceLayoutTypeControl() { return this.supplierForm.get("invoiceLayoutType"); }
  get accountingCostCodeControl() { return this.supplierForm.get("accountingCostCode"); }

}
