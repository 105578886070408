
<div class="card card-blue-border">
  <div class="card-header card-big-blue-text-header">
      Purchase Invoices:
  </div>
  <div class="card-body">

    <form [formGroup]="formGroup">

      <div class="form-row">

        <div class="col-md-3">
          <label>Customer:</label>
          <select class="form-control" formControlName="supplierddl">
            <option *ngFor="let supplier of supplierPickList" [ngValue]="supplier.id">{{supplier.name}}</option>
            <option [ngValue]=null> All Suppliers </option>
          </select>
        </div>

        <div class="col-md-2">
            <label> Received Date From:</label>
            <app-date-picker formControlName="receivedDateFrom" [minDate]="minDate"></app-date-picker>
        </div>

        <div class="col-md-2">
            <label> Received Date To:</label>
            <app-date-picker formControlName="receivedDateTo" [minDate]="minDate"></app-date-picker>
          </div>

          <div class="col-md-3">
              <label>Invoice Status:</label>
              <select class="form-control" formControlName="purchaseInvoiceStatusId">
                <option *ngFor="let status of purchaseInvoiceStatusPickList" [ngValue]="status.id">{{status.name}}</option>
                <option [ngValue]=null> Any Status </option>
              </select>
          </div>
  
          <div class="col-md-2">
              <button type="button" class="btn btn-primary group-button" (click)="onSearchSubmit()"> Search</button>
              <button type="button" class="btn btn-outline-info cancel group-button" [disabled]="searchState === 'loading'" (click)="onSearchReset()">Reset</button>
            </div>
    
      </div>

    </form>

  </div>
</div>

<div  style="margin-left:20px; margin-top:20px;" *ngIf="searchResults.length === 0">
  <span>No Purchase Invoices found</span>
</div>


<div class="row">
  <div class="col-md-12">

    <div *ngIf="searchState == 'finished'">
      <div class="table-responsive" *ngIf="!!searchResults && !!searchResults.length">
    
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Id:</th>
              <th>Supplier:</th>
              <th>Status:</th>
              <th>Valid:</th>
              <th>Invoice No:</th>
              <th>Invoice Total:</th>
              <th>Received:</th>
              <th>Due Date:</th>
              <th>Approved:</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of searchResults">
              <td>{{r.purchaseInvoiceId}}</td>
              <td>{{r.supplierName}}</td>

              <td>
                <span *ngIf="r.purchaseInvoiceStatusId < 200" > {{r.purchaseInvoiceStatusName}}</span>
                <span *ngIf="r.purchaseInvoiceStatusId === 200" style="color:green;" > {{r.purchaseInvoiceStatusName}}</span>
                <span *ngIf="r.purchaseInvoiceStatusId >= 500" style="width:200px;color:red;font-weight:bold;"> {{r.purchaseInvoiceStatusName}}</span>
              </td>

              <td>
                <span *ngIf="r.isValid"  style="width:200px;"> Passed</span>
                <span *ngIf="!r.isValid" style="width:200px;color:red;font-weight:bold;"> Failed</span>
              </td>

              <td>{{r.supplierInvoiceNumber}}</td>
              <td>{{r.payableAmount | currency}}</td>
              <td>{{r.receivedDate | stDate}}</td>
              <td>{{r.dueDate | stDate}}</td>
              <td>{{r.isApproved | boolYesNo}}</td>
              <td class="d-flex">
                <button (click)="onPurchaseInvoiceDetailClick(r.purchaseInvoiceId)" class="btn btn-sm btn-outline-primary mr-2">Details</button>
              </td>
            </tr>
          </tbody>
        </table>

        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="pagination.totalCount" [pageSize]="pagination.pageSize"
        [(page)]="selectedPage" (pageChange)="onPageClicked($event)" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>

      </div>
    </div>

  </div>
</div>

<p>&nbsp;</p>

<ngx-spinner type = "ball-spin-clockwise-fade-rotating"></ngx-spinner>

