import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import * as moment from "moment";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthRequest } from '../models/auth';
import { AuthResponse } from '../models/auth';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CurrentUser } from '../models/auth-user';
import { AuthRoleEnum } from '../../core/models/enum';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
  

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<CurrentUser>;
  public currentUser: Observable<CurrentUser>;

  constructor(
      private activedRoute: ActivatedRoute,
      private httpClient: HttpClient,
      private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   }


  login(authRequest : any): Observable<any> {
    // // use the pipe/tap to save the token/loginName/userTenantId to localStorage on the side
    return this.httpClient.post<AuthResponse>(`${environment.api}/Auth/login`, authRequest).pipe(tap(res => {
      localStorage.setItem('access_token', JSON.stringify(res.token.value.token));
      localStorage.setItem('currentUser', JSON.stringify(res.currentUser));
      this.currentUserSubject.next(res.currentUser);
    } ));

  }
     
  logout() {
    localStorage.removeItem('access_token');    // called from logout component
    localStorage.removeItem('currentUser');    // called from logout component
    this.currentUserSubject.next(null);
  }

  isLoggedIn() {

    const token = this.getAccessToken();

    if ( token ===  null || token === undefined )
    return false;

    let exp = token.exp;
    let expMoment = moment(exp * 1000);
    let nowMoment = moment();

    if (expMoment.isSameOrBefore(nowMoment)) {
        console.log("[Authentication] Token has expired: " + token.exp);
        return false;
    }

    return true;

  }


  /** Returns the access token in encoded string format. */
  getRawAccessToken() {
    let token = JSON.parse(localStorage.getItem('access_token'));

    return token;
  }

  /** Decodes the access token and returns it as an object. */
  getAccessToken() {
    let rawToken = this.getRawAccessToken();
    let token = null;

    try {
        token = jwt_decode(rawToken);
    } catch (err) { }

      return token;
  }


  public getCurrentUser() {
    if (this.currentUserSubject.value)
      return this.currentUserSubject.value
    else
      this.router.navigate(["login"]);
  }

  public isUserAdmin() {
    let currentUser = this.getCurrentUser();
    return currentUser.roleList.indexOf(AuthRoleEnum.administrator) !== -1;
  }

  public isUserPurchaseInvoiceApprover() {
    let currentUser = this.getCurrentUser();
    return (currentUser.roleList.indexOf(AuthRoleEnum.administrator) !== -1) || (currentUser.roleList.indexOf(AuthRoleEnum.approvePurchaseInvoices) !== -1);
  }

}
